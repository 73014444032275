import React, {useEffect} from 'react';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css"

import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ProjectList from "./routes/projects/ProjectList";
import ProjectAdd from "./routes/projects/ProjectAdd";
import {I18nextProvider} from "react-i18next";
import HttpApi from "i18next-http-backend";
import i18next from "i18next";
import ProjectEdit from "./routes/projects/ProjectEdit";
import DaysOffList from "./routes/daysOff/DaysOffList";
import DaysOffAdd from "./routes/daysOff/DayOffAdd";
import DayOffEdit from "./routes/daysOff/DayOffEdit";
import UsersList from "./routes/users/UsersList";
import UserAdd from "./routes/users/UserAdd";
import UserScreen from "./routes/users/UserScreen";
import ProjectTaskList from "./routes/projects/task/ProjectTaskList";
import ProjectEditDetails from "./routes/projects/ProjectEditDetails";
import ProjectTaskAdd from "./routes/projects/task/ProjectTaskAdd";
import ProjectAssignmentAdd from "./routes/projects/assignment/ProjectAssignmentAdd";
import AbsencesList from "./routes/users/absence/AbsencesList";
import UserAbsenceAdd from "./routes/users/absence/UserAbsenceAdd";
import UserDelegationAdd from "./routes/users/delegation/UserDelegationAdd";
import {projectDetails} from "./routes/projects/projectsRouting";
import ProjectTaskEdit from "./routes/projects/task/ProjectTaskEdit";
import ProjectAssignmentEdit from "./routes/projects/assignment/ProjectAssignmentEdit";
import UserAbsenceEdit from "./routes/users/absence/UserAbsenceEdit";
import ReportsList from "./routes/reports/ReportsList";
import ReportEdit from "./routes/reports/ReportEdit";
import MyReportsList from "./routes/reports/MyReportsList";
import ReportsListForLeader from "./routes/reports/ReportsListForLeader";
import ReportsListForHR from "./routes/reports/ReportsListForHR";
import RolesList from "./routes/users/roles/RolesList";
import CreateReports from "./routes/reports/CreateReports";
import ProjectAssets from "./routes/projects/asset/ProjectAssets";
import UserEdit from "./routes/users/UserEdit";
import MonthHRReadiness from "./routes/month-hr-readiness/MonthHRReadiness";
import AbsencesListForHR from "./routes/absence-list/AbsencesListForHR";
import DelegationsListForHR from "./routes/delegation-list/DelegationsListForHR";
import UserDelegationEdit from "./routes/users/delegation/UserDelegationEdit";
import MainPage from "./routes/main/MainPage";
import ReportsListForSupervisor from "./routes/reports/ReportsListForSupervisor";
import {ReactKeycloakProvider} from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import {BACKEND_URL} from "./config";
import {getBaseRequestConfig} from "./api/baseRequestConfig";
import {syncFetchAndJSON} from "./api/async-fetch";
import AuthService from "./AuthService";
import AssignmentsGlobalList from "./routes/assignments/AssignmentsGlobalList";
import Summaries from "./routes/summary/Summaries";
import Tools from "./routes/tools/Tools";
import ProcessedEventsList from "./routes/events/ProcessedEventsList";
import EmailsList from "./routes/emails/EmailsList";
import RemoteWorkRatesList from "./routes/remote-work-rates/RemoteWorkRatesList";
import RemoteWorkRateAdd from "./routes/remote-work-rates/RemoteWorkRateAdd";
import RemoteWorkRateEdit from "./routes/remote-work-rates/RemoteWorkRateEdit";
import RemoteWorkPermitList from "./routes/remote-work-permit/RemoteWorkPermitList";
import RemoteWorkPermitAdd from "./routes/remote-work-permit/RemoteWorkPermitAdd";
import RemoteWorkPermitEdit from "./routes/remote-work-permit/RemoteWorkPermitEdit";


export default function MainApp() {

    const [keycloack, setKeycloack] = React.useState(null)

    i18next
        .use(HttpApi) // Registering the back-end plugin
        .init({
            interpolation: {escapeValue: false},  // React already does escaping
            lng: "pl",
            // resources: resources
        });

    const getConfig = () => {
        let url = BACKEND_URL + 'ui-config'
        const baseRequestConfig =  {
            method: "GET",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setKeycloack(new Keycloak({
                    url: response.KEYCLOAK_BASE_URL,
                    realm: response.KEYCLOAK_REALM,
                    clientId: response.KEYCLOAK_CLIENT_ID
                }));
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const initOptions = { pkceMethod: "S256", onLoad: "login-required" }

    const getUser = async () => {
        let url = BACKEND_URL + 'user'
        const baseRequestConfig = getBaseRequestConfig("GET")
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                AuthService.login(response)
                keycloack["userEntity"] = response
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleOnEvent = async (event, error) => {
        if (event === 'onAuthSuccess') {
            if (keycloack.authenticated) {
                window.sessionStorage.accessToken = "Bearer " + keycloack.token;;
                await getUser();
            }
        }
    }

    const loadingComponent = (
        <div className="container-fluid text-center">
        <form className="form-signin">
            <img className="mb-4" src="logo.svg" alt=""
                 width="72" height="72" />
                <h1 className="h3 mb-3 font-weight-normal">Łączenie z SSO</h1>
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
        </form>
        </div>
    )




    useEffect(() => {
        getConfig()
    }, [])

    return (
        <>
        {keycloack && <ReactKeycloakProvider
            authClient={keycloack}
            initOptions={initOptions}
            LoadingComponent={loadingComponent}
            onEvent={(event, error) => handleOnEvent(event, error)}
            onTokens={({token}) => {
                window.sessionStorage.accessToken = "Bearer " + token;
                ;
            }}
        >
            <I18nextProvider i18n={i18next}>
                <BrowserRouter>
                    <Routes>
                        {/*<Route path="/login" element={<Login/>}/>*/}
                        {/*<Route path="/login/forget-password" element={<ForgetPassword/>}/>*/}
                        {/*<Route path="/password/reset/:uuid" element={<ResetPassword/>}/>*/}

                        <Route path="/" element={<App/>}>
                            <Route path="/" element={<MainPage/>}/>
                            <Route path="/main-page" element={<MainPage/>}/>
                            <Route path="summaries" element={<Summaries/>}/>
                            <Route path="absences" element={<AbsencesListForHR/>}/>
                            <Route path="assignments" element={<AssignmentsGlobalList/>}/>
                            <Route path="delegations" element={<DelegationsListForHR/>}/>
                            <Route path="projects" element={<ProjectList/>}/>
                            <Route path="projects/add/:role" element={<ProjectAdd/>}/>
                            <Route path={projectDetails} element={<ProjectEdit/>}>
                                <Route path="data" element={<ProjectEditDetails/>}/>
                                <Route path="tasks" element={<ProjectTaskList/>}/>
                                <Route path="assets" element={<ProjectAssets/>}/>
                                <Route path="tasks/create" element={<ProjectTaskAdd/>}/>
                                <Route path="tasks/:taskId" element={<ProjectTaskEdit/>}/>
                                <Route path="assignments/create" element={<ProjectAssignmentAdd/>}/>
                                <Route path="assignments/create/:userId" element={<ProjectAssignmentAdd/>}/>
                                <Route path="assignments/:assignmentId" element={<ProjectAssignmentEdit/>}/>
                            </Route>

                            <Route path="days-off" element={<DaysOffList/>}/>
                            <Route path="days-off/add" element={<DaysOffAdd/>}/>
                            <Route path="days-off/edit/:dayOffId" element={<DayOffEdit/>}/>

                            <Route path="users" element={<UsersList/>}/>
                            <Route path="users/add" element={<UserAdd/>}/>
                            <Route path="users/:userId" element={<UserScreen/>}>
                                <Route path="data" element={<UserEdit/>}/>
                                <Route path="absences" element={<AbsencesList/>}/>
                                <Route path="absences/create" element={<UserAbsenceAdd/>}/>
                                <Route path="absences/:absenceId" element={<UserAbsenceEdit/>}/>
                                <Route path="delegations/:delegationId" element={<UserDelegationEdit/>}/>
                                <Route path="roles" element={<RolesList/>}/>
                                <Route path="delegations/create" element={<UserDelegationAdd/>}/>
                            </Route>

                            <Route path="reports" element={<ReportsList/>}/>
                            <Route path="create-reports" element={<CreateReports/>}/>
                            <Route path="reports-hr" element={<ReportsListForHR/>}/>
                            <Route path="my-reports" element={<MyReportsList/>}/>
                            <Route path="report-for-leader" element={<ReportsListForLeader/>}/>
                            <Route path="report-for-supervisor" element={<ReportsListForSupervisor/>}/>
                            <Route path="reports/edit/:userId/:year/:month" element={<ReportEdit/>}/>

                            <Route path="month-hr-readiness" element={<MonthHRReadiness/>}/>
                            <Route path="tools" element={<Tools/>}/>
                            <Route path="events" element={<ProcessedEventsList/>}/>
                            <Route path="emails" element={<EmailsList/>}/>
                            <Route path="remote-work-rates" element={<RemoteWorkRatesList/>}/>
                            <Route path="remote-work-rates/add" element={<RemoteWorkRateAdd/>}/>
                            <Route path="remote-work-rates/edit/:id" element={<RemoteWorkRateEdit/>}/>
                            <Route path="remote-work-permit" element={<RemoteWorkPermitList/>}/>
                            <Route path="remote-work-permit/create" element={<RemoteWorkPermitAdd/>}/>
                            <Route path="remote-work-permit/create/:userId" element={<RemoteWorkPermitAdd/>}/>
                            <Route path="remote-work-permit/:id" element={<RemoteWorkPermitEdit/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </I18nextProvider>
        </ReactKeycloakProvider>}
        </>
    )
};