import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import asyncFetch from "../../../api/async-fetch";
import BlockFormAlert from "../../general/BlockFormAlert";
import {fetchProject} from "../projectUtil";
import {toast} from "react-toastify";
import {t} from "i18next";
import AuthService from "../../../AuthService";


export default function ProjectAssets() {

    const [disabled, setDisabled] = React.useState(true);
    const navigate = useNavigate();
    const projectId = useParams().projectId
    const [inProgress, setInProgress] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [project, setProject] = React.useState(null);
    let allowEditing = project && !project.archived && AuthService.hasAnyRole([project.managedByRole])

    useEffect(() => {
        fetchProject(projectId, setProject)
    }, [])

    const getMilis = () => {
        const d = new Date();
        return d.getMilliseconds();
    }
    const [rand, setRand] = React.useState(getMilis());


    const onFileChange = async (event, logoOrFooter) => {
        event.preventDefault();

        let file = event.target.files[0];

        let url = BACKEND_URL + "projects/" + projectId + "/" + logoOrFooter

        const baseRequestConfig = getBaseRequestConfig("POST")
        delete baseRequestConfig.headers["Content-Type"];
        let data = new FormData();
        data.append('file', file);

        const requestConfig = {...baseRequestConfig, body: data}


        try {
            setFailed(false);
            setSuccess(false);
            setInProgress(true);
            const response = await asyncFetch(url, requestConfig);
        } catch (e) {
            setFailed(true)
            toast.danger(t("errorOccurred"))
        }
        setInProgress(false);
        setRand(getMilis)

        if (!failed) {
            setSuccess(true)
        }

    };


    return (
        <>
            {allowEditing && <BlockFormAlert disableCallback={setDisabled}/> }
            {project &&
            <form className="row">
                <div className="form-floating mb-3">
                    <h3>Logo [{project.logoFile}]</h3>
                </div>
                <div className="form-floating mb-3 col-6">
                    <img className="col-6" src={BACKEND_URL + "assets/project/" + projectId + "/logo?rand=" + rand}></img>
                </div>
                <div className="form-floating mb-3">
                    <input
                        disabled={disabled}
                        id="logo-selector" type="file" onChange={(event) => onFileChange(event, "logo")}/>
                </div>

                <hr></hr>

                <div className="form-floating mb-3">
                    <h3>Stopka [{project.footerFile}]</h3>
                </div>
                <div className="form-floating mb-3 col-6">
                    <img className="col-12" src={BACKEND_URL + "assets/project/" + projectId + "/footer?rand=" + rand}></img>
                </div>
                <div className="form-floating mb-3">
                    <input id="footer-selector"
                           disabled={disabled}
                           type="file" onChange={(event) => onFileChange(event, "footer")}/>
                </div>

            </form>
            }

            {failed && <div className="alert alert-warning" role="alert">
                Wystąpił błąd podczas dodawania pliku
            </div>}

            {success && <div className="alert alert-success" role="alert">
                Plik został wgrany
            </div>}

        </>
    );
}