import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {BACKEND_URL} from "../../config";

export const fetchProject = (projectId, callback) => {
    let url = BACKEND_URL + 'projects/get/' + projectId
    const baseRequestConfig = getBaseRequestConfig("GET")
    syncFetchAndJSON(url, baseRequestConfig)
        .then((response) => {
            callback(response)
        })
        .catch((error) => {
            console.log(error)
        })
}
