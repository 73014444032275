import * as React from 'react';
import {useEffect} from 'react';
import RemoteWorkPermitForm from "./RemoteWorkPermitForm";
import {useNavigate, useParams} from "react-router-dom";
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";


export default function RemoteWorkPermitAdd() {

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1)
    };

    const userId = useParams().userId
    const [user, setUser] = React.useState(null);

    const fetchUser = React.useCallback(() => {
        let url = BACKEND_URL + 'users/get/' + userId
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setUser(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        if (userId) {
            fetchUser()
        }
    }, [fetchUser])


    const isReady = () => {
        return !userId || user != null
    }

    const pa = {
        user: user,
        startDate: '',
        endDate: '',
        internetPermit: null,
        pcPermit: null,
        maxFactor: null,
    }

    return (
        <>

            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Dodaj pracę zdalną</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button onClick={() => handleBack()} type="button"
                                className="btn btn-sm btn-outline-secondary">&laquo; Powrót do listy
                        </button>
                    </div>
                </div>
            </div>
        {isReady() && <RemoteWorkPermitForm pa={pa} key={"createWorkPermit"} showActions={true} />}


    </>
    );
}