import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";


import * as React from 'react';
import {useEffect, useRef} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {reportEditUrl} from "./reportsRouting";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {GetYearOptions, MonthOptions, RenderStatus, StatusOptions} from "./StatusUtils";
import {useTranslation} from "react-i18next";
import {filterAndMapUserToSelectOption, mapUserToSelectOption} from "../users/userUtils";
import {getSearchParams, getSearchParamsBool, getSearchParamsInt} from "./reportUtils";
import DownloadPDF from "./DownloadPDF";
import {goToUserDetails} from "../users/usersRouting";
import {goToProjectDetails} from "../projects/projectsRouting";
import SortByIcon from "./SortByIcon";
import AuthService, {PROJECT_CURATOR} from "../../AuthService";
import {freezeReport} from "./reportFreeze";
import {toast} from "react-toastify";
import {REPORT_TYPE_REMOTE_TIME, REPORT_TYPE_WORK_TIME} from "./ReportType";


export default function ReportsList() {
    const {t} = useTranslation()
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const loggedUser = AuthService.getCurrentUser();


    const [sortBy, setSortBy] = React.useState({by: "date", direction: "DESC"});
    const [month, setMonth] = React.useState(getSearchParams(searchParams, "month", null));
    const [year, setYear] = React.useState(getSearchParams(searchParams, "year", null));
    const [user, setUser] = React.useState(getSearchParams(searchParams, "user", null));
    const [leader, setLeader] = React.useState(getSearchParams(searchParams, "leader", null));
    const [supervisor, setSupervisor] = React.useState(getSearchParams(searchParams, "supervisor", null));
    const [curator, setCurator] = React.useState(getSearchParams(searchParams, "curator", null));
    const [project, setProject] = React.useState(getSearchParams(searchParams, "project", null));
    const [statuses, setStatuses] = React.useState(getSearchParams(searchParams, "statuses", ""));
    const [requiresAttention, setRequiresAttention] = React.useState(getSearchParamsBool(searchParams, "requiresAttention", false));
    const [onlyRemoteWorkReports, setOnlyRemoteWorkReports] = React.useState(getSearchParamsBool(searchParams, "onlyRemoteWorkReports", false));
    const [notAcceptedByCurator, setNotAcceptedByCurator] = React.useState(getSearchParamsBool(searchParams, "notAcceptedByCurator", false));
    const [hasNotSolvedComments, setHasNotSolvedComments] = React.useState(getSearchParamsBool(searchParams, "hasNotSolvedComments", false));
    const [pageNumber, setPageNumber] = React.useState(getSearchParamsInt(searchParams, "pageNumber", 0));


    // useEffect(() => {
    //     setPageNumber(getSearchParamInt("pageNumber"))
    // }, [pageNumber])


    const [totalPages, setTotalPages] = React.useState(1)
    const [itemsPerPage] = React.useState(20);
    const [users, setUsers] = React.useState([]);

    const [curators, setCurators] = React.useState([]);
    const [projectLeaders, setProjectLeaders] = React.useState([]);
    const [supervisors, setSupervisors] = React.useState([]);
    const [projects, setProjects] = React.useState([]);
    const [projectsData, setProjectsData] = React.useState([]);
    // setCurator(mapUserToSelectOption(AuthService.getCurrentUser()))

    const [reports, setReports] = React.useState([]);
    const [failedToFrozen, setFailedToFrozen] = React.useState([]);

    const myRef = useRef(null)

    const persistSearchState = (k, v, cleanPageNumber) => {
        let params = {
            month,
            year,
            user,
            leader,
            curator,
            project,
            statuses,
            pageNumber,
            supervisor,
            requiresAttention,
            notAcceptedByCurator,
            hasNotSolvedComments,
            onlyRemoteWorkReports
        }
        params[k] = v

        if (cleanPageNumber) {
            params.pageNumber = 0
        }

        setSearchParams(createSearchParams(params))
    }

    const setMonthHandler = (v) => {
        let val = v ? v.value : null
        setMonth(val);
        setPageNumber(0);
        persistSearchState("month", val, true);
    }
    const setYearHandler = (v) => {
        let val = v ? v.value : null
        setYear(val);
        setPageNumber(0);
        persistSearchState("year", val, true);
    }
    const setProjectHandler = (v) => {
        let val = v ? v.value : null
        setProject(val);
        setPageNumber(0);
        persistSearchState("project", val, true);
    }
    const setUserHandler = (v) => {
        let val = v ? v.value : null
        setUser(val);
        setPageNumber(0);
        persistSearchState("user", val, true);
    }
    const setLeaderHandler = (v) => {
        let val = v ? v.value : null
        setLeader(val);
        setPageNumber(0);
        persistSearchState("leader", val, true);
    }
    const setSupervisorHandler = (v) => {
        let val = v ? v.value : null
        setSupervisor(val);
        setPageNumber(0);
        persistSearchState("supervisor", val, true);
    }
    const setCuratorHandler = (v) => {
        let val = v ? v.value : null
        setCurator(val);
        setPageNumber(0);
        persistSearchState("curator", val, true);
    }
    const setPageNumberHandler = (v) => {
        setPageNumber(v);
        persistSearchState("pageNumber", v);
    }
    const setStatusesHandler = (v) => {
        let val = v ? v.map(c => c.value).join(',') : null

        setStatuses(val);
        setPageNumber(0);
        persistSearchState("statuses", val, true);
    }

    const toggleRequiresAttention = () => {
        let requiresAttentionNew = !requiresAttention

        setRequiresAttention(requiresAttentionNew);
        setPageNumber(0);
        persistSearchState("requiresAttention", requiresAttentionNew, true);
    }
    const toggleRemoteWorkReportsOnly = () => {
        let onlyRemoteWorkReportsNew = !onlyRemoteWorkReports

        setOnlyRemoteWorkReports(onlyRemoteWorkReportsNew);
        setPageNumber(0);
        persistSearchState("onlyRemoteWorkReports", onlyRemoteWorkReportsNew, true);
    }

    const toggleNotAcceptedByCurator = () => {
        let notAcceptedByCuratorNew = !notAcceptedByCurator

        setNotAcceptedByCurator(notAcceptedByCuratorNew);
        setPageNumber(0);
        persistSearchState("notAcceptedByCurator", notAcceptedByCuratorNew, true);
    }
    const toggleHasNotSolvedComments = () => {
        let hasNotSolvedCommentsNew = !hasNotSolvedComments

        setHasNotSolvedComments(hasNotSolvedCommentsNew);
        setPageNumber(0);
        persistSearchState("hasNotSolvedComments", hasNotSolvedCommentsNew, true);
    }


    const fetchUsers = React.useCallback(() => {
        let url = BACKEND_URL + 'users/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setUsers(response.map(mapUserToSelectOption))
                setCurators(filterAndMapUserToSelectOption(response, "PROJECT_CURATOR", true))
                setProjectLeaders(filterAndMapUserToSelectOption(response, "PROJECT_LEADER"))
                setSupervisors(filterAndMapUserToSelectOption(response, "SUPERVISOR"))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        fetchUsers()
    }, [fetchUsers])

    const fetchProjects = () => {
        let searchRequest = {
            pagination: {
                page: 0,
                pageSize: 1000
            }
        }

        let url = BACKEND_URL + 'projects/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setProjectsData(response.content)
                setProjects(response.content.map((p) => {
                    return {value: p.id, label: p.acronym}
                }))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const freezeSuccess = (updatedReports) => {
        const reportsCopy = [...reports]

        for (let r of updatedReports) {
            const index = reportsCopy.findIndex((c) => c.id === r.id);
            if (index > -1) {
                reportsCopy[index] = {...r}
            }
        }
        setReports(reportsCopy)

        toast.success("Karta została zamrożona.")
    }

    const freezeFailure = () => {
        toast.error("nie udało się zamrozić wybranej karty")
    }

    const freezeReportHandler = (reportId) => {
        freezeReport(reportId, freezeSuccess, freezeFailure);
    }

    const removeReportHandler = (reportId) => {
        let url = BACKEND_URL + `reports/${reportId}/delete`
        const baseRequestConfig = getBaseRequestConfig("DELETE")
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                toast.success("usunięto kartę")
            })
            .catch((error) => {
                toast.warning("wystąpił błąd")
            })
    }


    useEffect(() => {
        fetchProjects()
    }, [])

    const toReportUrl = (userId, year, month) => {
        navigate(reportEditUrl(userId, year, month))
    }

    const handleSortBy = (by) => {
        let direction = "ASC"

        if (sortBy && sortBy.by == by && sortBy.direction == "ASC") {
            direction = "DESC"
        }

        let newSortBy = {
            by: by,
            direction: direction
        }

        setSortBy(newSortBy);
    }


    const prepareSearchRequest = () => {
        let searchRequest = {
            sortBy: sortBy,
            pagination: {
                page: pageNumber,
                pageSize: itemsPerPage,
            }
        }

        if (year != null) {
            searchRequest.year = year
        }

        if (month != null) {
            searchRequest.month = month
        }

        if (user != null) {
            searchRequest.userId = user
        }

        if (leader != null) {
            searchRequest.leaderUserId = leader
        }

        if (curator != null) {
            searchRequest.curatorUserId = curator
        }

        if (supervisor != null) {
            searchRequest.supervisorId = supervisor
        }

        if (project != null && onlyRemoteWorkReports != true) {
            searchRequest.projectId = project
        }

        searchRequest.statuses = [];
        if (statuses) {
            searchRequest.statuses = statuses.split(",")
        }

        if (requiresAttention != null && requiresAttention == true) {
            searchRequest.requiresCuratorAttention = true
        }

        if (notAcceptedByCurator != null && notAcceptedByCurator == true) {
            searchRequest.notAcceptedByCurator = true
        }

        if (hasNotSolvedComments != null && hasNotSolvedComments == true) {
            searchRequest.hasNotSolvedComments = true
        }

        if (onlyRemoteWorkReports != null && onlyRemoteWorkReports == true) {
            searchRequest.reportType = REPORT_TYPE_REMOTE_TIME
        }

        return searchRequest;
    }

    const freezeBulkHandler = () => {
        let searchRequest = prepareSearchRequest();

        let url = BACKEND_URL + 'reports/freeze-bulk'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                fetchReports();
                setFailedToFrozen(response)
                setTimeout(() => {
                    myRef.current.scrollIntoView()
                }, 100);
                toast.success("zakończono proces zamrażania kart")
            })
            .catch((error) => {
                toast.warning("wystąpił bład przy zamrażaniu")
            })
    }

    const fetchReports = () => {
        let searchRequest = prepareSearchRequest();

        let url = BACKEND_URL + 'reports/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setReports(response.content)
                setTotalPages(response.totalPages)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const selectedProject = projectsData && project ? projectsData.find(p => p.id == project) : null

    useEffect(() => {
        fetchReports()
    }, [pageNumber, itemsPerPage, year, month, user, leader, project, curator, statuses, supervisor, requiresAttention, notAcceptedByCurator, hasNotSolvedComments, onlyRemoteWorkReports, sortBy])

    const handlePageClick = (event) => {
        setPageNumberHandler(event.selected)
    };


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3 className="h3">Karty czasu pracy</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">

                    </div>
                </div>
            </div>

            <div className="form-row row">
                <div className="col-1">
                    <Select
                        placeholder={t("month")}
                        value={MonthOptions.filter(obj => month && obj.value.toString() === month.toString())}
                        className="basic-single"
                        onChange={setMonthHandler}
                        classNamePrefix="select"
                        isClearable={true}
                        name="color"
                        options={MonthOptions}
                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                    />
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("year")}
                        className="basic-single"
                        onChange={setYearHandler}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        name="color"
                        value={GetYearOptions().filter(obj => year && obj.value.toString() === year.toString())}
                        options={GetYearOptions()}
                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                    />
                </div>
                <div className="col-2">
                    <Select
                        isDisabled={onlyRemoteWorkReports}
                        placeholder={t("project")}
                        className="basic-single"
                        onChange={setProjectHandler}
                        value={projects.filter(obj => obj.value.toString() === project)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={projects}
                    />
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("employee")}
                        className="basic-single"
                        onChange={setUserHandler}
                        value={users.filter(obj => obj.value === user)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={users}
                    />
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("leader")}
                        className="basic-single"
                        onChange={setLeaderHandler}
                        value={projectLeaders.filter(obj => obj.value === leader)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={projectLeaders}
                    />
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("supervisor")}
                        className="basic-single"
                        onChange={setSupervisorHandler}
                        value={supervisors.filter(obj => obj.value === supervisor)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={supervisors}
                    />
                </div>
            </div>
            <div className="form-row row second-row-of-form">
                <div className="col-2">
                    <Select
                        placeholder={t("curator")}
                        className="basic-single"
                        onChange={setCuratorHandler}
                        value={curators.filter(obj => obj.value === curator)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={curators}
                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                    />
                </div>
                <div className="col-3">
                    <Select
                        className="basic-single"
                        placeholder={t("status")}
                        onChange={setStatusesHandler}
                        value={StatusOptions.filter(obj => statuses.split(",").includes(obj.value))}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isMulti={true}
                        name="color"
                        options={StatusOptions}
                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                    />
                </div>
                <div className="col-1">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               onClick={() => toggleRequiresAttention()}
                               checked={requiresAttention}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">wym. uwagi</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="notAcceptedByCurator"
                               onClick={() => toggleNotAcceptedByCurator()}
                               checked={notAcceptedByCurator}/>
                        <label className="form-check-label" htmlFor="notAcceptedByCurator">niesprawdzone</label>
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="hasNotSolvedComments"
                               onClick={() => toggleHasNotSolvedComments()}
                               checked={hasNotSolvedComments}/>
                        <label className="form-check-label" htmlFor="hasNotSolvedComments">otwarte komentarze</label>
                    </div>
                </div>
                <div className="col-1">
                    {year && month && selectedProject && selectedProject.curator.id == loggedUser.id
                        && <button type="button" onClick={() => freezeBulkHandler()}
                                   className="btn btn-sm btn-outline-primary">
                            Zamróź <i className="bi bi-snow"></i></button>}
                </div>
            </div>


            <div className="text-nowrap">
                <table className="table table-striped table-sm table-hover table-fixed">
                    <thead>
                    <tr>
                        <th scope="col" style={{"width": "1%"}}></th>
                        <th scope="col" style={{"width": "14%"}} className="sortable"
                            onClick={() => handleSortBy("date")}>Miesiąc i rok
                            <SortByIcon sortBy={sortBy} by="date"/>
                        </th>
                        <th scope="col" style={{"width": "15%"}} className="sortable"
                            onClick={() => handleSortBy("projectAcronym")}>Projekt [akronim]
                            <SortByIcon sortBy={sortBy} by="projectAcronym"/>
                        </th>
                        <th scope="col" style={{"width": "15%"}}>Status</th>
                        <th scope="col" style={{"width": "15%"}} className="sortable"
                            onClick={() => handleSortBy("employee")}>Pracownik
                            <SortByIcon sortBy={sortBy} by="employee"/>
                        </th>
                        <th scope="col" style={{"width": "20%"}} className="sortable"
                            onClick={() => handleSortBy("projectLeader")}>Kierownik
                            <SortByIcon sortBy={sortBy} by="projectLeader"/>
                        </th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {reports.map((row) => (
                        <tr key={row.id}>
                            <td></td>
                            <td>{row.month}-{row.year}</td>
                            <td><a
                                onClick={() => goToProjectDetails(navigate, row.project)}>{row.reportType == REPORT_TYPE_WORK_TIME ? row.project.acronym : "PRACA ZDALNA"}</a>
                            </td>
                            <td>{RenderStatus(row)} {row.acceptanceCurator &&
                                <span className="badge rounded-pill bg-success">sprawdzona</span>}</td>
                            <td><a
                                onClick={() => goToUserDetails(navigate, row.userSnapshot.id)}>{row.userSnapshot.firstName} {row.userSnapshot.lastName}</a>
                            </td>
                            <td>{row.projectLeader ? `${row.projectLeader.firstName} ${row.projectLeader.lastName}` : null}
                                {row.projectLeader2 ? `, ${row.projectLeader2.firstName} ${row.projectLeader2.lastName}` : null}
                            </td>
                            <td>
                                <button type="button" className="btn btn-sm btn-outline-secondary"
                                        onClick={() => toReportUrl(row.userSnapshot.id, row.year, row.month)}>otwórz &raquo;</button>
                                <DownloadPDF report={row} allowForNonAccepted={true}/>
                                {row.curator && loggedUser.id == row.curator.id && !row.frozen && row.accepted &&
                                    <button type="button" onClick={() => freezeReportHandler(row.id)}
                                            className="btn btn-sm btn-outline-primary">
                                        Zamróź <i className="bi bi-snow"></i></button>}
                                <button style={{"display": "none"}} type="button"
                                        onClick={() => removeReportHandler(row.id)}
                                        className="btn btn-sm btn-outline-primary">
                                    Usuń
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {
                (!reports || reports.length == 0) && <div className="alert alert-dark" role="alert">
                    Brak danych do wyświetlenia
                </div>
            }
            <div className="row">
                <ReactPaginate
                    nextLabel="&raquo;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    forcePage={pageNumber}
                    pageCount={totalPages}
                    previousLabel="&laquo;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>

            {failedToFrozen && failedToFrozen.length > 0
                &&
                <div className="row" id="frozenFailedList" ref={myRef}>

                    <div className="col-6 ">
                        <div className="row border border-warning ">
                            <div className={"col-10"}>
                                <h3>Raporty, których nie udało się zamrozić [{failedToFrozen.length}]
                                </h3>
                            </div>
                            <div className={"col-2"}>
                                <span className="badge rounded-pill bg-warning"
                                onClick={() => setFailedToFrozen([])}>
                                    zamknij X
                                </span>
                            </div>

                            <table className="table table-striped table-sm table-hover">


                                <thead>
                                <tr>
                                    <th scope="col" style={{"width": "1%"}}></th>
                                    <th scope="col" style={{"width": "10%"}} className="sortable"
                                        onClick={() => handleSortBy("date")}>Miesiąc i rok
                                    </th>
                                    <th scope="col" style={{"width": "10%"}} className="sortable"
                                        onClick={() => handleSortBy("projectAcronym")}>Projekt [akronim]
                                    </th>
                                    <th scope="col" style={{"width": "25%"}}>Status</th>
                                    <th scope="col" style={{"width": "15%"}} className="sortable"
                                        onClick={() => handleSortBy("employee")}>Pracownik
                                    </th>

                                </tr>
                                </thead>
                                <tbody>
                                {failedToFrozen.map((row) => (
                                    <tr key={row.id}>
                                        <td></td>
                                        <td>{row.month}-{row.year}</td>
                                        <td>{row.project.acronym}</td>
                                        <td>{RenderStatus(row)} {row.acceptanceCurator &&
                                            <span className="badge rounded-pill bg-success">sprawdzona</span>}</td>
                                        <td>{row.userSnapshot.firstName} {row.userSnapshot.lastName}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            }

        </>
    );
}