import * as React from 'react';
import {useEffect} from 'react';
import ProjectAssignmentDataForm from "./ProjectAssignmentDataForm";
import {useParams} from "react-router-dom";
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../../api/async-fetch";
import BlockFormAlert from "../../general/BlockFormAlert";
import AuthService from "../../../AuthService";
import {fetchProject} from "../projectUtil";

export default function ProjectAssignmentEdit() {
    const projectId = useParams().projectId
    const assignmentId = useParams().assignmentId
    const [disabled, setDisabled] = React.useState(true);
    const [assignment, setAssignment] = React.useState(null);
    const [project, setProject] = React.useState(null);
    let allowEditing = (project && !project.archived)

    useEffect(() => {
        fetchProject(projectId, setProject)
    }, [])
    let ownProject = project && !project.archived && AuthService.hasAnyRole([project.managedByRole])

    const data =  assignment ? {
        taskId: assignment.projectTask ? assignment.projectTask.id : null,
        user: assignment.employee,
        supervisor: assignment.supervisor,
        startDate: assignment.startDate,
        endDate: assignment.endDate,
        assigmentType: assignment.projectAssignmentDimension && assignment.projectAssignmentDimension.hours ? 'hour' : 'partTime',
        assigmentDimension: assignment.projectAssignmentDimension && assignment.projectAssignmentDimension.hours ?
            assignment.projectAssignmentDimension.hours :  (assignment.projectAssignmentDimension && assignment.projectAssignmentDimension.partOfFullTime ? assignment.projectAssignmentDimension.partOfFullTime : 0.0),
    } : {}

    const fetchAssignment = () => {
        let url = BACKEND_URL + `projects/${projectId}/assigment/get/${assignmentId}`

        const baseRequestConfig = getBaseRequestConfig("GET")

        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setAssignment(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchAssignment()
    }, [])


    return (
        <>
            {ownProject && allowEditing && <BlockFormAlert disableCallback={setDisabled}/>}
        {assignment && <ProjectAssignmentDataForm pa={data} key={"editProjectAssigment"} disabled={disabled} showActions={allowEditing && ownProject}/>}
            </>
            );
}