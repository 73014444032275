import * as React from 'react';
import {useEffect} from 'react';
import RemoteWorkPermitForm from "./RemoteWorkPermitForm";
import {useNavigate, useParams} from "react-router-dom";
import BlockFormAlert from "../general/BlockFormAlert";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {BACKEND_URL} from "../../config";

export default function RemoteWorkPermitEdit() {
    const assignmentId = useParams().id
    const [disabled, setDisabled] = React.useState(true);
    const [assignment, setAssignment] = React.useState(null);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1)
    };


    const data =  assignment ? {
        user: assignment.employee,
        startDate: assignment.startDate,
        endDate: assignment.endDate,
        internetPermit: assignment.internetPermit,
        pcPermit: assignment.pcPermit,
        maxFactor: assignment.maxFactor,
    } : {}

    const fetchAssignment = () => {
        let url = BACKEND_URL + `remote-work-permit/get/${assignmentId}`

        const baseRequestConfig = getBaseRequestConfig("GET")

        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setAssignment(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchAssignment()
    }, [])


    return (
        <>            <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Edytuj pracę zdalną</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                    <button onClick={() => handleBack()} type="button"
                            className="btn btn-sm btn-outline-secondary">&laquo; Powrót do listy
                    </button>
                </div>
            </div>
        </div>
            {<BlockFormAlert disableCallback={setDisabled}/>}
        {assignment && <RemoteWorkPermitForm pa={data} key={"editProjectAssigment"} disabled={disabled} showActions={true}/>}
            </>
            );
}