import {useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import ProjectDataForm from "./ProjectDataForm";
import BlockFormAlert from "../general/BlockFormAlert";
import AuthService, {PROJECT_CURATOR, SUPER_HR} from "../../AuthService";

export default function ProjectEditDetails() {

    const [project, setProject] = React.useState(null);
    const [disabled, setDisabled] = React.useState(true);
    let isCurator = AuthService.hasAnyRole([PROJECT_CURATOR])
    let isSuperHR = AuthService.hasAnyRole([SUPER_HR])
    let allowEditing = project && !project.archived && AuthService.hasAnyRole([project.managedByRole])
    let showUnarchiveBtn = (project && project.archived) && isSuperHR

    let params = useParams();

    const fetchProject = () => {

        let url = BACKEND_URL + 'projects/get/'+params.projectId
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setProject(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const activateProject = () => {
        let url = BACKEND_URL + 'projects/'+params.projectId+'/unarchive'
        const baseRequestConfig = getBaseRequestConfig("POST")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setProject(null);
                fetchProject()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchProject()
    }, [])

    return (
            <div>
                {allowEditing && <BlockFormAlert disableCallback={setDisabled}/>}

                {showUnarchiveBtn && <div className="card col-6">
                    <div className="card-body">
                        <h5 className="card-title">Uwaga!</h5>
                        <p className="card-text text-secondary">Projekt jest zarchizowany.</p>
                        <button onClick={activateProject} type="button"
                                className="btn btn-outline-primary pull-right">Aktywuj
                        </button>
                    </div>
                </div>}

                {project && <ProjectDataForm project={project} disabled={disabled} showActions={allowEditing}/>}
            </div>
    );
}