import {NavLink, Outlet, useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {projectAssetsUrl, projectDataUrl, projectTasksUrl} from "./projectsRouting";
import AuthService, {PROJECT_CURATOR} from "../../AuthService";
import {goToAssigments} from "../assignments/assigmentsRouting";

export default function ProjectEdit() {
    const navigate = useNavigate();
    const params = useParams();
    let isCurator = AuthService.hasAnyRole([PROJECT_CURATOR])
    const projectId = params.projectId
    const [project, setProject] = React.useState(null);

    const handleBack = () => {
        navigate(-1)
    };

    const fetchProject = () => {
        let url = BACKEND_URL + 'projects/get/'+projectId
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setProject(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchProject()
    }, [])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3 className="h3">Projekt: {project && project.acronym}</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button onClick={() => handleBack()} type="button"
                                className="btn btn-sm btn-outline-secondary">&laquo; Powrót
                        </button>
                    </div>
                </div>
            </div>

            <ul className="nav nav-tabs">
                <li className="nav-item">
                <NavLink to={projectDataUrl(projectId)} className="nav-link"  activeClassName="active">
                    Dane projektu
                </NavLink>
                </li>
                <li className="nav-item">
                <NavLink to={projectTasksUrl(projectId)} className="nav-link"  activeClassName="active">
                    Zadania
                </NavLink>
                </li>
                <li className="nav-item">
                    <a className="nav-link"
                       onClick={(event) => {event.preventDefault(); goToAssigments(navigate, projectId,null )}}
                       href="#">
                        Pracownicy (zaangażowania)
                    </a>
                </li>
                <li className="nav-item">
                <NavLink to={projectAssetsUrl(projectId)} className="nav-link"  activeClassName="active">
                    Logo, stopka
                </NavLink>
                </li>


            </ul>


            {project && <Outlet />}
        </>
    );
}