import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {projectDataUrl} from "./projectsRouting";
import {trimToLength} from "../stringUtils";
import AuthService, {HR, PROJECT_CURATOR} from "../../AuthService";
import Select from "react-select";
import {getSearchParams} from "../reports/reportUtils";
import {useTranslation} from "react-i18next";
import {LanguageOptions} from "../reports/StatusUtils";


export default function ProjectList() {
    const {t} = useTranslation()

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [project, setProject] = React.useState(getSearchParams(searchParams, "project",null));

    const [projects, setProjects] = React.useState([]);
    const [allProjects, setAllProjects] = React.useState([]);


    const [date, setDate] = React.useState(getSearchParams(searchParams, "date",null));
    const [language, setLanguage] = React.useState(getSearchParams(searchParams, "lang",null));
    const [activeOnly, setActiveOnly] = React.useState(getSearchParams(searchParams, "activeOnly",true));


    let isCurator = AuthService.hasAnyRole([PROJECT_CURATOR])
    let isHR = AuthService.hasAnyRole([HR])

    const gotoAddProject = async (role) => {
        navigate("/projects/add/"+role)
    }

    const toProjectDetails = (projectId) => {
        navigate(projectDataUrl(projectId))
    }

    const fetchProjects = () => {
        let searchRequest = {
            pagination: {
                page: 0,
                pageSize: 1000
            },
        }

        if (project) {
            searchRequest.projectId = project
        }

        if (date) {
            searchRequest.date = date
        }

        if (language) {
            searchRequest.language = language
        }

        if (activeOnly) {
            searchRequest.activeOnly = activeOnly
        }

        let url = BACKEND_URL + 'projects/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setProjects(response.content)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fetchAllProjects = () => {
        let searchRequest = {
            pagination: {
                page: 0,
                pageSize: 1000
            }
        }

        let url = BACKEND_URL + 'projects/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setAllProjects(response.content.map((p) => {return {value: p.id, label: p.acronym}}))
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const persistSearchState = (k,v, cleanPageNumber) => {
        let params = {
            project, date, language, activeOnly
        }
        params[k] = v

        if (cleanPageNumber) {
            params.pageNumber = 0
        }

        setSearchParams(createSearchParams(params))
    }

    const handleSetDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value);
        persistSearchState("date", event.target.value, true);
    };

    const setProjectHandler = (v) => {
        let val = v ? v.value : null
        setProject(val);
        persistSearchState("project", val, true);
    }

    const setLanguageHandler = (v) => {
        let val = v ? v.value : null
        setLanguage(val);
        persistSearchState("language", val, true);
    }

    const toggleactiveOnly = () => {
        let activeOnlyNew = !activeOnly

        setActiveOnly(activeOnlyNew);
        persistSearchState("activeOnly", activeOnlyNew);
    }

    useEffect(() => {
        fetchProjects()
    }, [project, date, language, activeOnly])


    useEffect(() => {
        fetchAllProjects()
    }, [])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3 className="h3">Projekty</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {isCurator && <button onClick={() => gotoAddProject(PROJECT_CURATOR)} type="button"
                                className="btn btn-sm btn-primary">Dodaj projekt [DOBP]
                        </button> }
                        {isHR && <button onClick={() => gotoAddProject(HR)} type="button"
                                              className="btn btn-sm btn-primary">Dodaj projekt [KADRY]
                        </button> }
                    </div>
                </div>

            </div>

            <div className="form-row row">
                <div className="col-2">
                    <Select
                        placeholder={t("acronym")}
                        className="basic-single"
                        onChange = {setProjectHandler}
                        value={allProjects.filter(obj => obj.value.toString() === project)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={allProjects}
                    />
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("language")}
                        value={LanguageOptions.filter(obj => language && obj.value.toString() === language)}
                        className="basic-single"
                        onChange = {setLanguageHandler}
                        classNamePrefix="select"
                        isClearable={true}
                        name="color"
                        options={LanguageOptions}
                    />
                </div>
                <div className="col-2">
                    <input type="date"
                           className="form-control"
                           value={date}
                           onChange={handleSetDate}
                    ></input>
                </div>
                <div className="col-2">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               onClick={() => toggleactiveOnly()}
                               checked={activeOnly}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">tylko aktywne</label>
                    </div>
                </div>
                <div className="col-1">
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Akronim</th>
                        <th scope="col">Nazwa</th>
                        <th scope="col">Numer</th>
                        <th scope="col">Język</th>
                        <th scope="col">Czas trwania</th>
                        <th scope="col">Archiwalny</th>
                        <th scope="col">Właściciel</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {projects.map((row) => (
                        <tr key={row.id} onClick={() => toProjectDetails(row.id)}>
                            <td></td>
                            <td>{trimToLength(row.acronym, 40)}</td>
                            <td>{trimToLength(row.name, 40)}</td>
                            <td>{trimToLength(row.signature, 40)}</td>
                            <td>{row.language}</td>

                            <td>{row.startDate} - {row.endDate}</td>
                            <td>{row.archived && <i className="bi bi-check-square-fill"></i>}</td>
                            <td>{t(row.managedByRole)}</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => toProjectDetails(row.id)}>

                                    edytuj &raquo;</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}