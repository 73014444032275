import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import asyncFetch from "../../../api/async-fetch";
import {projectTasksUrl} from "../projectsRouting";
import {toast} from "react-toastify";
import {t} from "i18next";

export default function ProjectTaskForm(props) {

    const navigate = useNavigate();

    let projectId = useParams().projectId;
    let taskId = useParams().taskId;
    let {disabled} = props

    const [name, setName] = React.useState(props.taskData.name);
    const [startDate, setStartDate] = React.useState(props.taskData.startDate);
    const [endDate, setEndDate] = React.useState(props.taskData.endDate);

    const handleSetName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleSetStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleSetEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    const nameValid = name && name.trim() != ""
    let startDateValid = startDate != ""
    let endDateValid = endDate != ""

    if (startDate && endDate) {
        const d1 = new Date(startDate);
        const d2 = new Date(endDate);

        if (d1 >= d2) {
            startDateValid = endDateValid = false;
        }
    }

    const isValid = () => {
        return nameValid && startDateValid && endDateValid
    }

    const handleDelete = async () => {
        if (window.confirm("Czy na pewno chcesz usunąć wybrane zadanie?") != true) {
            return;
        }


        let url = BACKEND_URL + 'projects/'+projectId+'/task/'+taskId+'/delete'

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            toast.success(t("dataDeleted"))
            navigate(-1)
        }
    }

    const handleSave = async () => {

        let objToSave = {name, startDate, endDate}

        let url = BACKEND_URL + 'projects/'+projectId+'/task'
        if (taskId) {
            url += "/"+taskId
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            toast.success(t("dataSaved"))
            navigate(projectTasksUrl(projectId))
        }
    }

    return (
        <>
            <form>
                <div className="mb-3 col-6">
                    <label htmlFor="acronymInput" className="form-label">Nazwa</label>
                    <input type="text" required={true}  className={"form-control" + (!nameValid ? ' is-invalid' : '')} id="acronymInput" aria-describedby="Nazwa"
                           value={name}
                           disabled={disabled}
                           onChange={handleSetName}
                    />
                </div>
                <div className="mb-3  col-2">
                    <label htmlFor="acronymInput" className="form-label">Data rozpoczęcia</label>
                    <input type="date" required={true}  className={"form-control" + (!startDateValid ? ' is-invalid' : '')} id="acronymInput" aria-describedby="Data rozpoczęcia"
                           value={startDate}
                           disabled={disabled}
                           onChange={handleSetStartDate}
                    />
                </div>
                <div className="mb-3 col-2">
                    <label htmlFor="acronymInput" className="form-label">Data zakończenia</label>
                    <input type="date" required={true}  className={"form-control" + (!endDateValid ? ' is-invalid' : '')} id="acronymInput" aria-describedby="Data zakończenia"
                           value={endDate}
                           disabled={disabled}
                           onChange={handleSetEndDate}
                    />
                </div>

                {props.showActions && <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-primary"
                                disabled={!isValid() || disabled}
                                onClick={handleSave}
                        >Zapisz
                        </button>
                    </div>

                    {taskId && <div className="btn-group me-2">
                        <button type="button" className="btn btn-sm btn-danger"
                                disabled={disabled}
                                onClick={handleDelete}
                        >
                            <span className="bi bi-trash"></span> Usuń</button>
                    </div>}
                </div>}
            </form>
        </>
    );
}