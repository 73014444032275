import * as React from 'react';
import {useTranslation} from "react-i18next";
import ReplyToComment from "./ReplyToComment";
import AddNewCommentsTopic from "./AddNewCommentsTopic";
import {formatDate} from "../../DateUtil";


export default function Comments(props) {

    const {t} = useTranslation()
    const {reportId, project} = props

    return (
        <>
            {
                props.commentTopics.map((ct) => {
                    return (
                        <div className={"card"}>
                            <div className="card-body">

                            <h6 className="card-subtitle mb-2 text-muted">
                                {
                                    ct.resolvedAt &&
                                    <i className="bi bi-check-circle text-success" title={"Oznaczony jako rozwiązany: " + formatDate(ct.resolvedAt) + " przez " + ct.resolvedBy.firstName  + " " + ct.resolvedBy.lastName}></i>
                                }
                                <small>&nbsp;{formatDate(ct.comment.createdAt)}</small> {ct.comment.author.firstName} {ct.comment.author.lastName}

                                &nbsp;do: <b>{t(ct.assignedTo)}</b>
                            </h6>
                            <p className="card-text"><pre>{ct.comment.text}</pre></p>
                            <div className={"ms-4"}>
                                {ct.comments.map((c) => {
                                    return (
                                        <div>
                                            <i className="bi bi-arrow-return-right"></i> <small>{formatDate(c.createdAt)}</small> {c.author.firstName} {c.author.lastName}:
                                            <pre>{c.text}</pre>
                                        </div>
                                    );
                                })}
                            </div>


                            <ReplyToComment reportId={reportId} commentTopic={ct} updateComments={props.updateComments}/>
                            </div>

                        </div>
                    );
                })
            }
            {
                (!props.commentTopics || Object.values(props.commentTopics).length == 0) && <div className="alert alert-dark alert-sm" role="alert">
                Brak komentarzy
                </div>
            }

            <AddNewCommentsTopic reportId={reportId} updateComments={props.updateComments} project={project}/>


        </>
    );
}