import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {GetYearOptions, MonthOptions} from "../reports/StatusUtils";
import {mapUserToSelectOption} from "../users/userUtils";
import {
    RemoteWork,
    SummaryTypeAssignments,
    SummaryTypeDelegationsAndAbsences,
    SummaryTypeH2020,
    SummaryTypeHE,
    SummaryTypePM
} from "./SummaryType";
import AuthService, {HR, PROJECT_CURATOR} from "../../AuthService";


export default function Summaries() {

    const {t} = useTranslation()

    let isCurator = AuthService.hasAnyRole([PROJECT_CURATOR])
    let isHR = AuthService.hasAnyRole([HR])

    const [startMonth, setStartMonth] = React.useState(null);
    const [startYear, setStartYear] = React.useState(null);

    const [endMonth, setEndMonth] = React.useState(null);
    const [endYear, setEndYear] = React.useState(null);
    const [project, setProject] = React.useState(null);
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const [projects, setProjects] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [selectedReport, setSelectedReport] = React.useState(null);

    let isValid = false;
    let showStartDate = false;
    let showEndDate = false;
    let showProject = false;
    let showUser = false;

    const setSelectedReportHandler = (v) => {
        setSelectedReport(v)
        if (!showEndDate) {
            setEndMonth(null)
            setEndYear(null)
        }
        if (!showUser) {
            setUser(null)
        }
        if (!showProject) {
            setProject(null)
        }
    }

    const setStartMonthHandler = (v) => {
        let val = v ? v.value : null
        setStartMonth(val);
    }
    const setStartYearHandler = (v) => {
        let val = v ? v.value : null
        setStartYear(val);
    }
    const setEndMonthHandler = (v) => {
        let val = v ? v.value : null
        setEndMonth(val);
    }
    const setEndYearHandler = (v) => {
        let val = v ? v.value : null
        setEndYear(val);
    }

    const setProjectHandler = (v) => {
        let val = v ? v.value : null
        setProject(val);
    }


    let startDate = null
    if (startMonth && startYear) {
        startDate = new Date(startYear, startMonth, 1)
    }

    let endDate = null
    if (endMonth && endYear) {
        endDate = new Date(endMonth, endYear, 1)
    }



    switch (selectedReport) {
        case SummaryTypeH2020:
        case SummaryTypeHE:
        case SummaryTypePM:
            showStartDate = true;
            showEndDate = true;
            showProject = true;
            showUser = false;
            isValid = !(!startDate || !endDate || !project)
            break
        case SummaryTypeAssignments:
            showStartDate = true;
            showEndDate = false;
            showProject = false;
            showUser = false;
            isValid = !!startDate;
            break
        case SummaryTypeDelegationsAndAbsences:
            showStartDate = true;
            showEndDate = true;
            showProject = false;
            showUser = true;
            isValid = !(!startDate || !endDate || !user)
            break
        case RemoteWork:
            showStartDate = true;
            showEndDate = true;
            isValid = !(!startDate || !endDate)
            break;
        default:
            isValid = false;
    }



    const fetchProjects = () => {
        let searchRequest = {
            pagination: {
                page: 0,
                pageSize: 1000
            }
        }

        let url = BACKEND_URL + 'projects/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setProjects(response.content.map((p) => {return {value: p.id, label: p.acronym}}))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fetchUsers = React.useCallback(() => {
        let url = BACKEND_URL + 'users/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setUsers(response.map(mapUserToSelectOption))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        fetchProjects()
        fetchUsers()
    }, [])


    const namePrefixByType = (type) => {
        switch(type) {
            case RemoteWork:
                return "Praca zdalna"
            case SummaryTypeAssignments:
                return "Zaangażowania";
            case SummaryTypeDelegationsAndAbsences:
                return "Delegacje_i_nieobecności";
            default:
                return type;
        }
    }

    const downloadReport = (type) => {
        setLoading(true)
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let url = BACKEND_URL + 'summary/'+type+'/'+startMonth+"/"+startYear+"/"+endMonth+"/"+endYear+"/"+project
        if (type == SummaryTypeAssignments)  {
            url = BACKEND_URL + 'summary/'+type+'/'+startMonth+"/"+startYear
        }
        if (type == SummaryTypeDelegationsAndAbsences)  {
            url = BACKEND_URL + 'summary/'+type+'/'+startMonth+"/"+startYear+"/"+endMonth+"/"+endYear+"/"+user.value
        }
        if (type == RemoteWork)  {
            url = BACKEND_URL + 'summary/'+type+'/'+startMonth+"/"+startYear+"/"+endMonth+"/"+endYear
        }


        let headers = new Headers();
        if (window.sessionStorage.accessToken !== undefined
            && window.sessionStorage.accessToken !== null
            && window.sessionStorage.accessToken !== "null") {
            headers.append('Authorization', window.sessionStorage.accessToken);
        }

        let selectedProject = projects.filter(obj => obj.value.toString() === project)[0]

        fetch(url, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                let prefix = namePrefixByType(type)

                anchor.href = objectUrl;
                if (type == "assignments") {
                    anchor.download = prefix + "_" + startMonth + "." + startYear + ".xlsx";
                }
                else                 if (type == "delegations-and-absences") {
                    anchor.download = prefix+"_"+startMonth+"."+startYear+"-"+endMonth+"."+endYear+"_"+user.label+".xlsx";
                }
                else                 if (type == "remote-work") {
                    anchor.download = prefix+"_"+startMonth+"."+startYear+"-"+endMonth+"."+endYear+".xlsx";
                }
                else {
                    anchor.download = prefix+"_"+startMonth+"."+startYear+"-"+endMonth+"."+endYear+"_"+selectedProject.label+".xlsx";
                }

                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                setLoading(false)
            });

    }




    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3 className="h3">Raporty</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                    </div>
                </div>
            </div>

            <div className="form-row row">

            <ul className="nav nav-tabs">
                {isCurator && <li className="nav-item">
                    <a onClick={() => setSelectedReportHandler(SummaryTypeH2020)} className={"nav-link " + (selectedReport == SummaryTypeH2020 ? "active" : "")} >
                        H2020
                    </a>
                </li> }
                {isCurator && <li className="nav-item">
                    <a onClick={() => setSelectedReportHandler(SummaryTypeHE)} className={"nav-link " + (selectedReport == SummaryTypeHE ? "active" : "")} >
                        HE
                    </a>
                </li>}
                {isCurator && <li className="nav-item">
                    <a onClick={() => setSelectedReportHandler(SummaryTypePM)} className={"nav-link " + (selectedReport == SummaryTypePM ? "active" : "")} >
                        PM
                    </a>
                </li>}
                {isCurator && <li className="nav-item">
                    <a onClick={() => setSelectedReportHandler(SummaryTypeAssignments)} className={"nav-link " + (selectedReport == SummaryTypeAssignments ? "active" : "")} >
                        Zaangażowania
                    </a>
                </li>}
                {isCurator && <li className="nav-item">
                    <a onClick={() => setSelectedReportHandler(SummaryTypeDelegationsAndAbsences)} className={"nav-link " + (selectedReport == SummaryTypeDelegationsAndAbsences ? "active" : "")} >
                        Delegacje i nieobecności
                    </a>
                </li>}
                {isHR && <li className="nav-item">
                    <a onClick={() => setSelectedReportHandler(RemoteWork)} className={"nav-link " + (selectedReport == RemoteWork ? "active" : "")} >
                        Praca zdalna
                    </a>
                </li>}
            </ul>
            </div>

            <div className="form-row row summaries-filters-row">
                {showStartDate && <div className="col-1">
                    <Select
                        placeholder={t("month")}
                        value={MonthOptions.filter(obj => startMonth && obj.value.toString() === startMonth.toString())}
                        className="basic-single"
                        onChange = {setStartMonthHandler}
                        classNamePrefix="select"
                        isClearable={false}
                        name="color"
                        options={MonthOptions}
                    />
                </div>}
                {showStartDate && <div className="col-1">
                    <Select
                        placeholder={t("year")}
                        className="basic-single"
                        onChange = {setStartYearHandler}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={false}
                        name="color"
                        value={GetYearOptions().filter(obj => startYear && obj.value.toString() === startYear.toString())}
                        options={GetYearOptions()}
                    />
                </div>}
                {showEndDate && <div className="col-1">
                    <Select
                        placeholder={t("month")}
                        value={MonthOptions.filter(obj => endMonth && obj.value.toString() === endMonth.toString())}
                        className="basic-single"
                        onChange = {setEndMonthHandler}
                        classNamePrefix="select"
                        isClearable={false}
                        name="color"
                        options={MonthOptions}
                    />
                </div>}
                {showEndDate && <div className="col-1">
                    <Select
                        placeholder={t("year")}
                        className="basic-single"
                        onChange = {setEndYearHandler}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={false}
                        name="color"
                        value={GetYearOptions().filter(obj => endYear && obj.value.toString() === endYear.toString())}
                        options={GetYearOptions()}
                    />
                </div>}
                {showProject && <div className="col-2">
                    <Select
                        placeholder={t("project")}
                        className="basic-single"
                        onChange = {setProjectHandler}
                        value={projects.filter(obj => obj.value.toString() === project)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={projects}
                    />
                </div>}
                {showUser && <div className="col-2">
                        <Select
                            placeholder={t("employee")}
                            className="basic-single"
                            onChange = {setUser}
                            classNamePrefix="select"
                            defaultValue={null}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            options={users}
                        />
                </div>}
            </div>
            <div className="row">

            </div>



            <div className="form-row row">
                {selectedReport && <div className="col-2">
                    <button type="button" className="btn btn-sm btn-primary"
                            onClick={() => downloadReport(selectedReport)}
                            disabled={!isValid || loading}
                    >
                        Generuj raport

                        {loading && <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only"></span>
                        </div>}
                    </button>
                </div>}
                {!selectedReport &&
                    <div className="alert alert-secondary" role="alert">
                        Wybierz rodzaj raportu...
                    </div>                }
            </div>



            <div className="row">

            </div>
        </>
    );
}