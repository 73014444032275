import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {printDate} from "../stringUtils";
import {
    goToProjectDetails,
    projectAssignmentCreateUrl,
    projectAssignmentDetailsUrl,
    projectTasksDetailsUrl
} from "../projects/projectsRouting";
import {useTranslation} from "react-i18next";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {goToUserDetails} from "../users/usersRouting";
import {getSearchParams} from "../reports/reportUtils";
import AuthService from "../../AuthService";
import SortByIcon from "../reports/SortByIcon";


export default function AssignmentsGlobalList() {

    const navigate = useNavigate();
    const {t} = useTranslation()
    let [searchParams, setSearchParams] = useSearchParams();

    const [sortBy, setSortBy] = React.useState({by: "endDate", direction: "DESC"});
    const [user, setUser] = React.useState(getSearchParams(searchParams, "user",null));
    const [project, setProject] = React.useState({value:getSearchParams(searchParams, "project",null)});
    const [task, setTask] = React.useState(getSearchParams(searchParams, "task",null));
    const [date, setDate] = React.useState(getSearchParams(searchParams, "date",null));

    const [projects, setProjects] = React.useState([]);
    const [projectsData, setProjectsData] = React.useState([]);
    const [tasks, setTasks] = React.useState([]);
    const [assignments, setAssignments] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [itemsPerPage] = React.useState(20);
    const [totalPages, setTotalPages] = React.useState(1)
    const [users, setUsers] = React.useState([]);

    const selectedProject = projectsData && project ? projectsData.find(p => p.id == project.value) : null

    let canAddAssignment = project && selectedProject && AuthService.hasAnyRole([selectedProject.managedByRole])

    const persistSearchState = (k,v, cleanPageNumber) => {
        let params = {
            user: user, project: project.value, pageNumber, task: task
        }
        params[k] = v

        if (cleanPageNumber) {
            params.pageNumber = 0
        }

        setSearchParams(createSearchParams(params))
    }

    const persistSearchState2 = (k,v, k1,v1, cleanPageNumber) => {
        let params = {
            user: user, project: project.value, pageNumber, task: task
        }
        params[k] = v
        params[k1] = v1

        if (cleanPageNumber) {
            params.pageNumber = 0
        }

        setSearchParams(createSearchParams(params))
    }

      const setProjectHandler = (v) => {
        let val = v
        setProject(val);
        setPageNumber(0)
        setTask(null)
        persistSearchState2("project", val.value, "task", null, true);

        if (val) {
            fetchTasks(val)
        }
        else {
            setTasks([])
        }
    }
    const setProjectTaskHandler = (v) => {
        let val = v ? v.value : null
        setTask(val);
        setPageNumber(0)
        persistSearchState("task", val, true);
    }
    const setUserHandler = (v) => {
        let val = v ? v.value : null
        setUser(val);
        setPageNumber(0)
        persistSearchState("user", val, true);
    }

    const setPageNumberHandler = (v) => {
        setPageNumber(v);
        persistSearchState("pageNumber", v);
    }
    const handleSortBy = (by) => {
        let direction = "ASC"

        if (sortBy && sortBy.by == by && sortBy.direction == "ASC") {
            direction = "DESC"
        }

        let newSortBy = {
            by: by,
            direction: direction
        }

        setSortBy(newSortBy);
    }

    const handleSetDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value);
        setPageNumber(0);
        persistSearchState("date", event.target.value, true);
    };

    const mapUserToSelectOption = (u) => {return {value:u.id, label: u.firstName + " " + u.lastName + " [" + u.email+"]"}}

    const handlePageClick = (event) => {
        setPageNumberHandler(event.selected)
    };

    const fetchUsers = React.useCallback(() => {
        let url = BACKEND_URL + 'users/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setUsers(response.map(mapUserToSelectOption))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        fetchUsers()
        if (project) {
            fetchTasks(project.value)
        }
    }, [fetchUsers])


    const goToAssignmentDetails = (projectId, assignmentId) => navigate(projectAssignmentDetailsUrl(projectId, assignmentId))

    const gotoAddProject = async () => {
        navigate("/projects/add")
    }

    const goToTaskDetails = (projectId, taskId) => {
        navigate(projectTasksDetailsUrl(projectId, taskId))
    }



    const fetchProjects = () => {
        let searchRequest = {
            pagination: {
                page: 0,
                pageSize: 1000
            }
        }

        let url = BACKEND_URL + 'projects/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setProjectsData(response.content)
                setProjects(response.content.map((p) => {return {value: p.id, label: p.acronym, record: p}}))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fetchTasks = (projectId) => {
        let url = BACKEND_URL + 'projects/'+projectId+"/tasks/search"

        let searchRequest = {
            pagination: {
                page: 0,
                pageSize: 1000
            }
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}


        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setTasks(response.content.map((p) => {return {value: p.id, label: p.name}}))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fetchAssignments = () => {
        let searchRequest = {
            sortBy: sortBy,
            pagination: {
                page: pageNumber,
                pageSize: itemsPerPage
            }
        }

        if (user) {
            searchRequest.employeeId = user
        }

        if (project) {
            searchRequest.projectId = project.value
        }

        if (task) {
            searchRequest.taskId = task
        }

        if (date) {
            searchRequest.date = date
        }

        let url = BACKEND_URL + 'assignments/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setAssignments(response.content)
                setTotalPages(response.totalPages)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        fetchAssignments()
    }, [pageNumber, itemsPerPage, user, project, task, date, sortBy])


    useEffect(() => {
        fetchProjects()
    }, [])

    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3 className="h3">Zaangażowania</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                    </div>
                </div>
            </div>

            <div className="form-row row">
                <div className="col-2">
                    <Select
                        placeholder={t("employee")}
                        className="basic-single"
                        onChange = {setUserHandler}
                        value={users.filter(obj => obj.value === user)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={users}
                    />
                </div>
                <div className="col-2">
                    <input type="date"
                           className="form-control"
                           value={date}
                           onChange={handleSetDate}
                    ></input>
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("project")}
                        className="basic-single"
                        onChange = {setProjectHandler}
                        value={projects.filter(obj => project &&  obj.value.toString() === project.value)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={projects}
                    />
                </div>
                <div className="col-2">
                <Select
                        isDisabled={!project}
                        placeholder={t("task")}
                        className="basic-single"
                        onChange = {setProjectTaskHandler}
                        value={tasks.filter(obj => obj.value.toString() === task)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={tasks}
                    />
                </div>
                <div className="col-2">
                    {canAddAssignment && <button type="button" className="btn btn-sm btn-primary"
                            disabled={!(project) || (selectedProject && selectedProject.archived)}
                            onClick={() => navigate(projectAssignmentCreateUrl(selectedProject.id,user))}
                    >
                        Dodaj zaangażowanie
                    </button>}
                </div>
                <div className="col-1">
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-sm table-hover">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Pracownik</th>
                        <th scope="col" className="sortable"
                            onClick={() => handleSortBy("startDate")}>Rozpoczęcie
                            <SortByIcon sortBy={sortBy} by="startDate"/>
                        </th>
                        <th scope="col" className="sortable"
                            onClick={() => handleSortBy("endDate")}>Zakończenie
                            <SortByIcon sortBy={sortBy} by="endDate"/>
                        </th>
                        <th scope="col">Projekt</th>
                        <th scope="col">Zadanie</th>
                        <th scope="col">Wymiar</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {assignments.map((row) => (
                        <tr key={row.id} >
                            <td></td>
                            <td><a onClick={() => goToUserDetails(navigate, row.employee.id)}>{row.employee.firstName} {row.employee.lastName}</a></td>
                            <td>{printDate(row.startDate)}</td>
                            <td>{printDate(row.endDate)}</td>
                            <td><a onClick={() => goToProjectDetails(navigate, row.projectRef)}>{row.projectRef.acronym}</a></td>
                            <td><a onClick={() => goToTaskDetails(row.projectRef.id, row.projectTask.id)}>{row.projectTask.name}</a></td>
                            <td>{row.projectAssignmentDimension.hours ? row.projectAssignmentDimension.hours + "h" : row.projectAssignmentDimension.partOfFullTime.toString().replace(".",",")}</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => goToAssignmentDetails(row.projectRef.id, row.id)}>otwórz &raquo;</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="row">
                <ReactPaginate
                    nextLabel="&raquo;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={totalPages}
                    previousLabel="&laquo;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    );
}