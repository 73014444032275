import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import asyncFetch, {syncFetchAndJSON} from "../../../api/async-fetch";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {goToDelegations} from "../../delegation-list/delegationsRouting";
import {toast} from "react-toastify";

export default function UserDelegationForm(props) {
    const {t} = useTranslation()
    const navigate = useNavigate();

    let params = useParams();
    let {disabled} = props


    const [delegationId] = React.useState(props.delegation.id);
    const [projectId, setProjectId] = React.useState(props.delegation.projectId);
    const [startDateTime, setStartDateTime] = React.useState(props.delegation.startDateTime);
    const [endDateTime, setEndDateTime] = React.useState(props.delegation.endDateTime);
    const [projects, setProjects] = React.useState([]);
    const [city, setCity] = React.useState(props.delegation.city);

    const handleSetStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDateTime(event.target.value);
    };

    const handleSetEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDateTime(event.target.value);
    };

    const handleSetCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
    };

    const projectValid = projectId != '' && projectId != null

    let startDateInvalid = !startDateTime;
    let endDateInvalid = !endDateTime;
    let cityInvalid = city.trim().length < 2;

    if (startDateTime && endDateTime) {
        const d1 = new Date(startDateTime);
        const d2 = new Date(endDateTime);

        if (d1 >= d2) {
            startDateInvalid = endDateInvalid = true;
        }
    }

    const formInvalid = !projectValid || startDateInvalid || endDateInvalid

    const handleCreate = async () => {

        let objToSave = {projectId, startDateTime, endDateTime, city, userId: params.userId}

        let url = BACKEND_URL + 'delegations/create'

        if (delegationId) {
            url = BACKEND_URL + 'delegations/'+delegationId
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            toast.success(t("dataSaved"))
            goToDelegations(navigate, params.userId )
        }
    }
    const handleDelete = async () => {
        if (window.confirm("Czy na pewno chcesz usunać wybraną delegację?") != true) {
            return;
        }


        let url = BACKEND_URL + 'delegations/'+delegationId+'/delete'

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            toast.success(t("dataDeleted"))
            goToDelegations(navigate, params.userId )
        }
    }
    const setProjectHandler = (v) => {
        let val = v ? v.value : null
        setProjectId(val);
    }

    const fetchProjects = () => {
        let searchRequest = {
            pagination: {
                page: 0,
                pageSize: 1000
            }
        }

        if (startDateTime && endDateTime) {
            searchRequest.assignmentStartDate = new Date(startDateTime).toISOString().slice(0, 10);
            searchRequest.assignmentEndDate = new Date(endDateTime).toISOString().slice(0, 10);
            searchRequest.assignmentEmployeeId = params.userId
        }

        let url = BACKEND_URL + 'projects/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                let tempProjects = response.content

                if (!delegationId) {
                    //for creating allow only non archived
                    tempProjects = tempProjects.filter(p => !p.archived)
                }

                setProjects(tempProjects.map((p) => {return {value: p.id, label: p.acronym}}))
            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        fetchProjects()
    }, [startDateTime,endDateTime])

    return (
        <>
            <form>
                <div className="mb-3 col-2">
                    <label htmlFor="startDate" className="form-label">Wyjazd</label>
                    <input type="datetime-local" required={true} className={"form-control" + (startDateInvalid ? ' is-invalid' : '')} id="startDate"
                           aria-describedby="Data rozpoczęcia"
                           value={startDateTime}
                           onChange={handleSetStartDate}
                           disabled={disabled}
                    />
                </div>
                <div className="mb-3 col-2">
                    <label htmlFor="endDate" className="form-label">Powrót</label>
                    <input type="datetime-local" required={true} className={"form-control" + (endDateInvalid ? ' is-invalid' : '')} id="endDate"
                           aria-describedby="Data zakończenia"
                           value={endDateTime}
                           onChange={handleSetEndDate}
                           disabled={disabled}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="endDate" className="form-label">Miasto</label>
                    <input type="text" required={true} className={"form-control" + (cityInvalid ? ' is-invalid' : '')} id="city"
                           aria-describedby="Miasto"
                           value={city}
                           onChange={handleSetCity}
                           disabled={disabled}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="projectSelect" className="form-label">Projekt</label>
                    <Select
                        isDisabled={disabled || delegationId}
                        placeholder={t("project")}
                        className="basic-single"
                        onChange = {setProjectHandler}
                        value={projects.filter(obj => obj.value === projectId)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={projects}
                    />
                </div>



                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-primary"
                                disabled={formInvalid || disabled}
                                onClick={handleCreate}
                        >Zapisz
                        </button>
                    </div>


                    {delegationId && <div className="btn-group me-2">
                    <button type="button" className="btn btn-sm btn-danger"
                            disabled={disabled}
                            onClick={handleDelete}
                    >
                        <span className="bi bi-trash"></span> Usuń</button>
                    </div>}
                </div>
            </form>
        </>
    );
}
