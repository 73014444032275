import {useNavigate} from "react-router-dom";


import * as React from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch from "../../api/async-fetch";
import Select from "react-select";
import {GetRolesOptions} from "../reports/StatusUtils";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

export default function UserAdd() {
    const {t} = useTranslation()

    const navigate = useNavigate();

    const [lastName, setLastName] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [alias, setAlias] = React.useState('');
    const [registrationNumber, setRegistrationNumber] = React.useState('');

    const handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleSetAlias = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlias(event.target.value);
    };

    const handleSetRegistrationNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegistrationNumber(event.target.value);
    };

    const handleSetFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const handleSetLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const goBack = () => {
        navigate("/users")
    };

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const registrationNUmberRegex = /^[0-9]{6}/;

    const isLastNameValid = lastName !== ""
    const isFirstNameValid = firstName != ""
    const isAliasValid = alias != ""
    const isRegistrationNumberValid = registrationNumber.match(registrationNUmberRegex) && String(registrationNumber).length == 6

    const isEmailValid = email.match(emailRegex)
    const [roles, setRoles] = React.useState([]);

    const isValid = () => {
        return isLastNameValid && isFirstNameValid && isEmailValid && isAliasValid && isRegistrationNumberValid
    }

    const handleCreate = async () => {

        let objToSave = {email: email, lastName, firstName, registrationNumber,
            roles: roles.map(r => r.value),
            kingaAlias: alias
        }

        let url = BACKEND_URL + 'users/'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        }
        else {
            toast.success(t("dataSaved"))
            navigate("/users")
        }
    }


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Utwórz konto pracownika</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button onClick={() => goBack()} type="button"
                                className="btn btn-sm btn-outline-secondary">&laquo; Powrót do listy
                        </button>
                    </div>
                </div>
            </div>

            <form className={"userDataForm"}>

                <div className="mb-3 col-3">
                    <label htmlFor="lastNameInput" className="form-label">Nazwisko</label>
                    <input type="text" required={true} className={"form-control" + (!isLastNameValid ? ' is-invalid' : '')} id="lastNameInput" aria-describedby="Nazwisko"
                           value={lastName}
                           onChange={handleSetLastName}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="firstNameInput" className="form-label">Imiona</label>
                    <input type="text" required={true} className={"form-control" + (!isFirstNameValid ? ' is-invalid' : '')} id="firstNameInput" aria-describedby="Imiona"
                           value={firstName}
                           onChange={handleSetFirstName}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="aliasInput" className="form-label">Alias [KINGA]</label>
                    <input type="text" required={true} className={"form-control" + (!isAliasValid ? ' is-invalid' : '')} id="aliasInput" aria-describedby="aLIAS"
                           value={alias}
                           onChange={handleSetAlias}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="registrationNumber" className="form-label">Numer ewidencyjny</label>
                    <input type="number"
                        required={true} className={"form-control" + (!isRegistrationNumberValid ? ' is-invalid' : '')} id="aliasInput" aria-describedby="Numer identyfikacyjny"
                           value={registrationNumber}
                           onChange={handleSetRegistrationNumber}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="emailInput" className="form-label">E-mail</label>
                    <input type="email" required={true} className={"form-control" + (!isEmailValid ? ' is-invalid' : '')} id="emailInput" aria-describedby="E-mail"
                           value={email}
                           onChange={handleSetEmail}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="emailInput" className="form-label">Role</label>
                    <Select
                        className="basic-single"
                        placeholder={t("role")}
                        onChange = {setRoles}
                        classNamePrefix="select"
                        value={roles}
                        isClearable={true}
                        isMulti={true}
                        name="color"
                        options={GetRolesOptions()}
                    />
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-primary"
                                disabled={!isValid()}
                                onClick={handleCreate}
                        >Zapisz
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}