import {useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../../api/async-fetch";
import ProjectTaskForm from "./ProjectTaskForm";
import BlockFormAlert from "../../general/BlockFormAlert";
import AuthService from "../../../AuthService";
import {fetchProject} from "../projectUtil";

export default function ProjectTaskEdit() {

    const projectId = useParams().projectId
    const taskId = useParams().taskId

    const [project, setProject] = React.useState(null);
    let allowEditing = (project && !project.archived)
    let ownProject = project && !project.archived && AuthService.hasAnyRole([project.managedByRole])

    useEffect(() => {
        fetchProject(projectId, setProject)
    }, [])

    const [task, setTask] = React.useState(null);
    const [disabled, setDisabled] = React.useState(true);

    const t = task ? {
        name: task.name,
        startDate: task.startDate,
        endDate: task.endDate,
    } : {}

    const fetchTask = () => {
        let url = BACKEND_URL + `projects/${projectId}/task/get/${taskId}`

        const baseRequestConfig = getBaseRequestConfig("GET")

        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setTask(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchTask()
    }, [])

    return (
        <>
            <h4>Zadanie {t.name}</h4>
            {ownProject && allowEditing && <BlockFormAlert disableCallback={setDisabled}/>}

            {task && <ProjectTaskForm taskData={t} key={"editTaskData"} disabled={disabled} showActions={allowEditing && ownProject}/>}
        </>
    );
}