import {useNavigate} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch, {syncFetchAndJSON} from "../../api/async-fetch";
import {useTranslation} from "react-i18next";
import {filterAndMapUserToSelectOption, mapUserToSelectOption} from "../users/userUtils";
import Select from "react-select";
import AuthService, {SUPER_HR} from "../../AuthService";
import {toast} from "react-toastify";


export default function ProjectDataForm(props) {
    const {t} = useTranslation()
    const navigate = useNavigate();
    let isSuperHR = AuthService.hasAnyRole([SUPER_HR])

    let {disabled} = props
    const [projectId] = React.useState(props.project.id)
    const [name, setName] = React.useState(props.project.name);
    const [acronym, setAcronym] = React.useState(props.project.acronym);
    const [signature, setSignature] = React.useState(props.project.signature);
    const [language, setLanguage] = React.useState(props.project.language);
    const [startDate, setStartDate] = React.useState(props.project.startDate);
    const [endDate, setEndDate] = React.useState(props.project.endDate);
    const [archived, setArchived] = React.useState(props.project.archived === true);
    const [showWorkingHours, setShowWorkingHours] = React.useState(props.project.showWorkingHours === true);
    const [footerText, setFooterText] = React.useState(props.project.footerText);


    const [leader, setLeader] = React.useState(mapUserToSelectOption(props.project.leader));
    const [leader2, setLeader2] = React.useState(mapUserToSelectOption(props.project.leader2));
    const [curator, setCurator] = React.useState(mapUserToSelectOption(props.project.curator));

    const [curators, setCurators] = React.useState([]);
    const [projectLeaders, setProjectLeaders] = React.useState([]);

    const nameValid = name && name.trim() != ""
    const acronymValid = acronym && acronym.trim() != ""
    const signatureValid = signature && signature.trim() != ""
    const languageValid = language != ""
    let startDateValid = startDate != ""
    let endDateValid = endDate != ""
    const leaderUserIdValid = !!leader
    const curatorUserIdValid = !!curator


    if (startDate && endDate) {
        const d1 = new Date(startDate);
        const d2 = new Date(endDate);

        if (d1 >= d2) {
            startDateValid = endDateValid = false;
        }
    }

    const handleSetName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleSetAcronym = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAcronym(event.target.value);
    };

    const handleSetSignature = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSignature(event.target.value);
    };

    const handleSetLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLanguage(event.target.value);
    };

    const handleSetStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleSetEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    const handleSetFooterText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFooterText(event.target.value);
    };

    const handleBack = () => {
        navigate("/projects")
    };

    const isValid = () => {
        return nameValid && acronymValid && signatureValid && startDateValid && endDateValid && languageValid
            && curatorUserIdValid && leaderUserIdValid
    }

    const fetchUsers = React.useCallback(() => {
        let url = BACKEND_URL + 'users/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setCurators(filterAndMapUserToSelectOption(response,props.project.managedByRole))
                setProjectLeaders(filterAndMapUserToSelectOption(response,"PROJECT_LEADER"))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    useEffect(() => {
        fetchUsers()
    }, [fetchUsers])

    const handleSubmit = async (event) => {
        event.preventDefault()
        let objToSave = {name, acronym, signature, language, startDate, endDate, footerText,
            leaderUserId: leader.value,
            deputyLeaderUserId: leader2 ? leader2.value : null,
            curatorUserId: curator.value,
            archived, showWorkingHours}

        let url = BACKEND_URL + 'projects/'

        if (projectId) {
            url += projectId
        } else {
            objToSave.managedByRole = props.project.managedByRole
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            toast.success(t("dataSaved"))
            navigate("/projects")
        }
    }

    return (<>
        <form onSubmit={handleSubmit}>
            <div className="mb-3 col-4">
                <label htmlFor="acronymInput" className="form-label">Akronim</label>
                <input type="text" required={true} className={"form-control" + (!acronymValid ? ' is-invalid' : '')}  id="acronymInput" aria-describedby="Akronim"
                       value={acronym}
                       disabled={disabled}
                       onChange={handleSetAcronym}
                />
            </div>
            <div className="mb-3 col-12">
                <label htmlFor="acronymInput" className="form-label">Nazwa</label>
                <input type="text" required={true} className={"form-control" + (!nameValid ? ' is-invalid' : '')}  id="acronymInput" aria-describedby="Nazwa"
                       value={name}
                       disabled={disabled}
                       onChange={handleSetName}
                />
            </div>
            <div className="mb-3 col-4">
                <label htmlFor="acronymInput" className="form-label">Numer projektu</label>
                <input type="text" required={true} className={"form-control" + (!signatureValid ? ' is-invalid' : '')}  id="acronymInput" aria-describedby="Sygnature"
                       value={signature}
                       disabled={disabled}
                       onChange={handleSetSignature}
                />
            </div>
            <div className="mb-3 col-4">
                <label htmlFor="languageInput" className="form-label">Język</label>
                <select  required={true} className={"form-control" + (!languageValid ? ' is-invalid' : '')}  id="languageInput" aria-describedby="Język"
                       value={language}
                         disabled={disabled}
                       onChange={handleSetLanguage}>
                    <option value=''>{t("selectOption")}</option>
                    <option value={"pl"}>pl</option>
                    <option value={"en"}>en</option>
                </select>
            </div>
            <div className="mb-3 col-2">
                <label htmlFor="acronymInput" className="form-label">Data rozpoczęcia</label>
                <input type="date" required={true} required={true} className={"form-control" + (!startDateValid ? ' is-invalid' : '')}  id="acronymInput"
                       aria-describedby="Data rozpoczęcia"
                       value={startDate}
                       disabled={disabled}
                       onChange={handleSetStartDate}
                />
            </div>
            <div className="mb-3 col-2">
                <label htmlFor="acronymInput" className="form-label">Data zakończenia</label>
                <input type="date" required={true} required={true} className={"form-control" + (!endDateValid ? ' is-invalid' : '')}  id="acronymInput"
                       aria-describedby="Data zakończenia"
                       value={endDate}
                       disabled={disabled}
                       onChange={handleSetEndDate}
                />
            </div>
            <div className={"mb-3 col-4" + (!leaderUserIdValid ? ' is-invalid' : '')}>
                <label htmlFor="leaderSelect" className="form-label">Kierownik</label>
                <Select
                    className={"form-control" + (!leaderUserIdValid ? ' is-invalid' : '')}
                    isDisabled={disabled}
                    required={true}
                    placeholder={t("curator")}
                    className="basic-single"
                    onChange = {setLeader}
                    classNamePrefix="select"
                    defaultValue={null}
                    isClearable={true}
                    isSearchable={true}
                    value={leader}
                    name="color"
                    options={projectLeaders}
                />
            </div>
            <div className="mb-3 col-4">
                <label htmlFor="deputyLeaderSelect" className="form-label">Kierownik 2</label>
                <Select
                    isDisabled={disabled}
                    placeholder={t("leader2")}
                    className="basic-single"
                    onChange = {setLeader2}
                    classNamePrefix="select"
                    defaultValue={null}
                    isClearable={true}
                    isSearchable={true}
                    value={leader2}
                    name="color"
                    options={projectLeaders}
                />
            </div>
            <div
                 className={"mb-3 col-4" + (!curatorUserIdValid ? ' is-invalid' : '')}
            >
                <label htmlFor="curatorSelect" className="form-label">Opiekun</label>
                <Select
                    isDisabled={disabled}
                    required={true}
                    placeholder={t("curator")}
                    className="basic-single"
                    onChange = {setCurator}
                    classNamePrefix="select"
                    defaultValue={null}
                    isClearable={true}
                    isSearchable={true}
                    value={curator}
                    name="color"
                    options={curators}
                />
            </div>
            <div className="mb-3 col-4 form-check">
                <input type="checkbox" className="form-check-input" id="archivedInput" checked={archived}
                       disabled={disabled}
                       onClick={(e) => setArchived(!archived)}/>
                <label className="form-check-label" htmlFor="archivedInput">Archiwalny</label>
            </div>
            <div className="mb-3 col-12">
                <label className="form-label">Tekst stopki (np. oświadczenie)</label>
                <textarea  disabled={disabled} className={"form-control"}  onChange={handleSetFooterText}>{footerText}</textarea>
            </div>
            <div className="mb-3 col-4 form-check">
                <input type="checkbox" className="form-check-input" id="showWorkingHoursInput" checked={showWorkingHours}
                       disabled={disabled}
                       onClick={(e) => setShowWorkingHours(!showWorkingHours)}/>
                <label className="form-check-label" htmlFor="showWorkingHoursInput">Pokazuj godz. rozp. i zak. w PDF</label>
            </div>
            {props.showActions && <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                    <button type="submit" className="btn btn-primary"
                            disabled={!isValid() || disabled}
                    >Zapisz
                    </button>
                </div>
            </div>}
        </form>
    </>);
}