export const trimToLength = (text, l) => {
    if (text == undefined || text == null) {
        return "";
    }

    let len =  text.length;

    if (len > l) {
        return text.substring(0, l) + "..."
    }

    return text
}

export     const printDate = (dstr) => {

    return dstr.replace("T", " ")
}

export const printAbsenceStart = (a) => {
    if (a.absenceType == "CHILD_CARE_HEALTHY") {
        return a.startDate + " " + a.startTime;
    } else {
        return a.startDate;
    }
}

export const printAbsenceEnd = (a) => {
    if (a.absenceType == "CHILD_CARE_HEALTHY") {
        return a.endDate + " " + a.endTime;
    } else {
        return a.endDate;
    }
}

export const printAbsenceDates = (a) => {
    if (a.absenceType == "CHILD_CARE_HEALTHY") {
        return a.startDate;
    }
    else if (a.absenceType == "EQUIVALENT") {
        return a.startDate + " za dzień " + a.workedOnDate;
    } else {
        if (a.startDate ==  a.endDate) {
            return a.startDate;
        }
        return a.startDate + " - " +a.endDate;
    }
}