import {Outlet, useNavigate} from "react-router-dom";
import AuthService, {EMPLOYEE, HR, PROJECT_CURATOR, PROJECT_LEADER, SUPER_HR, SUPERVISOR} from "./AuthService";
import * as React from "react";
import {useEffect} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {ToastContainer} from "react-toastify";

export default function App() {

    const navigate = useNavigate();
    const { initialized, keycloak } = useKeycloak();

    useEffect(() => {
        if (keycloak && initialized) {
            keycloak.onTokenExpired = () => {keycloak.updateToken(600)};
        }
        return () => {
            if (keycloak) keycloak.onTokenExpired = () => {};
        };
    }, [initialized, keycloak]);


    const handleLogout = () => {
        navigate("/")
        AuthService.logout();
        keycloak.logout()
    }

    const handleLogin = () => {
        keycloak.login()
    }


    const urls = [
        {
            url: "projects",
            slug: "Projekty",
            roles: [PROJECT_CURATOR, HR]
        },
        {
            url: "assignments?pageNumber=0",
            slug: "Zaangażowania",
            roles: [HR, PROJECT_CURATOR]
        },
        {
            url: "absences?pageNumber=0",
            slug: "Nieobecności",
            roles: [HR, PROJECT_CURATOR]
        },
        {
            url: "delegations?pageNumber=0",
            slug: "Delegacje",
            roles: [HR, PROJECT_CURATOR]
        },
        {
            url: "days-off",
            slug: "Dni wolne",
            roles: [PROJECT_CURATOR, HR]
        },
        {
            url: "users",
            slug: "Pracownicy",
            roles: [PROJECT_CURATOR, HR]
        },
        {
            url: "reports?pageNumber=0&curator=" + (keycloak.userEntity ? keycloak.userEntity.id : ""),
            slug: "Karty [DOBP]",
            roles: [PROJECT_CURATOR]
        },

        {
            url: "my-reports?pageNumber=0",
            slug: "Moje karty",
            roles: [EMPLOYEE]
        },
        {
            url: "report-for-leader?pageNumber=0",
            slug: "Karty [KIEROWNIK]",
            roles: [PROJECT_LEADER]
        },
        {
            url: "report-for-supervisor?pageNumber=0",
            slug: "Karty [PRZEŁOŻONY]",
            roles: [SUPERVISOR]
        },
        {
            url: "reports-hr?pageNumber=0",
            slug: "Karty [KADRY]",
            roles: [HR]
        },
        {
            url: "month-hr-readiness",
            slug: "Potwierdzenie miesięcy",
            roles: [PROJECT_CURATOR, HR]
        },

        {
            url: "create-reports",
            slug: "Generuj karty",
            roles: [PROJECT_CURATOR]
        },
        {
            url: "summaries",
            slug: "Raporty",
            roles: [PROJECT_CURATOR, HR]
        },
        {
            url: "emails",
            slug: "Emails",
            roles: [SUPER_HR]
        },

        {
            url: "remote-work-rates",
            slug: "PZ Stawki",
            roles: ["HR"]
        },
        {
            url: "remote-work-permit?page=0",
            slug: "Praca zdalna",
            roles: ["HR"]
        },
    ]

    return (
        <div className="container-extra-width">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <a className="navbar-brand" href="/"><img src="/logo.svg" /> </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
                        aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav mr-auto">
                        {urls.map((item) => AuthService.hasAnyRole(item.roles, keycloak.userEntity) && <li className="nav-item" key={item.url}>
                            <a className="nav-link active" aria-current="page" href="#"
                               onClick={() => navigate("/"+item.url)}
                            >
                                <span data-feather="home" className="align-text-bottom"></span>
                                {item.slug}
                            </a>
                        </li>)}
                    </ul>
                </div>
                <ToastContainer
                position="top-center"
                />

                { keycloak.authenticated && <div className="navbar-nav">
                    <div className="nav-item text-nowrap">
                      {<a onClick={handleLogout} className="nav-link px-3" href="#">{keycloak.tokenParsed.preferred_username}, wyloguj</a>}
                    </div>
                </div>
                }
                { !keycloak.authenticated && <div className="navbar-nav">
                    <div className="nav-item text-nowrap">
                        {<a onClick={handleLogin} className="nav-link px-3" href="#">Zaloguj</a>}
                    </div>
                </div>
                }
            </nav>


            <div className="container-fluid">
                <div className="row">
                    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4">
                        <Outlet />
                    </main>
                </div>
            </div>

            <div className="container">
                <footer className="py-3 my-4">
                    <ul className="nav justify-content-center border-bottom pb-3 mb-3">
                    </ul>
                    <p className="text-center text-body-secondary">© 2023 ACK Cyfronet AGH</p>
                </footer>
            </div>
        </div>
    );
}