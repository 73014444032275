import HttpApiCallError from "./HttpApiCallError";

export default async function asyncFetch(url, requestConfig = {}) {

    const response = await fetch(url, requestConfig);

    const isSuccess = response.status >= 200 && response.status < 300;

    // let authHeader = response.headers.get('Authorization');
    //
    // if (authHeader) {
    //     let decoded = jwtDecode(authHeader);
    //     window.sessionStorage.accessToken = authHeader;
    // }

    if (isSuccess) {
        return response;
    }

    const error = new HttpApiCallError(
        response.statusText,
        response.status
    );

    try {
        // error.response = await response.json();
    }
    catch (e){}

    throw error;
}


export async function syncFetchAndJSON(url, requestConfig) {
    const response = await asyncFetch(url, requestConfig);
    return await response.json();
}

export async function syncFetch(url, requestConfig) {
    return await asyncFetch(url, requestConfig);
}

