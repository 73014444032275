import * as React from 'react';
import {BACKEND_URL} from "../../config";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


export default function DownloadPDF(props) {

    const {t} = useTranslation()
    const [isLoading, setIsLoading] = React.useState(false)


    const downloadReport = (report, extension) => {
        if (!report.accepted && !props.allowForNonAccepted) {
            toast.warning(t("pdfDownloadIsAllowedOnlyForAcceptedReports"))
            return
        }

        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let url = BACKEND_URL + 'reports/print/'+report.id

        let headers = new Headers();
        if (window.sessionStorage.accessToken !== undefined
            && window.sessionStorage.accessToken !== null
            && window.sessionStorage.accessToken !== "null") {
            headers.append('Authorization', window.sessionStorage.accessToken);
        }

        setIsLoading(true);

        fetch(url, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = report.year+"-"+String(report.month).padStart(2, '0')+"_"+report.userSnapshot.lastName+"_"+report.userSnapshot.firstName+"_"+ (report.project ? report.project.acronym : "PRACA_ZDALNA-"+report.supervisors[0].kingaAlias)+".pdf";
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                setIsLoading(false);
            });

    }


    return (

        <button disabled={isLoading} type="button" className="btn btn-sm btn-outline-secondary"
                onClick={() => downloadReport(props.report, "pdf")}>
            {!isLoading && <>PDF</>}
            {isLoading && <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
            </div>}
        </button>
    );
}