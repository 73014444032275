import * as React from 'react';
import {useRef} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {useTranslation} from "react-i18next";


export default function AddNewCommentsTopic(props) {

    const {t} = useTranslation()
    const {reportId, project} = props

    const myRef = useRef(null)

    const [text, setText] = React.useState("");
    const [assignee, setAssignee] = React.useState(null);

    const [isOpen, setIsOpen] = React.useState(false);

    const toggle = async (event) => {
        let newVal = !isOpen
        setIsOpen(newVal)
        if (newVal) {
            setTimeout(() => {
                myRef.current.scrollIntoView()
            }, 100);
        }
    }

    const handleSetAssignee = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAssignee(event.target.value);
    };


    const handleSubmit = async (event) => {
        event.preventDefault()
        let objToSave = {
            text: text,
            assignedTo: assignee
        }

        let url = BACKEND_URL + `reports/report/${reportId}/comments/topic/create`

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await syncFetchAndJSON(url, requestConfig);
        props.updateComments(response, reportId)

        setText("")
        setIsOpen(false)

    }

    const handleSetText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };



    return (
        <>
            {!isOpen && <button type="button" className="btn btn-sm btn-primary"
                                onClick={toggle}
            ><i className="bi bi-plus-circle"></i> Dodaj komentarz dla {project.acronym}
            </button>}

            {isOpen && <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="languageInput" className="form-label"></label>
                    <label htmlFor="languageInput" className="form-label"></label>
                    <select  required={true} className={"form-control"}
                             value={assignee}
                             onChange={handleSetAssignee}>
                        <option value=''>{t("wybierz adresata")}</option>
                        <option value={"EMPLOYEE"}>Pracownik</option>
                        <option value={"HR"}>Kadry</option>
                        <option value={"PROJECT_LEADER"}>Kierownik</option>
                        <option value={"SUPERVISOR"}>Przełożony</option>
                        <option value={"CURATORS"}>DOBP</option>
                    </select>
                </div>
                <div className="mb-3">
                    <textarea className="fullWith" onChange={handleSetText}>{text}</textarea>
                </div>

                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-secondary"
                                onClick={toggle}
                        >Cofnij
                        </button>
                        <button type="submit" className="btn btn-primary"
                                disabled={text == "" || assignee==""}
                        >Zapisz
                        </button>
                    </div>
                </div>
                <span ref={myRef} />

            </form>
            }


        </>

    );
}