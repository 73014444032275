import {useNavigate} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {mapUserToSelectOption} from "./userUtils";
import AuthService, {HR} from "../../AuthService";


export default function UsersList() {
    const {t} = useTranslation()

    const navigate = useNavigate();
    let isHR = AuthService.hasAnyRole([HR])

    const [users, setUsers] = React.useState([]);
    const [allUsers, setAllUsers] = React.useState([]);
    const [usersFiltered, setUsersFiltered] = React.useState([]);

    const goToAdd = async () => {
        navigate("/users/add")
    }

    const handleSetUser = (u) => {
        if (u) {
            setUsersFiltered(allUsers.filter(c => c.id == u.value))

        }
        else {
            setUsersFiltered(allUsers)
        }
    }

    const goToEdit = (id) => {
        navigate("/users/"+id+"/data")
    }

    const fetchUsers = React.useCallback(() => {

        let searchRequest = {

        }

        let url = BACKEND_URL + 'users/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setUsers(response.map(mapUserToSelectOption))
                setAllUsers(response)
                setUsersFiltered(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    useEffect(() => {
        fetchUsers()
    }, [fetchUsers])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Pracownicy</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {isHR && <button onClick={() => goToAdd()} type="button"
                                className="btn btn-sm btn-primary">Utwórz konto pracownika +
                        </button>}
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <div className="col-4">
                    <Select
                        order={1}
                        placeholder={t("employee")}
                        className="basic-single"
                        onChange = {handleSetUser}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={users}
                    />
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Nazwisko</th>
                        <th scope="col">Imiona</th>
                        <th scope="col">Email</th>
                        <th scope="col">Alias</th>
                        <th scope="col">Role</th>
                        <th scope="col">Numer ewidencyjny</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {usersFiltered.map((row) => (
                        <tr key={row.id} onClick={() => goToEdit(row.id)}>
                            <td></td>
                            <td>{row.lastName}</td>
                            <td>{row.firstName}</td>
                            <td>{row.email}</td>
                            <td>{row.kingaAlias}</td>
                            <td>{row.roles.map(r => <span className="badge rounded-pill bg-info">{t(r)}</span>)}</td>
                            <td>{row.registrationNumber}</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => goToEdit(row.id)}>edytuj &raquo;</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}