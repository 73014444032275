import {Link, useNavigate} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import AuthService, {PROJECT_CURATOR} from "../../AuthService";


export default function DaysOffList() {

    const navigate = useNavigate();

    const [projects, setProjects] = React.useState([]);
    let isCurator = AuthService.hasAnyRole([PROJECT_CURATOR])

    const goToAdd = async () => {
        navigate("/days-off/add")
    }


    const toDayOffDetails = (id) => {
        navigate("/days-off/edit/"+id)
    }

    const fetchDaysOff = React.useCallback(() => {

        let searchRequest = {

        }

        let url = BACKEND_URL + 'days-off/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setProjects(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    useEffect(() => {
        fetchDaysOff()
    }, [fetchDaysOff])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Zarzadzanie dniami wolnymi</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {isCurator && <button onClick={() => goToAdd()} type="button"
                                className="btn btn-sm btn-primary">Dodaj dzień wolny +
                        </button>}
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Data</th>
                        <th scope="col">Opis</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {projects.map((row) => (
                        <tr key={row.id}>
                            <td></td>
                            <td>{row.date}</td>
                            <td>{row.name}</td>
                            <td>
                                {isCurator && <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => toDayOffDetails(row.id)}>edytuj &raquo;</button> }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}