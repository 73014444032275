import {useNavigate} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";


export default function RemoteWorkRatesList() {

    const navigate = useNavigate();

    const [projects, setProjects] = React.useState([]);

    const goToAdd = async () => {
        navigate("/remote-work-rates/add")
    }


    const toDayOffDetails = (id) => {
        navigate("/remote-work-rates/edit/"+id)
    }

    const fetchRecords = React.useCallback(() => {
        let url = BACKEND_URL + 'remote-work-rates/list'
        const baseRequestConfig = getBaseRequestConfig("GET")

        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setProjects(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    useEffect(() => {
        fetchRecords()
    }, [fetchRecords])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Stawki pracy zdalnej</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {<button onClick={() => goToAdd()} type="button"
                                className="btn btn-sm btn-primary">Dodaj stawkę +
                        </button>}
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Od</th>
                        <th scope="col">Do</th>
                        <th scope="col">Stawka prąd</th>
                        <th scope="col">Stawka komputer</th>
                        <th scope="col">Stawka internet</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {projects.map((row) => (
                        <tr key={row.id}>
                            <td></td>
                            <td>{row.startDate}</td>
                            <td>{row.endDate}</td>
                            <td>{row.baseRate}</td>
                            <td>{row.pcRate}</td>
                            <td>{row.internetRate}</td>
                            <td>
                                {<button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => toDayOffDetails(row.id)}>edytuj &raquo;</button> }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}