const remoteWorkPermitUrl = (userId) => "/remote-work-permit?page=0&user="+userId
export const goToRemoteWorkPermits  = (navigate, userId) => navigate(remoteWorkPermitUrl( userId))

export const remoteWorkPermitDetailsUrl = (id) => {
    return `/remote-work-permit/${id}`
}

export const remoteWorkPermitCreateUrl = (userId) => {
    let url = `/remote-work-permit/create`

    if (userId) {
        url += `/${userId}`
    }

    return url;
}
