import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../../api/async-fetch";
import {useTranslation} from "react-i18next";
import AuthService, {HR, SUPER_HR} from "../../../AuthService";
import {printAbsenceEnd, printAbsenceStart} from "../../stringUtils";


export default function AbsencesList() {
    const {t} = useTranslation()

    const navigate = useNavigate();
    let isHR = AuthService.hasAnyRole([HR, SUPER_HR])

    const [absences, setAbsences] = React.useState([]);

    let params = useParams();

    const goToAddAbsence = () => navigate("/users/"+params.userId+"/absences/create")
    const goToAbsenceDetails = (absenceId) => navigate("/users/"+params.userId+"/absences/"+absenceId)

    const fetchAbsences = React.useCallback(() => {
        let url = BACKEND_URL + "absences/search"

        let searchRequest = {
            userId: params.userId,
            pagination: {
                page: 0,
                pageSize: 1000
            }
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}


        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setAbsences(response.content)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    useEffect(() => {
        fetchAbsences()
    }, [fetchAbsences])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {isHR && <button onClick={() => goToAddAbsence()} type="button"
                                className="btn btn-sm btn-primary">Dodaj nieobecność +
                        </button> }
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Data od</th>
                        <th scope="col">Data do</th>
                        <th scope="col">Rodziaj nieobecności</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {absences.map((row) => (
                        <tr key={row.id}>
                            <td></td>
                            <td>{printAbsenceStart(row)}</td>
                            <td>{printAbsenceEnd(row)}</td>
                            <td>{t(row.absenceType)}</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => goToAbsenceDetails(row.id)}>edytuj &raquo;</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}