import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import asyncFetch, {syncFetchAndJSON} from "../../../api/async-fetch";
import {useTranslation} from "react-i18next";
import {filterAndMapUserToSelectOption, mapUsersToSelectOption, mapUserToSelectOption} from "../../users/userUtils";
import Select from "react-select";
import {toast} from "react-toastify";

export default function ProjectAssignmentDataForm(props) {
    const {t} = useTranslation()
    const navigate = useNavigate();

    let {disabled} = props
    let projectId = useParams().projectId;
    let assignmentId = useParams().assignmentId;

    const [taskId, setTaskId] = React.useState(props.pa.taskId);
    const [user, setUser] = React.useState(mapUserToSelectOption(props.pa.user));
    const [supervisor, setSupervisor] = React.useState(mapUserToSelectOption(props.pa.supervisor));
    const [startDate, setStartDate] = React.useState(props.pa.startDate);
    const [endDate, setEndDate] = React.useState(props.pa.endDate);
    const [assigmentType, setAssigmentType] = React.useState(props.pa.assigmentType);
    const [assigmentDimension, setAssigmentDimension] = React.useState(props.pa.assigmentDimension);

    const [tasks, setTasks] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [supervisors, setSupervisors] = React.useState([]);

    const goBack = () => {
        navigate(-1)
    };

    const fetchTasks = (setter) => {
        let url = BACKEND_URL + `projects/${projectId}/tasks/search`

        let searchRequest = {
            pagination: {
                page: 0,
                pageSize: 1000
            }
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}

        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setter(response.content)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fetchUsers = () => {
        let searchRequest = {}

        let url = BACKEND_URL + 'users/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setUsers(mapUsersToSelectOption(response))
                setSupervisors(filterAndMapUserToSelectOption(response,"SUPERVISOR"))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchTasks(setTasks)
    }, [])

    useEffect(() => {
        fetchUsers()
    }, [])

    const taskIdValid = !!taskId;

    let userIdValid = !!user;
    let supervisorUserIdValid = !!supervisor;
    if (userIdValid && supervisorUserIdValid && user.value == supervisor.value) {
        userIdValid = false
        supervisorUserIdValid = false
    }

    const assigmentTypeValid = !!assigmentType;

    let assigmentDimensionValid = assigmentDimension != null && assigmentDimension != "" && assigmentDimension > 0;
    if (assigmentType == "partTime") {
        assigmentDimensionValid = assigmentDimension > 0 && assigmentDimension <= 1
    } else if (assigmentType == "hour") {
        assigmentDimensionValid = assigmentDimension > 0 && assigmentDimension <= 200
    }


    let startDateValid = startDate != ""
    let endDateValid = endDate != ""
    if (startDate && endDate) {
        const d1 = new Date(startDate);
        const d2 = new Date(endDate);

        if (d1 >= d2) {
            startDateValid = endDateValid = false;
        }
    }

    if (taskId != null && tasks) {
        const task = tasks.find((tt) => tt.id == taskId);
        if (task) {
            const taskStartDate = new Date(task.startDate);
            const taskEndDate = new Date(task.endDate);
            const start = new Date(startDate);
            const end = new Date(endDate);

            if (taskStartDate > start) {
                startDateValid = false;
            }

            if (taskEndDate < end) {
                endDateValid = false;
            }
        }
    }


    const isValid = () => {
        return userIdValid && supervisorUserIdValid && taskIdValid && startDateValid && endDateValid && assigmentTypeValid&& assigmentDimensionValid
    }


    const handleSetTaskId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTaskId(event.target.value);
    };


    const handleSetStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleSetEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    const handleSetAssigmentDimensionType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAssigmentType(event.target.value);
    };

    const handleSetAssigmentDimension = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAssigmentDimension(event.target.value);
    };

    const handleCreate = async () => {
        let objToSave = {taskId,
            userId: user.value,
            supervisorUserId: supervisor.value,
            startDate, endDate}

        if (assigmentType == "partTime") {
            objToSave.partOfFullTime = assigmentDimension
        }
        else {
            objToSave.hours = parseInt(assigmentDimension)
        }

        let url = BACKEND_URL + `projects/${projectId}/assignment`

        if (assignmentId) {
            url += `/${assignmentId}`
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}
        const response = await asyncFetch(url, requestConfig);
        let responseJson = await response.json();
        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        }
        else {
            toast.success(t("dataSaved"))
            goBack();
        }
    }

    const handleDelete = async () => {
        if (window.confirm("Czy na pewno chcesz usunąć wybrane zaangażowanie?") != true) {
            return;
        }


        let url = BACKEND_URL + 'assignments/'+assignmentId+'/delete'

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            toast.success(t("dataDeleted"))
            navigate(-1)
        }
    }


    return (
        <>
            <form>
                <div className={"mb-3 col-4" + (!userIdValid ? ' is-invalid' : '')}>
                    <label htmlFor="userSelect" className="form-label">Pracownik</label>
                    <Select
                        isDisabled={disabled || assignmentId}
                        disabled={disabled}
                        required={true}
                        placeholder={t("employee")}
                        className="basic-single"
                        onChange = {setUser}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        value={user}
                        name="color"
                        options={users}
                    />
                </div>

                <div className={"mb-3 col-4" + (!supervisorUserIdValid ? ' is-invalid' : '')}>
                    <label htmlFor="supervisorSelect" className="form-label">Przełożony</label>
                    <Select
                        isDisabled={disabled}
                        required={true}
                        placeholder={t("supervisor")}
                        className="basic-single"
                        onChange = {setSupervisor}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        value={supervisor}
                        name="color"
                        options={supervisors}
                    />
                </div>

                <div className="mb-3 col-4">
                    <label htmlFor="taskSelect" className="form-label">Zadanie</label>
                    <select required={true} className={"form-control" + (!taskIdValid ? ' is-invalid' : '')} id="taskSelect" aria-describedby="Zadanie"
                            value={taskId}
                            onChange={handleSetTaskId}
                            disabled={disabled}
                    >
                        <option value="">{t("selectOption")}</option>
                        {tasks.map((t) => <option value={t.id}>{t.name} [{t.startDate} - {t.endDate}]</option>)}
                    </select>
                </div>

                <div className="mb-3 col-2">
                    <label htmlFor="startDate" className="form-label">Data rozpoczęcia</label>
                    <input type="date" required={true} className={"form-control" + (!startDateValid ? ' is-invalid' : '')} id="startDate" aria-describedby="Data rozpoczęcia"
                           value={startDate}
                           disabled={disabled}
                           onChange={handleSetStartDate}
                    />
                </div>
                <div className="mb-3 col-2">
                    <label htmlFor="endDate" className="form-label">Data zakończenia</label>
                    <input type="date" required={true} className={"form-control" + (!endDateValid ? ' is-invalid' : '')} id="endDate" aria-describedby="Data zakończenia"
                           value={endDate}
                           disabled={disabled}
                           onChange={handleSetEndDate}
                    />
                </div>

                <div className="mb-3 col-4">
                    <label htmlFor="assigmentType" className="form-label">Forma zatrudnienia</label>
                    <select required={true} className={"form-control" + (!assigmentTypeValid ? ' is-invalid' : '')} id="assigmentType" aria-describedby="Forma zatrudnienia"
                            value={assigmentType}
                            disabled={disabled}
                            onChange={handleSetAssigmentDimensionType}
                    >
                        <option value=''>{t("selectOption")}</option>
                        <option value={"partTime"}>stosunek pracy</option>
                        <option value={"hour"}>dodatek</option>
                    </select>
                </div>

                <div className="mb-3 col-2">
                    <label htmlFor="endDate" className="form-label">{assigmentType == "partTime" ? "Wymiar etatu" : "Godziny"}</label>
                    <input type="number" required={true} className={"form-control" + (!assigmentDimensionValid ? ' is-invalid' : '')} id="endDate" aria-describedby="Wymiar"
                           value={assigmentDimension}
                           lang="pl"
                           disabled={disabled}
                           onChange={handleSetAssigmentDimension}
                    />
                </div>

                {props.showActions && <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-primary"
                                disabled={!isValid() || disabled}
                                onClick={handleCreate}
                        >Zapisz
                        </button>
                    </div>

                    {assignmentId && <div className="btn-group me-2">
                        <button type="button" className="btn btn-sm btn-danger"
                                disabled={disabled}
                                onClick={handleDelete}
                        >
                            <span className="bi bi-trash"></span> Usuń</button>
                    </div>}
                </div>}
            </form>
        </>
    );
}