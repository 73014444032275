export const mapUserToSelectOptionNoEmail = (u) => {return  u ? {value:u.id, label: u.firstName + " " + u.lastName}: null}
export const mapUserToSelectOption = (u) => {return  u ? {value:u.id, label: u.firstName + " " + u.lastName + " [" + u.email+"]"}: null}


export const mapUsersToSelectOption = (users) => {
    return users.map(mapUserToSelectOption)
}
export const filterAndMapUserToSelectOption = (users, role, skipEmail) => {
    if (skipEmail) {
        return users.filter(u => u.roles.includes(role)).map(mapUserToSelectOptionNoEmail)
    }
    else {
        return users.filter(u => u.roles.includes(role)).map(mapUserToSelectOption)
    }
}