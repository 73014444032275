import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {reportEditUrl} from "./reportsRouting";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {GetYearOptions, MonthOptions, RenderStatus} from "./StatusUtils";
import {useTranslation} from "react-i18next";
import {getSearchParams, getSearchParamsBool, getSearchParamsInt} from "./reportUtils";
import SortByIcon from "./SortByIcon";
import {REPORT_TYPE_WORK_TIME} from "./ReportType";


export default function ReportsListForLeader() {
    const {t} = useTranslation()

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const [itemsPerPage] = React.useState(20);
    const [totalPages, setTotalPages] = React.useState(1)

    const [sortBy, setSortBy] = React.useState({by: "date", direction: "DESC"});
    const [pageNumber, setPageNumber] = React.useState(getSearchParamsInt(searchParams, "pageNumber",0));
    const [month, setMonth] = React.useState(getSearchParams(searchParams, "month",null));
    const [year, setYear] = React.useState(getSearchParams(searchParams, "year",null));
    const [requiresAttention, setRequiresAttention] = React.useState(getSearchParamsBool(searchParams, "requiresAttention",true));

    const [user, setUser] = React.useState(getSearchParams(searchParams, "user",null));
    const [project, setProject] = React.useState(getSearchParams(searchParams, "project",null));

    const [reports, setReports] = React.useState([]);

    const [users, setUsers] = React.useState([]);
    const [projects, setProjects] = React.useState([]);


    const persistSearchState = (k,v,cleanPageNumber) => {
        let params = {
            month, year, user, project, requiresAttention, pageNumber
        }
        params[k] = v

        if (cleanPageNumber) {
            params.pageNumber = 0
        }

        setSearchParams(createSearchParams(params))
    }

    const setMonthHandler = (v) => {
        let val = v ? v.value : null
        setMonth(val);
        setPageNumber(0);
        persistSearchState("month", val, true);
    }
    const setYearHandler = (v) => {
        let val = v ? v.value : null
        setYear(val);
        setPageNumber(0);
        persistSearchState("year", val, true);
    }
    const toggleRequiresAttention = () => {
        let requiresAttentionNew = !requiresAttention

        setRequiresAttention(requiresAttentionNew);
        setPageNumber(0);
        persistSearchState("requiresAttention", requiresAttentionNew, true);
    }
    const setPageNumberHandler = (v) => {
        setPageNumber(v);
        setPageNumber(0);
        persistSearchState("pageNumber", v);
    }
    const setProjectHandler = (v) => {
        let val = v ? v.value : null
        setProject(val);
        setPageNumber(0);
        persistSearchState("project", val, true);
    }
    const setUserHandler = (v) => {
        let val = v ? v.value : null
        setUser(val);
        setPageNumber(0);
        persistSearchState("user", val, true);
    }


    const fetchUsers = React.useCallback(() => {
        let url = BACKEND_URL + 'users/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setUsers(response.map((u) => {return {value:u.id, label: u.firstName + " " + u.lastName + " [" + u.email+"]"}}))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        fetchUsers()
    }, [fetchUsers])

    const fetchProjects = () => {
        let searchRequest = {
            pagination: {
                page: 0,
                pageSize: 1000
            }
        }

        let url = BACKEND_URL + 'projects/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setProjects(response.content.map((p) => {return {value: p.id, label: p.acronym}}))
            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        fetchProjects()
    }, [])

    const toReportUrl = (userId, year, month) => {
        navigate(reportEditUrl(userId, year, month))
    }

    const handleSortBy = (by) => {
        let direction = "ASC"

        if (sortBy && sortBy.by == by && sortBy.direction == "ASC") {
            direction = "DESC"
        }

        let newSortBy = {
            by: by,
            direction: direction
        }

        setSortBy(newSortBy);
    }

    const fetchReports = () => {
        let searchRequest = {
            sortBy: sortBy,
            pagination: {
                page: pageNumber,
                pageSize: itemsPerPage,
            }
        }

        if (year != null) {
            searchRequest.year = year
        }

        if (month != null) {
            searchRequest.month = month
        }

        if (user != null) {
            searchRequest.userId = user
        }

        if (project != null) {
            searchRequest.projectId = project
        }

        if (requiresAttention != null && requiresAttention == true) {
            searchRequest.requiresLeaderAttention = true
        }

        let url = BACKEND_URL + 'reports/search-for-leader'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setReports(response.content)
                setTotalPages(response.totalPages)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchReports()
    }, [pageNumber, itemsPerPage, year, month, user, project, requiresAttention, sortBy])

    const handlePageClick = (event) => {
        setPageNumberHandler(event.selected)
    };

    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3 className="h3">Karty [kierownik]</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">

                    </div>
                </div>
            </div>


            <div className="form-row row">
                <div className="col-1">
                    <Select
                        placeholder={t("month")}
                        value={MonthOptions.filter(obj => month && obj.value.toString() === month.toString())}
                        className="basic-single"
                        onChange = {setMonthHandler}
                        classNamePrefix="select"
                        isClearable={true}
                        name="color"
                        options={MonthOptions}
                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    />
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("year")}
                        className="basic-single"
                        onChange = {setYearHandler}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        name="color"
                        value={GetYearOptions().filter(obj => year && obj.value.toString() === year.toString())}
                        options={GetYearOptions()}
                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    />
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("project")}
                        className="basic-single"
                        onChange = {setProjectHandler}
                        value={projects.filter(obj => obj.value.toString() === project)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={projects}
                    />
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("employee")}
                        className="basic-single"
                        onChange = {setUserHandler}
                        value={users.filter(obj => obj.value === user)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={users}
                    />
                </div>
                <div className="col-2">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               onClick={() => toggleRequiresAttention()}
                               checked={requiresAttention}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">tylko wymagające uwagi</label>
                    </div>
                </div>
            </div>

            <div className="text-nowrap">
                <table className="table table-striped table-sm table-hover table-fixed">
                    <thead>
                    <tr>
                        <th scope="col" style={{"width": "1%"}}></th>
                        <th scope="col" style={{"width": "14%"}} className="sortable" onClick={() => handleSortBy("date")}>Miesiąc i rok
                            <SortByIcon sortBy={sortBy} by="date" />
                        </th>
                        <th scope="col" style={{"width": "20%"}} className="sortable" onClick={() => handleSortBy("projectAcronym")}>Projekt [akronim]
                            <SortByIcon sortBy={sortBy} by="projectAcronym" />
                        </th>
                        <th scope="col" style={{"width": "20%"}} className="sortable" onClick={() => handleSortBy("employee")}>Pracownik
                            <SortByIcon sortBy={sortBy} by="employee" />
                        </th>
                        <th scope="col"  className="col-2">Status</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {reports.map((row) => (
                        <tr key={row.id}>
                            <td></td>
                            <td>{row.month}-{row.year}</td>
                            <td>{row.reportType == REPORT_TYPE_WORK_TIME ? row.project.acronym : "PRACA ZDALNA"}</td>
                            <td>{row.userSnapshot.firstName} {row.userSnapshot.lastName}</td>
                            <td>{RenderStatus(row)}</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => toReportUrl(row.userSnapshot.id, row.year, row.month)}>otwórz &raquo;</button>
                            </td>
                            <td />
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {
                (!reports || reports.length == 0) && <div className="alert alert-dark" role="alert">
                    Brak danych do wyświetlenia
                </div>
            }
            <div className="row">
                <ReactPaginate
                    nextLabel="&raquo;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={totalPages}
                    previousLabel="&laquo;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    );
}