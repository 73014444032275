import * as React from 'react';
import ProjectTaskForm from "./ProjectTaskForm";

export default function ProjectTaskAdd() {

    const t = {
        name: '',
        startDate: '',
        endDate: ''
    }

    return (
        <ProjectTaskForm taskData={t} key={"createProjectTask"} showActions={true}/>
    );
}