import * as React from 'react';
import {useEffect} from 'react';
import AuthService, {EMPLOYEE, HR, PROJECT_CURATOR, PROJECT_LEADER, SUPERVISOR} from "../../AuthService";
import {useNavigate} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";

export default function MainPage() {

    const navigate = useNavigate();
    const {keycloak , initialized} = useKeycloak();

    const handleLogin = () => {
        keycloak.login()
    }

    const redirect = async (response) => {
        if (AuthService.hasAnyRole([PROJECT_CURATOR], response)) {
            navigate("reports?pageNumber=0")
        } else if (AuthService.hasAnyRole([HR], response)) {
            navigate("/reports-hr?pageNumber=0")
        } else if (AuthService.hasAnyRole([PROJECT_LEADER,], response)) {
            navigate("/report-for-leader")
        } else if (AuthService.hasAnyRole([SUPERVISOR], response)) {
            navigate("/report-for-supervisor")
        } else if (AuthService.hasAnyRole([EMPLOYEE], response)) {
            navigate("/my-reports")
        }
    }

    const getUserAndRedirect = async () => {
        let url = BACKEND_URL + 'user'
        const baseRequestConfig = getBaseRequestConfig("GET")
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                redirect(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getUserAndRedirect()
    }, [])

    return (
        <>
            {!keycloak.authenticated &&
                <div className="container col-xl-10 col-xxl-8 px-4 py-5">
                    <div className="row align-items-center g-lg-5 py-5">
                        <div className="col-md-12 mx-auto col-lg-6">
                            <form className="p-4 p-md-5 border rounded-3 bg-light">
                                <div className="alert alert-success" role="alert">
                                    Witaj w systemie KCP
                                </div>
                                <button className="w-100 btn btn-lg btn-primary" type="button" onClick={handleLogin}>Zaloguj</button>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}