import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch, {syncFetchAndJSON} from "../../api/async-fetch";
import BlockFormAlert from "../general/BlockFormAlert";
import AuthService, {HR, SUPER_HR} from "../../AuthService";
import {toast} from "react-toastify";
import {t} from "i18next";

export default function UserEdit() {

    const navigate = useNavigate();
    let isHr = AuthService.hasAnyRole([HR])
    let isSuperHR = AuthService.hasAnyRole([SUPER_HR])
    let params = useParams();
    const [disabled, setDisabled] = React.useState(true);
    const [lastName, setLastName] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [alias, setAlias] = React.useState('');
    const [registrationNumber, setRegistrationNumber] = React.useState('');


    const fetchUser = React.useCallback(() => {
        let url = BACKEND_URL + 'users/get/' + params.userId
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setLastName(response.lastName)
                setFirstName(response.firstName)
                setEmail(response.email)
                setAlias(response.kingaAlias)
                setRegistrationNumber(response.registrationNumber)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    useEffect(() => {
        fetchUser()
    }, [fetchUser])


    const handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleSetFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const handleSetLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const handleSetAlias = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlias(event.target.value);
    };

    const handleSetRegistrationNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegistrationNumber(event.target.value);
    };

    const goBack = () => {
        navigate(-1)
    };

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const registrationNUmberRegex = /^[0-9]{6}/;

    const isLastNameValid = lastName !== ""
    const isFirstNameValid = firstName != ""
    const isEmailValid = email.match(emailRegex)
    const isAliasValid = alias != ""
    const isRegistrationNumberValid = registrationNumber && registrationNumber.match(registrationNUmberRegex) && String(registrationNumber).length == 6

    const isValid = () => {
        return isLastNameValid && isFirstNameValid && isEmailValid && isAliasValid && isRegistrationNumberValid
    }

    const handleSave = async () => {

        let objToSave = {email, lastName, firstName, registrationNumber,
            kingaAlias: alias
        }

        let url = BACKEND_URL + 'users/' + params.userId
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        }
        else {
            toast.success(t("dataSaved"))
        }
    }

    const handleDelete = async () => {
        if (window.confirm("Czy na pewno chcesz usunąć użytkownika?") != true) {
            return;
        }


        let url = BACKEND_URL + 'users/'+params.userId+'/delete'

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            toast.success(t("dataDeleted"))
            navigate("/users/")
        }
    }


    return (
        <>

            {isHr && <BlockFormAlert disableCallback={setDisabled}/> }
            <form className={"userDataForm"}>

                <div className="mb-3 col-3">
                    <label htmlFor="lastNameInput" className="form-label">Nazwisko</label>
                    <input type="text" required={true} className={"form-control" + (!isLastNameValid ? ' is-invalid' : '')} id="lastNameInput" aria-describedby="Nazwisko"
                           value={lastName}
                           disabled={disabled}
                           onChange={handleSetLastName}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="firstNameInput" className="form-label">Imiona</label>
                    <input type="text" required={true} className={"form-control" + (!isFirstNameValid ? ' is-invalid' : '')} id="firstNameInput" aria-describedby="Imiona"
                           value={firstName}
                           disabled={disabled}
                           onChange={handleSetFirstName}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="aliasInput" className="form-label">Alias [KINGA]</label>
                    <input type="text" required={true} className={"form-control" + (!isAliasValid ? ' is-invalid' : '')} id="aliasInput" aria-describedby="aLIAS"
                           value={alias}
                           disabled={disabled}
                           onChange={handleSetAlias}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="registrationNumber" className="form-label">Numer ewidencyjny</label>
                    <input type="number" required={true} className={"form-control" + (!isRegistrationNumberValid ? ' is-invalid' : '')} id="aliasInput" aria-describedby="Numer identyfikacyjny"
                           disabled={disabled}
                           value={registrationNumber}
                           onChange={handleSetRegistrationNumber}
                    />
                </div>
                <div className="mb-3 col-3">
                    <label htmlFor="emailInput" className="form-label">E-mail</label>
                    <input type="email" required={true} className={"form-control" + (!isEmailValid ? ' is-invalid' : '')} id="emailInput" aria-describedby="E-mail"
                           value={email}
                           disabled={disabled}
                           onChange={handleSetEmail}
                    />
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-primary"
                                disabled={!isValid() | disabled}
                                onClick={handleSave}
                        >Zapisz
                        </button>
                    </div>
                </div>

                <div className="btn-group me-2">
                    <button type="button" className="btn btn-sm btn-danger"
                            disabled={!isSuperHR}
                            onClick={handleDelete}
                    >
                        <span className="bi bi-trash"></span> Usuń</button>
                </div>
            </form>
        </>
    );
}