import * as React from 'react';
import {useTranslation} from "react-i18next";


export default function SortByIcon(props) {

    const {t} = useTranslation()
    const {sortBy, by} = props

    return (
        <>

            {sortBy && sortBy.by == by && sortBy.direction == "ASC" &&
                <i className="bi bi-sort-down"></i>}
            {sortBy && sortBy.by == by && sortBy.direction == "DESC" &&
                <i className="bi bi-sort-up"></i>}

        </>
    );
}