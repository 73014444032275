import * as React from 'react';
import {useTranslation} from "react-i18next";
import {formatDate} from "../../DateUtil";


export default function AcceptanceStatusSingle(props) {

    const {t} = useTranslation()

    const {acceptance, clickHandler, isCurator, acceptBySuperHR, acceptAsEmployeeHandler} = props


    if (!acceptance) {
        return (
        <>
        -
            {acceptBySuperHR && <i className="bi bi-check-circle-fill acceptanceIcon" title="zaakceptuj w imieniu pracownika"><ul className="dropdown-menu" role="menu"
                              aria-labelledby="dropdownMenu">
                <li>
                    <button type="button" className="btn btn-light" onClick={acceptAsEmployeeHandler}>zaakceptuj w imieniu pracownika
                    </button>
                </li>
            </ul>
            </i>}
        </>
        )
    }
    else {
        return <i className="bi bi-check-circle-fill acceptanceIcon slight-ok" title={formatDate(acceptance.at) + " " + acceptance.by.email}>
            {isCurator && <ul className="dropdown-menu" role="menu"
                                  aria-labelledby="dropdownMenu">
                <li>
                    <button type="button" className="btn btn-light" onClick={clickHandler}>wycofaj akceptację
                    </button>
                </li>
            </ul>}
        </i>
    }

}