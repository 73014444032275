export function  getBaseRequestConfig (method) {

    let m = "GET";
    if (method) {
        m = method;
    }

    let request = {
        method: m,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        }
    };

    if (window.sessionStorage.accessToken !== undefined
        && window.sessionStorage.accessToken !== null
        && window.sessionStorage.accessToken !== "null") {
        request.headers.Authorization = window.sessionStorage.accessToken;
    }


    return request;
};
