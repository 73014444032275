export const projectDetails = "projects/edit/:projectId"

export const createProjectAssigmentUrl = (projectId) => {
    return `/projects/edit/${projectId}/assignments/create`
}

export const projectAssignmentsUrl = (projectId) => {
    return `/projects/edit/${projectId}/assignments`
}
export const projectAssetsUrl = (projectId) => {
    return `/projects/edit/${projectId}/assets`
}

export const projectAssignmentDetailsUrl = (projectId, assigmentId) => {
    return `/projects/edit/${projectId}/assignments/${assigmentId}`
}

export const projectAssignmentCreateUrl = (projectId,userId) => {
    let url = `/projects/edit/${projectId}/assignments/create`

    if (userId) {
        url += `/${userId}`
    }

    return url;
}

export const projectDataUrl = (projectId) => {
    return `/projects/edit/${projectId}/data`
}

export const goToProjectDetails = (navigate, project) => {
    if (!project) {
        return;
    }
    navigate(projectDataUrl(project.id))
}

export const projectTasksUrl = (projectId) => {
    return `/projects/edit/${projectId}/tasks`
}

export const projectTasksDetailsUrl = (projectId, taskId) => {
    return `/projects/edit/${projectId}/tasks/${taskId}`
}

export const projectTaskCreateUrl = (projectId) => {
    return projectTasksUrl(projectId) + `/create`
}