import {useNavigate, useParams} from "react-router-dom";
import * as React from 'react';
import {useTranslation} from "react-i18next";
import ProjectDataForm from "./ProjectDataForm";

export default function ProjectAdd() {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const role = useParams().role

    const project = {
        name: '',
        acronym: '',
        signature: '',
        language: '',
        startDate: '',
        endDate: '',
        leaderUserId: '',
        deputyLeaderUserId: '',
        curatorUserId: '',
        archived: false,
        trackable: false,
        generateTimeReports: false,
        managedByRole: role
    }

    const handleBack = () => {
        navigate("/projects")
    };

    return (<>
        <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Dodaj projekt</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                    <button onClick={() => handleBack()} type="button"
                            className="btn btn-sm btn-outline-secondary">&laquo; Powrót do listy
                    </button>
                </div>
            </div>
        </div>

        <ProjectDataForm project={project} showActions={true}/>
    </>);
}