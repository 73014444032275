import {NavLink, Outlet, useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import AuthService, {HR, PROJECT_CURATOR, SUPER_HR} from "../../AuthService";
import {goToAssigments} from "../assignments/assigmentsRouting";
import {goToDelegations} from "../delegation-list/delegationsRouting";
import {goToRemoteWorkPermits} from "../remote-work-permit/remoteWorkPermitRouting";

export default function UserScreen() {
    const navigate = useNavigate();
    const [user, setUser] = React.useState({});
    let canEditRoles = AuthService.hasAnyRole([HR, PROJECT_CURATOR, SUPER_HR])
    const handleBack = () => {
        navigate(-1)
    };

    let params = useParams();

    const fetchUser = React.useCallback(() => {
        let url = BACKEND_URL + 'users/get/' + params.userId
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setUser(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    useEffect(() => {
        fetchUser()
    }, [fetchUser])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">{user.firstName} {user.lastName} [{user.email}]</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button onClick={() => handleBack()} type="button"
                                className="btn btn-sm btn-outline-secondary">&laquo; Powrót
                        </button>
                    </div>
                </div>
            </div>

            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <NavLink to={"/users/" + params.userId + "/data"} className="nav-link"
                             activeClassName="active">
                        Dane pracownika
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={"/users/" + params.userId + "/absences"} className="nav-link"
                             activeClassName="active">
                        Nieobecności
                    </NavLink>
                </li>
                <li className="nav-item">
                    <a className="nav-link"
                       onClick={(event) => {event.preventDefault(); goToDelegations(navigate, params.userId )}}
                       href="#">Delegacje
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link"
                       onClick={(event) => {event.preventDefault(); goToAssigments(navigate, null,params.userId )}}
                       href="#">Zaangażowania do projektów
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link"
                       onClick={(event) => {event.preventDefault(); goToRemoteWorkPermits(navigate, params.userId )}}
                       href="#">Praca zdalna
                    </a>
                </li>
                {canEditRoles && <li className="nav-item">
                    <NavLink to={"/users/" + params.userId + "/roles"} className="nav-link"
                             activeClassName="active">
                        Role
                    </NavLink>
                </li>}
            </ul>

            <Outlet/>
        </>
    );
}