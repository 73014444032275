import {useNavigate} from "react-router-dom";


import * as React from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch from "../../api/async-fetch";
import DaysOffForm from "./DayOffForm";

export default function DaysOffAdd() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/days-off")
    };

    const [name, setName] = React.useState('');
    const [qualified, setQualified] = React.useState(false);
    const [date, setDate] = React.useState('');

    const dayOff = {
        name: '',
        qualified: false,
        date: ''
    }

    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Dodaj dzień wolny</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button onClick={() => handleBack()} type="button"
                                className="btn btn-sm btn-outline-secondary">&laquo; Powrót do listy
                        </button>
                    </div>
                </div>
            </div>

            <DaysOffForm dayOff={dayOff} key={"createDayOffForm"}/>
        </>
    );
}