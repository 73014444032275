import * as React from 'react';

export default function BlockFormAlert(props) {

    const {disableCallback} = props
    const [disabled, setDisabled] = React.useState(true);


    const handle = () => {
        setDisabled(false)
        disableCallback(false)
    }

    return (
        <>
            {disabled && <div className="card col-6">
                <div className="card-body">
                    <h5 className="card-title">Uwaga!</h5>
                    <p className="card-text text-secondary">Edycja tych danych może mieć wpływ na istniejące karty pracy.</p>
                    <button onClick={handle} type="button"
                            className="btn btn-outline-secondary pull-right">Edytuję, wiem co robię
                    </button>
                </div>
            </div>}
        </>
    );
}