import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {printDate} from "../stringUtils";
import {useTranslation} from "react-i18next";
import ReactPaginate from "react-paginate";


export default function EmailsList() {

    const navigate = useNavigate();
    const {t} = useTranslation()
    let [searchParams, setSearchParams] = useSearchParams();

    const [assignments, setAssignments] = React.useState([]);

    const [pageNumber, setPageNumber] = React.useState(0);
    const [itemsPerPage] = React.useState(20);
    const [totalPages, setTotalPages] = React.useState(1)

        const persistSearchState = (k,v, cleanPageNumber) => {
        let params = {
            pageNumber
        }
        params[k] = v

        if (cleanPageNumber) {
            params.pageNumber = 0
        }

        setSearchParams(createSearchParams(params))
    }


    const setPageNumberHandler = (v) => {
        setPageNumber(v);
        persistSearchState("pageNumber", v);
    }



    const handlePageClick = (event) => {
        setPageNumberHandler(event.selected)
    };


    const fetchEmails = () => {


        let url = BACKEND_URL + 'emails/search/'+pageNumber+'/'+itemsPerPage
        const baseRequestConfig = getBaseRequestConfig("GET")
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setAssignments(response.content)
                setTotalPages(response.totalPages)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const splitByLastDot = str =>
        str.split('.').pop();

    useEffect(() => {
        fetchEmails()
    }, [pageNumber, itemsPerPage])



    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3 className="h3">Processed emails</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-sm table-hover">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">id</th>
                        <th scope="col">eventClassName</th>
                        <th scope="col">processedAt</th>
                        <th scope="col">failedAt</th>
                        <th scope="col">payload</th>
                        <th scope="col">error</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {assignments.map((row) => (
                        <tr key={row.id} >
                            <td></td>
                            <td>{row.id}</td>
                            <td>{row.processedAt && printDate(row.processedAt)}</td>
                            <td>{row.failedAt && printDate(row.failedAt)}</td>
                            <td>{splitByLastDot(row.eventClassName)}</td>
                            <td><pre>{JSON.stringify(row.genericEvent,null, 2)}</pre></td>
                            <td>{row.error}</td>
                            <td></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="row">
                <ReactPaginate
                    nextLabel="&raquo;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={totalPages}
                    previousLabel="&laquo;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    );
}