import {useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import asyncFetch, {syncFetchAndJSON} from "../../../api/async-fetch";
import Select from "react-select";
import {GetRolesOptions} from "../../reports/StatusUtils";
import {useTranslation} from "react-i18next";
import AuthService, {PROJECT_CURATOR, SUPER_HR} from "../../../AuthService";


export default function RolesList() {
    const {t} = useTranslation()
    const [roles, setRoles] = React.useState([]);
    const [orginalRoles, setOrginalRoles] = React.useState([]);
    let isSuperHR = AuthService.hasAnyRole([SUPER_HR])
    let isCurator = AuthService.hasAnyRole([PROJECT_CURATOR])

    let params = useParams();

    const setRolesHandler = (value) => {
        if (isSuperHR || isCurator) {
            setRoles(value);
        } else {
            let newRoles = [...orginalRoles];

            for (let r in value) {
                if (!orginalRoles.includes(value[r])) {
                    newRoles.push(value[r]);
                }
            }

            setRoles(newRoles);
        }
    };

    const fetchUserRoles = React.useCallback(() => {
        let url = BACKEND_URL + "users/get/"+params.userId

        const baseRequestConfig = getBaseRequestConfig("GET")

        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {

                let mappedRoles = [];

                for (let r of response.roles) {
                    mappedRoles.push({
                        label: t(r),
                        value: r
                    })
                }

                setRoles(mappedRoles)
                setOrginalRoles(mappedRoles)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    const saveRoles = async () => {
        let searchRequest = {
            roles: roles.map(r => r.value)
        }

        let url = BACKEND_URL + "users/" + params.userId + "/roles"
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        await asyncFetch(url, requestConfig);
        fetchUserRoles();
    }

    useEffect(() => {
        fetchUserRoles()
    }, [fetchUserRoles])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                <h1></h1>
                <div className="btn-toolbar mb-2 mb-md-0">

                </div>
            </div>

            <div className="row">
            <div className="col-6">
                <Select
                    className="basic-single"
                    placeholder={t("role")}
                    onChange = {setRolesHandler}
                    classNamePrefix="select"
                    value={roles}
                    isClearable={false}
                    isMulti={true}
                    name="color"
                    options={GetRolesOptions()}
                />
            </div>

            <div className="col-6">
                <button onClick={saveRoles}
                        className="btn btn-sm btn-outline-secondary">Zapisz
                </button>
            </div>

            </div>


        </>
    );
}