import * as React from 'react';
import {useTranslation} from "react-i18next";
import Comments from "./Comments";
import {useRef} from "react";


export default function CommentsSection(props) {

    const {t} = useTranslation()

    const myRef = useRef(null)


    const [activeCommentsFor, setActiveCommentsFor] = React.useState(null);

    const activate = (id) => {
        setActiveCommentsFor(id)

        setTimeout(() => {
            myRef.current.scrollIntoView()
        }, 100);
    }

    return (
        <>
            <h4>Wiadomości</h4>

            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                {false && <button className={"nav-link "} aria-current="page">
                    &nbsp;</button>}
                {props.reports.map((row) => {
                    let hasUnresolvedComments = props.commentTopics[row.id].some(c => !c.resolvedAt)

                    return (
                    <button id={row.id + "-comments-nav"} key={row.id + "-comments-nav"} className={"nav-link " + (activeCommentsFor == row.project.id ? "active" : "")} onClick={(event) => activate(row.project.id)} aria-current="page">
                        {row.project.acronym} <span className={"badge " + (hasUnresolvedComments ?  "bg-danger":"bg-secondary")}>{props.commentTopics[row.id].length}</span></button>
                )})}
            </div>

            <div className="tab-content" id="nav-tabContent" >
                {props.reports.map((row) => {return (
                    <div className={"tab-pane fade " + (activeCommentsFor == row.project.id ? "show active" : "")} id={row.id + "-comments-tab"} key={row.id + "-comments-tab"} role="tabpanel"
                         aria-labelledby="nav-home-tab">
                        <Comments reportId={row.id} commentTopics={props.commentTopics[row.id]} updateComments={props.updateCommentsForReport}
                        project={row.project}
                        />
                    </div>
                )})}
                <span ref={myRef}/>
            </div>
        </>
    );
}