import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch from "../../api/async-fetch";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function RemoteWorkRateForm(props) {

    const {t} = useTranslation()
    const navigate = useNavigate();

    const id = useParams().id
    const isEdit = !!id
    let {disabled} = props

    const [startDate, setStartDate] = React.useState(props.record.startDate);
    const [endDate, setEndDate] = React.useState(props.record.endDate);
    const [baseRate, setBaseRate] = React.useState(props.record.baseRate);
    const [pcRate, setPcRate] = React.useState(props.record.pcRate);
    const [internetRate, setInternetRate] = React.useState(props.record.internetRate);

    const handleSetStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };
    const handleSetEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    const handleSetBaseRate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBaseRate(event.target.value);
    };
    const handleSetPcRate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPcRate(event.target.value);
    };
    const handleSetInternetRate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternetRate(event.target.value);
    };

    const handleBack = () => {
        navigate("/remote-work-rates")
    };

    const handleDelete = async () => {
        if (window.confirm("Czy na pewno chcesz usunać wybrany rekord?") != true) {
            return;
        }


        let url = BACKEND_URL + 'remote-work-rates/'+id+'/delete'

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t("errorOccurred"))
        } else {
            toast.success(t("dataDeleted"))
            navigate("/remote-work-rates/")
        }
    }


    let startDateValid = startDate != ""
    let endDateValid = endDate != ""
    const baseRateValid = baseRate != "" && baseRate > 0
    const pcRateValid = pcRate != "" && pcRate > 0
    const internetRateValid = internetRate != "" && internetRate > 0


    if (startDate && endDate) {
        const d1 = new Date(startDate);
        const d2 = new Date(endDate);

        if (d1 >= d2) {
            startDateValid = endDateValid = false;
        }
    }


    const isValid = () => {
        return startDateValid && endDateValid && baseRateValid && pcRateValid && internetRateValid
    }


    const handleCreate = async () => {
        let objToSave = {startDate, endDate, baseRate, pcRate, internetRate}


        let url = BACKEND_URL + 'remote-work-rates/'

        if (id) {
            url += id
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            navigate("/remote-work-rates")
        }
    }


    return (
        <form>
            <div className="mb-3 col-2">
                <label htmlFor="acronymInput" className="form-label">Data rozpoczęcia</label>
                <input type="date" required={true} required={true} className={"form-control" + (!startDateValid ? ' is-invalid' : '')}  id="acronymInput"
                       aria-describedby="Data rozpoczęcia"
                       value={startDate}
                       disabled={disabled}
                       onChange={handleSetStartDate}
                />
            </div>
            <div className="mb-3 col-2">
                <label htmlFor="acronymInput" className="form-label">Data zakończenia</label>
                <input type="date" required={true} required={true} className={"form-control" + (!endDateValid ? ' is-invalid' : '')}  id="acronymInput"
                       aria-describedby="Data zakończenia"
                       value={endDate}
                       disabled={disabled}
                       onChange={handleSetEndDate}
                />
            </div>
            <div className="mb-3 col-2">
                <label htmlFor="endDate" className="form-label">Stawka prąd</label>
                <input type="number" min={0} required={true} className={"form-control" + (!baseRateValid ? ' is-invalid' : '')} id="endDate" aria-describedby="Stawka prąd"
                       value={baseRate}
                       lang="pl"
                       disabled={disabled}
                       onChange={handleSetBaseRate}
                />
            </div>
            <div className="mb-3 col-2">
                <label htmlFor="endDate" className="form-label">Stawka komputer</label>
                <input type="number" min={0} required={true} className={"form-control" + (!pcRateValid ? ' is-invalid' : '')} id="endDate" aria-describedby="Stawka komputer"
                       value={pcRate}
                       lang="pl"
                       disabled={disabled}
                       onChange={handleSetPcRate}
                />
            </div>
            <div className="mb-3 col-2">
                <label htmlFor="endDate" className="form-label">Stawka internet</label>
                <input type="number" min={0} required={true} className={"form-control" + (!internetRateValid ? ' is-invalid' : '')} id="endDate" aria-describedby="Stawka internet"
                       value={internetRate}
                       lang="pl"
                       disabled={disabled}
                       onChange={handleSetInternetRate}
                />
            </div>
            <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                    <button type="button" className="btn btn-primary"
                            disabled={!isValid() || disabled}
                            onClick={handleCreate}
                    >Zapisz
                    </button>
                </div>

                {isEdit && <div className="btn-group me-2">
                    <button type="button" className="btn btn-sm btn-danger"
                            disabled={disabled}
                            onClick={handleDelete}
                    >
                        <span className="bi bi-trash"></span> Usuń</button>
                </div>}
            </div>
        </form>
    );
}