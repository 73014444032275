import * as React from 'react';
import {useEffect} from 'react';
import UserAbsenceForm from "./UserAbsenceForm";
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../../api/async-fetch";
import {useParams} from "react-router-dom";
import BlockFormAlert from "../../general/BlockFormAlert";
import AuthService, {HR, SUPER_HR} from "../../../AuthService";

export default function UserAbsenceEdit() {
    const [absence, setAbsence] = React.useState(null);
    const [disabled, setDisabled] = React.useState(true);
    const absenceId = useParams().absenceId
    let isHR = AuthService.hasAnyRole([HR, SUPER_HR])

    const fetchAbsence = () => {
        let url = BACKEND_URL + 'absences/get/'+absenceId
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setAbsence(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchAbsence()
    }, [])

    const record = absence ? {
        absenceType: absence.absenceType,
        startDate: absence.startDate,
        endDate: absence.endDate,
        workedOnDate: absence.workedOnDate,
        hours: absence.hours,
        startTime: absence.startTime ? absence.startTime.substring(0, 5) : null,
        endTime: absence.endTime ? absence.endTime.substring(0, 5) : null,
    } : {}

    return (
        <>
            {isHR && <BlockFormAlert disableCallback={setDisabled}/>}
            {absence && <UserAbsenceForm record={record} disabled={disabled}/>}
        </>
    );
}