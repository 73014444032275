export const HOLIDAY_PAID= "holiday_paid"
export const HOLIDAY_UNPAID = "holiday_not_paid"
export const SICK_LEAVE_PAID = "sick_leave_paid"
export const SICK_LEAVE_UNPAID = "sick_leave_not_paid"
export const OCCASIONAL_LEAVE = "occasional_leave"
export const CHILD_CARE_SICK = "child_care_sick"
export const CHILD_CARE_HEALTHY = "child_care_healthy"
export const OTHER_PAID = "other_paid"
export const OTHER_UNPAID = "other_not_paid"
export const EQUIVALENT = "equivalent"



export const ABSENCE_TYPES = {
    "HOLIDAY_PAID": HOLIDAY_PAID,
    "HOLIDAY_UNPAID": HOLIDAY_UNPAID,
    "SICK_LEAVE_PAID": SICK_LEAVE_PAID,
    "SICK_LEAVE_UNPAID": SICK_LEAVE_UNPAID,
    "OCCASIONAL_LEAVE": OCCASIONAL_LEAVE,
    "CHILD_CARE_SICK": CHILD_CARE_SICK,
    "CHILD_CARE_HEALTHY": CHILD_CARE_HEALTHY,
    "OTHER_PAID": OTHER_PAID,
    "OTHER_UNPAID": OTHER_UNPAID,
    "EQUIVALENT": EQUIVALENT
}