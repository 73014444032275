import * as React from 'react';
import UserAbsenceForm from "./UserAbsenceForm";

export default function UserAbsenceAdd() {
    const absence = {
        absenceType: '',
        startDate: '',
        endDate: '',
        hours: 0.0
    }

    return (
        <>
            <UserAbsenceForm record={absence} />
        </>
    );
}