import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import RemoteWorkRateForm from "./RemoteWorkRateForm";
import BlockFormAlert from "../general/BlockFormAlert";

export default function RemoteWorkRateEdit() {

    const navigate = useNavigate();
    const [disabled, setDisabled] = React.useState(true);
    const [record, setRecord] = React.useState(null);

    const handleBack = () => {
        navigate("/remote-work-rates")
    };

    let params = useParams();

    const fetchRecord = () => {
        let url = BACKEND_URL + 'remote-work-rates/get/' + params.id
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setRecord(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchRecord()
    }, [])

    const remoteWorkRate = record ? {
        startDate: record.startDate,
        endDate: record.endDate,
        pcRate: record.pcRate,
        internetRate: record.internetRate,
        baseRate: record.baseRate,
    } : {}

    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Edytuj rekord</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button onClick={() => handleBack()} type="button"
                                className="btn btn-sm btn-outline-secondary">&laquo; Powrót do listy
                        </button>
                    </div>
                </div>
            </div>
            <BlockFormAlert disableCallback={setDisabled}/>
            {record && <RemoteWorkRateForm record={remoteWorkRate} key={"editDayOffForm"} disabled={disabled}/>}
        </>
    );
}