export const getSearchParams = (searchParams, key,def) => {
    let val = searchParams.get(key);
    if (val === "null" || val === null) {
        return def
    }
    return val
}
export const getSearchParamsBool = (searchParams, key,def) => {
    let val = searchParams.get(key);
    if (val == null || val == "null" || val == undefined) {
        return def
    }
    return val == "true"
}
export const getSearchParamsInt = (searchParams, key,def) => {
    let val = searchParams.get(key);
    if (val == "null") {
        return def
    }
    return parseInt(val)
}