import * as React from 'react';
import {useEffect} from 'react';
import ProjectAssignmentDataForm from "./ProjectAssignmentDataForm";
import {useParams} from "react-router-dom";
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../../api/async-fetch";

export default function ProjectAssignmentAdd() {

    const userId = useParams().userId
    const [user, setUser] = React.useState(null);

    const fetchUser = React.useCallback(() => {
        let url = BACKEND_URL + 'users/get/' + userId
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setUser(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        if (userId) {
            fetchUser()
        }
    }, [fetchUser])


    const isReady = () => {
        return !userId || user != null
    }

    const pa = {
        taskId: null,
        user: user,
        supervisor: null,
        startDate: '',
        endDate: '',
        assigmentType: null,
        assigmentDimension: 0.0,
    }

    return (<>{isReady() && <ProjectAssignmentDataForm pa={pa}  key={"createProjectAssigment"} showActions={true} />}</>);
}