import * as React from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch from "../../api/async-fetch";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";


export default function ConfirmMonthHRReadiness(props) {
    const {t} = useTranslation()

    const {month,year,scope,user,project} = props

    const handleCreate = async () => {
        let objToSave = {
            month: month,
            year: year,
            scope: scope
        }

        let url = BACKEND_URL + 'monthly-hr-readiness/create'

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}


        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        }
        else {
            toast.success(t("dataSaved"))
        }
    }


    return (
        <>
                    <button type="button" className="btn btn-sm btn-primary"
                            disabled={!(month && year) || user || project}
                            onClick={handleCreate}
                    >
                        {t("confirm"+scope+"HRReadinessButton")}

                    </button>
        </>
    );
}

export const ABSENCES = "ABSENCES"
export const DELEGATIONS = "DELEGATIONS"