import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../../api/async-fetch";
import {useParams} from "react-router-dom";
import BlockFormAlert from "../../general/BlockFormAlert";
import UserDelegationForm from "./UserDelegationForm";
import AuthService, {HR, SUPER_HR} from "../../../AuthService";

export default function UserDelegationEdit() {
    const [delegation, setDelegation] = React.useState(null);
    const [disabled, setDisabled] = React.useState(true);
    const delegationId = useParams().delegationId
    let isHR = AuthService.hasAnyRole([HR, SUPER_HR])
    let allowEditing = (delegation && delegation.project && !delegation.project.archived)


    const fetchDelegation = () => {
        let url = BACKEND_URL + 'delegations/get/'+delegationId
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setDelegation(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchDelegation()
    }, [])

    const record = delegation ? {
        id: delegation.id,
        projectId: delegation.project.id,
        startDateTime: delegation.startDateTime,
        endDateTime: delegation.endDateTime,
        city: delegation.city,
    } : {}

    return (
        <>
            {isHR && allowEditing && <BlockFormAlert disableCallback={setDisabled}/> }
            {delegation && <UserDelegationForm delegation={record} disabled={disabled}/>}
        </>
    );
}