import {useNavigate} from "react-router-dom";


import * as React from 'react';
import RemoteWorkRateForm from "./RemoteWorkRateForm";

export default function RemoteWorkRateAdd() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/remote-work-rates")
    };


    const record = {
        startDate: null,
        endDate: null,
        pcRate: null,
        internetRate: null,
        baseRate: null,
    }

    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Dodaj dzień wolny</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button onClick={() => handleBack()} type="button"
                                className="btn btn-sm btn-outline-secondary">&laquo; Powrót do listy
                        </button>
                    </div>
                </div>
            </div>

            <RemoteWorkRateForm record={record} key={"createDayOffForm"}/>
        </>
    );
}