import * as React from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {useTranslation} from "react-i18next";


export default function Tools() {

    const {t} = useTranslation()

    const generateAllPdfs = () => {
        let url = BACKEND_URL + 'reports/generate-all-pdf'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                alert("done")
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const generateForAcceptedAfter = () => {
        let url = BACKEND_URL + 'reports/generate-after-acceptance'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                alert("done")
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const markProjectWIthROles = () => {
        let url = BACKEND_URL + 'projects/mark-with-roles'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                alert("done")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const checkPdfs = () => {
        let url = BACKEND_URL + 'reports/pdfs-status'
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                alert("check console")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const generateOrDropPdf = () => {
        let url = BACKEND_URL + 'reports/generate-missing-pdf'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                alert("done")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const markReportsWithTypeAndManagedBy = () => {
        let url = BACKEND_URL + 'reports/mark-reports-with-type-and-managed-by'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                alert("done")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const downloadReport = (report, extension) => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let url = BACKEND_URL + 'reports/generate-all-pdf/results'

        let headers = new Headers();
        if (window.sessionStorage.accessToken !== undefined
            && window.sessionStorage.accessToken !== null
            && window.sessionStorage.accessToken !== "null") {
            headers.append('Authorization', window.sessionStorage.accessToken);
        }

        fetch(url, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = "last_pdf_generation_report.txt";
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            });

    }


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3 className="h3">Tools</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                    </div>
                </div>
            </div>

            <div className="form-row row">
                <div className="col-2"
                     style={{"display":"none"}}>
                    <button type="button" className="btn btn-sm btn-primary"
                            onClick={() => generateAllPdfs()}
                    >
                        generate-all-pdf
                    </button>

                    <button type="button" className="btn btn-sm btn-primary"
                            onClick={() => downloadReport()}
                    >
                        get last report
                    </button>
                </div>
            </div>

            <div className="form-row row">
                <div className="col-2"
                     style={{"display":"none"}}>
                    <button type="button" className="btn btn-sm btn-primary"
                            onClick={() => generateForAcceptedAfter()}
                    >
                        generateForAcceptedAfter
                    </button>

                    <button type="button" className="btn btn-sm btn-primary"
                            onClick={() => downloadReport()}
                    >
                        get last report
                    </button>
                </div>
            </div>


            <div className="form-row row">
                <div className="col-2"
                     style={{"display":"block"}}>
                    <button type="button" className="btn btn-sm btn-primary"
                            onClick={() => markProjectWIthROles()}
                    >
                        mark project with roles
                    </button>

                </div>
            </div>

            <div className="form-row row">
                <div className="col-2"
                     style={{"display":"block"}}>
                    <button type="button" className="btn btn-sm btn-primary"
                            onClick={() => checkPdfs()}
                    >
                        check pdfs
                    </button>

                </div>
            </div>

            <div className="form-row row">
                <div className="col-2"
                     style={{"display":"block"}}>
                    <button type="button" className="btn btn-sm btn-primary"
                            onClick={() => generateOrDropPdf()}
                    >
                        generate or drop pdfs
                    </button>

                </div>
            </div>

            <div className="form-row row">
                <div className="col-2"
                     style={{"display":"block"}}>
                    <button type="button" className="btn btn-sm btn-primary"
                            onClick={() => markReportsWithTypeAndManagedBy()}
                    >
                        mark reports with type and managed by
                    </button>

                </div>
            </div>


            <div className="row">

            </div>
        </>
    );
}