import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch, {syncFetchAndJSON} from "../../api/async-fetch";
import Select from "react-select";
import {GetYearOptions, MonthOptions} from "./StatusUtils";
import {useTranslation} from "react-i18next";
import AuthService, {SUPER_HR} from "../../AuthService";


export default function CreateReports() {
    const {t} = useTranslation()
    const mapUserToSelectOption = (u) => {return {value:u.id, label: u.firstName + " " + u.lastName + " [" + u.email+"]"}}

    let canOverrride = AuthService.hasAnyRole([SUPER_HR])


    const [inProgress, setInProgress] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [failedUsers, setFailedUsers] = React.useState([]);
    const [month, setMonth] = React.useState(null);
    const [year, setYear] = React.useState(null);
    const [user, setUser] = React.useState(null);
    const [users, setUsers] = React.useState([]);
    const [override, setOverride] = React.useState(false);

    const isValid = () => {
        return month != null && year != null
    }

    const toggleOverride = () => {
        let requiresAttentionNew = !override

        setOverride(requiresAttentionNew);
    }

    const fetchUsers = React.useCallback(() => {
        let url = BACKEND_URL + 'users/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setUsers(response.map(mapUserToSelectOption))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const handleCreate = async () => {

        let breakAction = false;
        if (override) {
            breakAction = window.confirm("Czy na pewno chcesz nadpisać istniejące karty pracy? Spowoduje to utratę danych wprowadzonych przez użytkowników.") !== true;
        }

        if (breakAction) {
            return;
        }

        let objToSave = {
            month: month.value,
            year: year.value,
            userId: user != null ? user.value : null,
            override: override
        }

        let url = BACKEND_URL + 'reports/generate'

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        try {
            setFailed(false);
            setIsSuccess(false);
            setInProgress(true);
            await asyncFetch(url, requestConfig).then(function (response) {
                return response.text();
            }).then(function (data) {
                setFailedUsers(JSON.parse(data));
            });
            setInProgress(false);
            setIsSuccess(true);
        }
        catch (e) {
            setInProgress(false)
            setFailed(true)
        }

    }

    useEffect(() => {
        fetchUsers()
    }, [fetchUsers])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3 className="h3">Wygeneruj karty czasu pracy</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">

                    </div>
                </div>
            </div>

            <div className="form-row row">
                    <div className="col-2">
                        <Select
                            placeholder={t("month")}
                            className="basic-single"
                            onChange = {setMonth}
                            classNamePrefix="select"
                            defaultValue={null}
                            isClearable={true}
                            name="color"
                            options={MonthOptions}
                        />
                    </div>
            </div>
            <div className="form-row row">
                    <div className="col-2">
                        <Select
                            placeholder={t("year")}
                            className="basic-single"
                            onChange = {setYear}
                            classNamePrefix="select"
                            defaultValue={null}
                            isClearable={true}
                            name="color"
                            options={GetYearOptions()}
                        />
                    </div>
            </div>
                <div className="form-row row">


                    <div className="col-6">
                        <Select
                            placeholder={t("employee")}
                            className="basic-single"
                            onChange = {setUser}
                            classNamePrefix="select"
                            defaultValue={null}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={users}
                        />
                    </div>
                </div>

            <div className="form-row row">


                <div className="col-6">
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                       onClick={() => toggleOverride()}
                       disabled={!canOverrride}
                       checked={override}/>
                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">nadpisz istniejące karty pracy</label>
            </div>

                </div>
            </div>
            <div className="form-row row">
                <div className="col-3">
                <button type="button" className="btn btn-primary"
                        disabled={!isValid()}
                        onClick={handleCreate}
                >Generuj
                </button>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-6">

                {inProgress && <div className="alert alert-info" role="alert">
                    Trwa zlecanie zadania...
                </div>}

                {failed && <div className="alert alert-warning" role="alert">
                    Wystąpił błąd...
                </div>}

                {!!failedUsers.length && <div className="alert alert-warning" role="alert">
                  Nie udało się wygenerować kart dla następujących użytkowników:<br/>
                  {failedUsers.join(", ")}
                </div>}

                {isSuccess && <div className="alert alert-success" role="alert">
                    Generowanie kart pracy zostało zlecone
                </div>}
                </div>
            </div>

        </>
    );
}