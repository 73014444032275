import { BACKEND_URL } from "../../config";
import { getBaseRequestConfig } from "../../api/baseRequestConfig";
import { syncFetchAndJSON } from "../../api/async-fetch";

export const freezeReport = (reportId, callback, failedCallback) => {
  let url = BACKEND_URL + `reports/report/${reportId}/freeze`;
  const baseRequestConfig = getBaseRequestConfig("POST");
  const requestConfig = { ...baseRequestConfig };
  syncFetchAndJSON(url, requestConfig)
    .then((response) => {
      callback(
        response.filter(r => r.reportType === "WORK_TIME"),
        response.filter(r => r.reportType === "REMOTE_TIME")
      );
    })
    .catch((_) => {
      if (failedCallback) {
        failedCallback();
      }
    });
};

export const unfreezeReport = (reportId, callback) => {
  let url = BACKEND_URL + `reports/report/${reportId}/unfreeze`;
  const baseRequestConfig = getBaseRequestConfig("POST");
  const requestConfig = { ...baseRequestConfig };
  syncFetchAndJSON(url, requestConfig)
    .then((response) => {
      callback(
        response.filter(r => r.reportType === "WORK_TIME"),
        response.filter(r => r.reportType === "REMOTE_TIME")
      );
    })
    .catch((_) => {
    });
};
