import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetch, syncFetchAndJSON} from "../../api/async-fetch";
import {useTranslation} from "react-i18next";
import AuthService, {EMPLOYEE, HR, PROJECT_CURATOR, PROJECT_LEADER, SUPER_HR, SUPERVISOR} from "../../AuthService";

import CommentsSection from "./CommentsSection";
import AcceptanceStatusSingle from "./AcceptanceStatusSingle";
import AcceptanceSupervisorsStatus from "./AcceptanceSupervisorsStatus";
import PreviewOfOldReports from "./PreviewOfOldReports";
import {printAbsenceDates, printDate} from "../stringUtils";
import {toast} from "react-toastify";
import {ABSENCE_TYPES, CHILD_CARE_HEALTHY, EQUIVALENT} from "../users/absence/absenceType";
import {freezeReport, unfreezeReport} from "./reportFreeze";
import {formatDate} from "../../DateUtil";
import {REPORT_TYPE_WORK_TIME} from "./ReportType";


export default function ReportEdit() {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const user = AuthService.getCurrentUser();

    const [touched, setTouched] = React.useState(false)
    const [isReady, setIsReady] = React.useState(false)
    const [descriptions, setDescriptions] = React.useState({})
    const [dailyWorkSummaryMap, setDailyWorkSummaryMap] = React.useState({});
    const [reports, setReports] = React.useState([]);
    const [remoteTimeReports, setRemoteReportsTime] = React.useState([]);
    const [workingDays, setWorkingDays] = React.useState({});
    const [workingTime, setWorkingTime] = React.useState({});
    const [remoteWorkingTime, setRemoteWorkingTime] = React.useState({});
    const [workingTimeDimensions, setWorkingTimeDimensions] = React.useState({});
    const [absenceTimeDimensions, setAbsenceTimeDimensions] = React.useState({});
    const [absencesTime, setAbsencesTime] = React.useState({});
    const [statusesMap,setStatusesMap] = React.useState({});
    const [commentTopics,setCommentTopics] = React.useState({});
    const [previewOfProject,setPreviewOfProject] = React.useState(null);
    const [totalAssigmentDimension,setTotalAssigmentDimension] = React.useState(null);
    const [totalAllocation,setTotalAllocation] = React.useState({});
    const [validationResults,setValidationResults] = React.useState({});


    //reports/:userId/:year/:month/:projectId
    let params = useParams();

    const month = params.month
    const year = params.year

    let isCurator = AuthService.hasAnyRole([PROJECT_CURATOR])
    let isHR = AuthService.hasAnyRole([HR])
    let isSuperHR = AuthService.hasAnyRole([SUPER_HR])
    let ownsReport = reports[0] && reports[0].userSnapshot.id == user.id
    let someReportFrozen = reports.some(r => r.frozen)
    let allReportsFrozen = reports.every(r => r.frozen)
    let allAccepted = reports.every(r => r.accepted)

    let canAccept = AuthService.hasAnyRole([PROJECT_LEADER, EMPLOYEE, SUPERVISOR]) && !someReportFrozen
    let editDisabled = !((isCurator || ownsReport) && !someReportFrozen)
    let remoteTimeEditDisabled = !ownsReport || someReportFrozen;
    let saveDisabled = !(isCurator || ownsReport) || someReportFrozen
    let notAllowedToEdit = !((isCurator || ownsReport))
    let editAbsencesDisabled = !((isSuperHR || ownsReport) && !someReportFrozen)

    const updateReportsStates = (reports, remoteTimeReports) => {
        setReports(reports)
        setRemoteReportsTime(remoteTimeReports)
        setWorkingAndAbsenceTimeFromRequest(reports)
        setDescriptionsMapFromRequest(reports)
        setStatusesMapFromRequest(reports, remoteTimeReports)
        setCommentTopicsFromRequest(reports)
    }

    const fetchReports = () => {
        let req = {
            year: params.year,
            month: params.month,
            userId: params.userId,
            pagination: {
                page: 0,
                pageSize: 100,
            }
        }

        if (reports != null) {
            req.projectId = reports.value
        }

        let url = BACKEND_URL + 'reports/get-for-edit'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(req)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setIsReady(true)
                updateReportsStates(response.reports, response.remoteTimeReports)
                setWorkingTimesMapFromRequest(response.monthSummary)
                setRemoteReportsTime(response.remoteTimeReports)
                setRemoteWorkingFromRequest(response.remoteTimeReports)
                setValidationResults(response.validationResults)
            })
            .catch((error) => {
            })
    }

    const previewUpdateStartTimes = (workingTimeCp) => {
        let searchRequest = {
            reports: reports,
            remoteReports: remoteTimeReports,
            workingTime: prepareWorkingTimeForRequest(workingTimeCp),
            remoteWorkingTime: prepareRemoteWorkingTimeForRequest(),
            absenceTime: prepareAbsenceTimeForRequest(),
            startTimes: prepareStartTimes(),
            workingTimeDimensions:  prepareWorkingTimeDimensions(),
            absenceTimeDimensions:  prepareAbsenceTimeDimensions(),
        }

        let url = BACKEND_URL + 'reports/preview-update-start-times'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setReports(response.reports)
                setRemoteReportsTime(response.remoteTimeReports)
                setRemoteWorkingFromRequest(response.remoteTimeReports)
                setWorkingTimesMapFromRequest(response.monthSummary)
                setStatusesMapFromRequest(response.reports, response.remoteTimeReports)
                setCommentTopicsFromRequest(response.reports)
                setValidationResults(response.validationResults)
            })
            .catch((error) => {
            })
    }

    const validateDescription = (description) => {
        return description !== null && description != undefined && description.length > 2
    }

    const isInvalidRemoteWorkReport = (r) => {
        let dailyWorkTimeExceeded = false;
        if (ownsReport) {
            let supervisorId = r.supervisors[0].id;

            dailyWorkTimeExceeded = Object.values(validationResults.remoteTimeValidBySupervisorId[supervisorId]).some((e) => {
                return e
            });
        }

        return dailyWorkTimeExceeded || r.totalRemoteTime > r.maxRemoteTime || r.finalTotalRemoteTime > r.maxRemoteTime;
    }


    const isValid = () => {
        let hasInvalidDescription  = Object.values(descriptions).some((desc) => !validateDescription(desc));
        if (hasInvalidDescription) {
            return false;
        }

        let hasUnassignedTime = reports.some((report) => Object.values(report.unallocatedMinutes).some((val) => val != 0));
        if (hasUnassignedTime) {
            return false;
        }

        let hasOverHoursOrIncorrectWorkingHours = Object.values(dailyWorkSummaryMap).some(dws => dws.overHours || dws.endsTooLate || dws.startsTooEarly);
        if (hasOverHoursOrIncorrectWorkingHours) {
            return false;
        }

        let hasInvalidRemoteWorkReport = remoteTimeReports.some((report) => isInvalidRemoteWorkReport(report));
        if (hasInvalidRemoteWorkReport) {
            return false;
        }

        return true;
    }




    const undo = () => {
        if (window.confirm("Czy na pewno chcesz wycofać zmiany?") != true) {
            return;
        }

        fetchReports();
        setTouched(false);
    }


    const saveChanges = () => {
        let req = {
            description: descriptions,
            workingTime: prepareWorkingTimeForRequest(),
            remoteWorkingTime: prepareRemoteWorkingTimeForRequest(),
            absenceTime: prepareAbsenceTimeForRequest(),
            startTimes: prepareStartTimes(),
            workingTimeDimensions:  prepareWorkingTimeDimensions(),
            absenceTimeDimensions:  prepareAbsenceTimeDimensions(),
            year: params.year,
            month: params.month,
            userId: params.userId,
        }

        let url = BACKEND_URL + 'reports/save-changes'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(req)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setReports(response.reports)
                setRemoteReportsTime(response.remoteTimeReports)
                setRemoteWorkingFromRequest(response.remoteTimeReports)
                setWorkingAndAbsenceTimeFromRequest(response.reports)
                setWorkingTimesMapFromRequest(response.monthSummary)
                setDescriptionsMapFromRequest(response.reports)
                setStatusesMapFromRequest(response.reports, response.remoteTimeReports)
                setCommentTopicsFromRequest(response.reports)
                setTouched(false)
                setValidationResults(response.validationResults)

                toast.success("Zapisano zmiany");
            })
            .catch((error) => {
            })
    }


    const removeAcceptance = (reportId,acceptanceBySide) => {
        let url = BACKEND_URL + `reports/report/${reportId}/remove-acceptance/${acceptanceBySide}`
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setStatusesOfReport(response)
            })
            .catch((error) => {
            })
    }

    const acceptReport = (reportId) => {
        if (!isValid()) {
            toast.warning("Karta nie jest poprawnie wypełniona. Nie możesz jej zaakceptować.");
            return;
        }

        let url = BACKEND_URL + `reports/report/${reportId}/accept`
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setStatusesOfReport(response)
            })
            .catch((error) => {
            })
    }

    const acceptReportAsEmployeeBySuperHR = (reportId) => {
        if (!isValid()) {
            toast.warning("Karta nie jest poprawnie wypełniona. Nie możesz jej zaakceptować.");
            return;
        }

        let url = BACKEND_URL + `reports/report/${reportId}/accept-as-employee-by-super-hr`
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setStatusesOfReport(response)
            })
            .catch((error) => {
            })
    }

    const acceptReportRemoteWork = (reportId) => {
        const index = remoteTimeReports.findIndex((c) => c.id === reportId);

        if (index > -1) {
            if (remoteTimeReports[index].finalTotalRemoteTime < 1) {
                if (!window.confirm("Nie wprowadzono godzin pracy zdalnej. Czy kontynuować?")) {
                    return;
                }
            } else {
                if (remoteTimeReports[index].finalTotalRemoteTime < remoteTimeReports[index].maxRemoteTime) {
                    if (!window.confirm("Liczba zaraportowanych godzin pracy zdalnej jest mniejsza od maksymalnej. Czy kontynuować?")) {
                        return;
                    }
                }
            }
        }

        acceptReport(reportId);
    }

    const acceptReportAsCurator = (reportId) => {
        if (!isValid()) {
            toast.warning("Karta nie jest poprawnie wypełniona. Nie możesz jej zaakceptować.");
            return;
        }

        let url = BACKEND_URL + `reports/report/${reportId}/accept-as-curator`
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setStatusesOfReport(response)
            })
            .catch((error) => {
            })
    }
    const acceptReportAsHR = (reportId) => {
        if (!isValid()) {
            toast.warning("Karta nie jest poprawnie wypełniona. Nie możesz jej zaakceptować.");
            return;
        }

        let url = BACKEND_URL + `reports/report/${reportId}/accept-as-hr`
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setStatusesOfReport(response)
            })
            .catch((error) => {
            })
    }

    const allowWorkOnWeekendsAndDaysOff = (month, year, employeeId, allowed) => {
        let req = {
            month, year, employeeId, allowed
        }
        let url = BACKEND_URL + `reports/allow-weekend-and-days-off`
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(req)}
        syncFetch(url, requestConfig)
            .then((response) => {
                fetchReports();
            })
            .catch((error) => {
            })
    }

    const freezeFailure = () => {
        toast.error("Nie udało się zamrozić wybranej karty. Sprawdź swoje uprawnienia.")
    }

    const freezeReportHandler = (reportId) => {
        freezeReport(reportId, updateReportsStates, freezeFailure);
    }

    const unfreezeReportHandler = (reportId) => {
        unfreezeReport(reportId, updateReportsStates);
    }

    const setDescriptionForReport = (desc) => {
        setDescription(desc, previewOfProject.reportId)
        setPreviewOfProject(null)
    }

    const prepareStartTimes = () => {
        let r = {}
        for (let dayNo of Object.keys(dailyWorkSummaryMap)) {
            r[dayNo] = dailyWorkSummaryMap[dayNo].start.hours * 60 + dailyWorkSummaryMap[dayNo].start.minutes
        }
        return r
    }

    const prepareWorkingTimeDimensions = () => {
        let wt = {}

        for (let r of reports){
            wt[r.id] = {}

            for (let pa of r.assignments) {
                let taskId = pa.projectTask.id
                wt[r.id][taskId] = workingTimeDimensions[r.id][taskId].hours * 60 + workingTimeDimensions[r.id][taskId].minutes * 1
            }
        }

        return wt
    }

    const prepareAbsenceTimeDimensions = () => {
        let wt = {}

        for (let r of reports){
            wt[r.id] = {}

            for (let e of Object.entries(absenceTypes)) {
                let at = e[0];

                    wt[r.id][at] = absenceTimeDimensions[r.id][at].hours * 60 + absenceTimeDimensions[r.id][at].minutes * 1
            }
        }

        return wt
    }

    const prepareWorkingTimeForRequest = (workingTimeCp) => {
        let data = workingTimeCp ? workingTimeCp : workingTime


        let wt = {}

        for (let r of reports){
            let WorkingTimeForReport = {
                workingTimeOfTasks: {}
            };

            for (let pa of r.assignments) {
                let taskId = pa.projectTask.id

                let day2Minutes = {};
                for (let dayNo = 1; dayNo <= 31; dayNo++) {
                    let dailyWorktime = data[r.id][taskId][dayNo]

                    let minutes = 0;

                    if (dailyWorktime) {
                        minutes = dailyWorktime.hours * 60 + dailyWorktime.minutes
                    }
                    day2Minutes[dayNo] = minutes
                }

                WorkingTimeForReport.workingTimeOfTasks[taskId] = {day2Minutes: day2Minutes}
            }

            wt[r.id] = WorkingTimeForReport
        }

        return wt
    }

    const prepareRemoteWorkingTimeForRequest = (remoteWorkingTimeCp) => {
        let data = remoteWorkingTimeCp ? remoteWorkingTimeCp : remoteWorkingTime

        let wt = {}

        for (let r of remoteTimeReports){
            let day2Minutes = {};
            for (let dayNo = 1; dayNo <= 31; dayNo++) {
                let dailyWorktime = data[r.id][dayNo]

                let minutes = 0;

                if (dailyWorktime) {
                    minutes = dailyWorktime.hours * 60 + dailyWorktime.minutes
                }
                day2Minutes[dayNo] = minutes
            }
            wt[r.id] = day2Minutes
        }

        return wt
    }

    const prepareAbsenceTimeForRequest = () => {
        let wt = {}
        for (let r of reports){
            let AbsenceTimeOfType = {
                absenceTimeByType: {}
            };
            for (let dayNo = 1; dayNo <= 31; dayNo++) {
                let daily = absencesTime[r.id][dayNo]

                if (!daily) {
                    continue
                }

                for (let absenceType of Object.keys(daily)) {
                    let timeObj = daily[absenceType];
                    let time = timeObj.hours * 60 + timeObj.minutes

                    if (!AbsenceTimeOfType.absenceTimeByType[absenceType]) {
                        AbsenceTimeOfType.absenceTimeByType[absenceType] = {day2Minutes: {}}
                    }
                    AbsenceTimeOfType.absenceTimeByType[absenceType].day2Minutes[dayNo] = time
                }
            }
            wt[r.id] = AbsenceTimeOfType
        }
        return wt
    }

    const setStatusesMapFromRequest = (reports,remoteTimeReports) => {
        let d = {}

        for (let r of reports) {
            d[r.id] = mapStatusesFromReport(r)
        }

        for (let r of remoteTimeReports) {
            d[r.id] = mapStatusesFromReport(r)
        }

        setStatusesMap(d)
    }

    const setCommentTopicsFromRequest = (reports) => {
        let d = {}

        for (let r of reports) {
            d[r.id] = r.commentTopics
        }

        setCommentTopics(d)
    }

    const mapStatusesFromReport = (r) => {
        return {
            acceptanceSupervisors: r.acceptanceSupervisors,
            acceptanceCurator: r.acceptanceCurator,
            acceptanceHR: r.acceptanceHR,
            acceptedByAllSupervisorAt: r.acceptedByAllSupervisorAt,
            frozen: r.frozen,
            acceptanceProjectLeader: r.acceptanceProjectLeader,
            acceptanceEmployee: r.acceptanceEmployee,
            accepted: r.accepted
        }
    }

    const setDescriptionsMapFromRequest = (reports) => {
        let d = {...descriptions}

        for (let r of reports) {
            d[r.id] = r.description ? r.description : ""
        }

        setDescriptions(d)
    }

    const setWorkingTimesMapFromRequest = (monthSummary) => {
        let r  = {};

        setTotalAssigmentDimension(monthSummary.totalAssigmentDimension);
        setTotalAllocation(monthSummary.totalAllocation);

        for (let dayNo = 1; dayNo <= 31; dayNo++) {
            r[dayNo] = {
                start: minutesToPrintArr(monthSummary.dailyWorkSummaryMap[dayNo].start),
                end: minutesToPrintArr(monthSummary.dailyWorkSummaryMap[dayNo].end),
                totalWorkingTime: minutesToPrintArr(monthSummary.dailyWorkSummaryMap[dayNo].totalWorkingTime),
                totalAllocatedTime: minutesToPrintArr(monthSummary.dailyWorkSummaryMap[dayNo].totalAllocatedTime),
                startsTooEarly: monthSummary.dailyWorkSummaryMap[dayNo].startsTooEarly,
                endsTooLate: monthSummary.dailyWorkSummaryMap[dayNo].endsTooLate,
                overHours: monthSummary.dailyWorkSummaryMap[dayNo].overHours,
            }
        }

        setDailyWorkSummaryMap(r)
    }

    const setWorkingAndAbsenceTimeFromRequest = (reports) => {
        let workingTime = {}
        let absenceTime = {}
        let workingTimeDimensions = {}
        let absenceTimeDimensions = {}
        for (let r of reports){
            let WorkingTimeForReport = {}
            let absencesTimeForReport = {}
            if (!workingTimeDimensions[r.id]) {
                workingTimeDimensions[r.id] = {}
            }
            if (!absenceTimeDimensions[r.id]) {
                absenceTimeDimensions[r.id] = {}
            }

            for (let pa of r.assignments) {
                let taskId = pa.projectTask.id

                let day2Minutes = {};
                for (let dayNo = 1; dayNo <= 31; dayNo++) {
                    let dailyWorktime = r.dailyWorktimeMap[dayNo]

                    if (!dailyWorktime) {
                        continue;
                    }
                    let hours = dailyWorktime.assigmentIdToWorkingHours[taskId];
                    day2Minutes[dayNo] = minutesToPrintArr(hours)
                }

                WorkingTimeForReport[taskId] = day2Minutes
                workingTimeDimensions[r.id][taskId] = minutesToPrintArr(r.workingTimeDimension[taskId])
            }

            for (let dayNo = 1; dayNo <= 31; dayNo++) {
                let dailyWorktime = r.dailyWorktimeMap[dayNo]
                if (!dailyWorktime || !dailyWorktime.absences) {
                    continue;
                }

                let dailyAbsences = {}
                for (let absenceType of Object.keys(dailyWorktime.absences)) {
                    dailyAbsences[absenceType] = minutesToPrintArr(dailyWorktime.absences[absenceType]);
                }
                absencesTimeForReport[dayNo] = dailyAbsences;
            }

            workingTime[r.id] = WorkingTimeForReport
            absenceTime[r.id] = absencesTimeForReport

            for (let absenceType of Object.keys(r.absenceDimension)) {
                absenceTimeDimensions[r.id][absenceType] = minutesToPrintArr(r.absenceDimension[absenceType]);
            }

        }
        setWorkingTime(workingTime)
        setAbsencesTime(absenceTime)
        setWorkingTimeDimensions(workingTimeDimensions)
        setAbsenceTimeDimensions(absenceTimeDimensions)
    }


    const setRemoteWorkingFromRequest = (reports) => {
        let remoteWorkingTimeCP = {}
        for (let r of reports){
            if (!remoteWorkingTimeCP[r.id]) {
                remoteWorkingTimeCP[r.id] = {}
            }

            let day2Minutes = {};
            for (let dayNo = 1; dayNo <= 31; dayNo++) {
                let dailyWorktime = r.dailyRemoteTimeMap[dayNo]

                if (!dailyWorktime) {
                    continue;
                }
                day2Minutes[dayNo] = minutesToPrintArr(dailyWorktime)
            }

            remoteWorkingTimeCP[r.id]=day2Minutes
        }

        setRemoteWorkingTime(remoteWorkingTimeCP)
    }

    const fetchWorkingDays = () => {
        let url = BACKEND_URL + `reports/working-days/${year}/${month}/${params.userId}`
        const baseRequestConfig = getBaseRequestConfig("GET")
        const requestConfig = {...baseRequestConfig}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setWorkingDays(response)
            })
            .catch((error) => {
            })
    }

    useEffect(() => {
        fetchReports()
        fetchWorkingDays()
    }, [])

    const dayType2CssName = (isWorking) => {
        let cssClass = "";

        if (!isWorking) {
            cssClass += "nonWorking "
        }

        return cssClass;
    }

    const minutesToPrint = (value) => {
        let attrs = minutesToPrintArr(Math.abs(value));

        let toPrint = "";
        if (value <0) {
            toPrint = "-";
        }
        toPrint += printsHoursAndMInutes(attrs)
        return toPrint
    }

    const printPLN = (intVal) => {
        return (intVal/100.0).toFixed(2) + " zł";
    }



    const setWorkingTimeHoursOnChange = (event: React.ChangeEvent<HTMLInputElement>, reportId, projectTaskId, dayNo) => {
        let strVal = event.target.value;

        let workingTimeCp = {...workingTime}
        if (!workingTimeCp[reportId]) {
            workingTimeCp[reportId] = {}
        }
        if (!workingTimeCp[reportId][projectTaskId]) {
            workingTimeCp[reportId][projectTaskId] = {}
        }
        if (!workingTimeCp[reportId][projectTaskId][dayNo]) {
            workingTimeCp[reportId][projectTaskId][dayNo] = {
                hours: 0,
                minutes: 0,
            }
        }
        workingTimeCp[reportId][projectTaskId][dayNo].hours = strVal


        setWorkingTime(workingTimeCp)
        setTouched(true)
        previewUpdateStartTimes()
    }

    const setWorkingTimeMinutesOnChange = (event: React.ChangeEvent<HTMLInputElement>, reportId, projectTaskId, dayNo) => {
        let strVal = parseInt(event.target.value);

        let workingTimeCp = {...workingTime}
        if (!workingTimeCp[reportId]) {
            workingTimeCp[reportId] = {}
        }
        if (!workingTimeCp[reportId][projectTaskId]) {
            workingTimeCp[reportId][projectTaskId] = {}
        }
        if (!workingTimeCp[reportId][projectTaskId][dayNo]) {
            workingTimeCp[reportId][projectTaskId][dayNo] = {
                hours: 0,
                minutes: 0,
            }
        }

        workingTimeCp[reportId][projectTaskId][dayNo].minutes = strVal


        setWorkingTime(workingTimeCp)
        setTouched(true)
        previewUpdateStartTimes()
    }


    const setRemoteWorkingTimeHoursOnChange = (event: React.ChangeEvent<HTMLInputElement>, reportId, dayNo) => {
        let strVal = parseInt(event.target.value);

        let remoteWorkingTimeCp = {...remoteWorkingTime}
        if (!remoteWorkingTimeCp[reportId]) {
            remoteWorkingTimeCp[reportId] = {}
        }
        if (!remoteWorkingTimeCp[reportId][dayNo]) {
            remoteWorkingTimeCp[reportId][dayNo] = {
                hours: 0,
                minutes: 0,
            }
        }
        remoteWorkingTimeCp[reportId][dayNo].hours = strVal


        setRemoteWorkingTime(remoteWorkingTimeCp)
        setTouched(true)
        previewUpdateStartTimes()
    }

    const setRemoteWorkingTimeMinutesOnChange = (event: React.ChangeEvent<HTMLInputElement>, reportId, dayNo) => {
        let strVal = parseInt(event.target.value);

        let remoteWorkingTimeCp = {...remoteWorkingTime}
        if (!remoteWorkingTimeCp[reportId]) {
            remoteWorkingTimeCp[reportId] = {}
        }
        if (!remoteWorkingTimeCp[reportId][dayNo]) {
            remoteWorkingTimeCp[reportId][dayNo] = {
                hours: 0,
                minutes: 0,
            }
        }
        remoteWorkingTimeCp[reportId][dayNo].minutes = strVal


        setRemoteWorkingTime(remoteWorkingTimeCp)
        setTouched(true)
        previewUpdateStartTimes()
    }

    const setWorkingTimeDimensionOnChange = (event: React.ChangeEvent<HTMLInputElement>, reportId, projectTaskId, type) => {
        let strVal = event.target.value;

        let workingTimeCp = {...workingTimeDimensions}
        if (!workingTimeCp[reportId]) {
            workingTimeCp[reportId] = {}
        }

        if (!workingTimeCp[reportId][projectTaskId]) {
            workingTimeCp[reportId][projectTaskId] = {
                hours: 0,
                minutes: 0,
            }
        }
        workingTimeCp[reportId][projectTaskId][type] = strVal


        setWorkingTimeDimensions(workingTimeCp)
        setTouched(true)
        previewUpdateStartTimes()
    }

    const setAbsenceDimensionOnChange = (event: React.ChangeEvent<HTMLInputElement>, reportId, absenceType, type) => {
        let strVal = event.target.value;

        let workingTimeCp = {...absenceTimeDimensions}
        if (!workingTimeCp[reportId]) {
            workingTimeCp[reportId] = {}
        }

        if (!workingTimeCp[reportId][absenceType]) {
            workingTimeCp[reportId][absenceType] = {
                hours: 0,
                minutes: 0,
            }
        }
        workingTimeCp[reportId][absenceType][type] = strVal


        setAbsenceTimeDimensions(workingTimeCp)
        setTouched(true)
        previewUpdateStartTimes()
    }


    const setAbsenceHours = (event: React.ChangeEvent<HTMLInputElement>, reportId, absenceType, dayNo) => {
        let strVal = parseInt(event.target.value);

        let absencesTimeCp = {...absencesTime}
        let absencesOfReport = absencesTimeCp[reportId]

        let dailyAbsences = absencesOfReport[dayNo];

        if (!dailyAbsences) {
            dailyAbsences = {}
        }

        let time = dailyAbsences[absenceType]

        if (!time) {
            time = {
                hours: 0,
                minutes: 0
            }
        }
        time.hours = strVal
        dailyAbsences[absenceType] = time
        absencesOfReport[dayNo] = dailyAbsences

        setAbsencesTime(absencesTimeCp)
        setTouched(true)
        previewUpdateStartTimes()
    }

    const setAbsenceMinutes = (event: React.ChangeEvent<HTMLInputElement>, reportId, absenceType, dayNo) => {
        let strVal = parseInt(event.target.value);

        let absencesTimeCp = {...absencesTime}
        let absencesOfReport = absencesTimeCp[reportId]

        let dailyAbsences = absencesOfReport[dayNo];

        if (!dailyAbsences) {
            dailyAbsences = {}
        }

        let time = dailyAbsences[absenceType]

        if (!time) {
            time = {
                hours: 0,
                minutes: 0
            }
        }
        time.minutes = strVal
        dailyAbsences[absenceType] = time
        absencesOfReport[dayNo] = dailyAbsences

        setAbsencesTime(absencesTimeCp)
        setTouched(true)
        previewUpdateStartTimes()
    }

    const setStartingTimeHoursAtDay = (event: React.ChangeEvent<HTMLInputElement>, dayNo, type, oldValue) => {
        let strVal = parseInt(event.target.value);
        let dailyMap = {...dailyWorkSummaryMap}

        dailyMap[dayNo].start.hours = strVal

        setDailyWorkSummaryMap(dailyMap);
        setTouched(true)
        previewUpdateStartTimes()
    }

    const setStartingTimeMinutesAtDay = (event: React.ChangeEvent<HTMLInputElement>, dayNo, type, oldValue) => {
        let strVal = parseInt(event.target.value);
        let dailyMap = {...dailyWorkSummaryMap}

        dailyMap[dayNo].start.minutes = strVal

        setDailyWorkSummaryMap(dailyMap);
        setTouched(true)
        previewUpdateStartTimes()
    }

    const copyStartTime = (startTime) => {
        let dailyMap = {...dailyWorkSummaryMap}

        for (let i=1; i<=31; i++) {
            dailyMap[i].start.hours = startTime.hours
            dailyMap[i].start.minutes = startTime.minutes
        }

        setDailyWorkSummaryMap(dailyMap);
        setTouched(true)
        previewUpdateStartTimes()
    }

    const copyDailyHours = (reportId, supervisor, dayNo) => {
        const tasks = reports.flatMap(report => report.assignments)
          .filter(assignment => assignment.supervisor.id === supervisor.id)
          .map(assignment => assignment.projectTask);

        const totalMinutes = reports.reduce((totalMinutes, report) => {
            const worktimeMap = report.dailyWorktimeMap[dayNo]?.assigmentIdToWorkingHours || {};
            const dailyMinutes = tasks.reduce((dayMinutes, task) => {
                return dayMinutes + (worktimeMap[task.id] || 0);
            }, 0);
            return totalMinutes + dailyMinutes;
        }, 0);

        let remoteTime = { ...remoteWorkingTime };
        remoteTime[reportId][dayNo] = {
            hours: Math.floor(totalMinutes / 60),
            minutes: totalMinutes % 60,
            totalMinutes
        };

        setRemoteWorkingTime(remoteTime);
        setTouched(true);
        previewUpdateStartTimes();
    };

    const setAsDefaultTime = (userId, startTime) => {
        let searchRequest = {
            startTime: startTime.hours*60+startTime.minutes,
        }

        let url = BACKEND_URL + 'users/'+userId+'/start-time'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                toast.success(t("dataSaved"))
            })
            .catch((error) => {
                toast.warning(t("errorOccurred"))
            })
    }

    const setDescription = (value, reportId) => {
        let descriptionsCp = {...descriptions}
        descriptionsCp[reportId] = value
        setDescriptions(descriptionsCp)
        setTouched(true)
    }

    const setStatusesOfReport = (r) => {
        const reportsCopy = [...reports]
        const remoteTimeReportsCopy = [...remoteTimeReports]
        const index = reportsCopy.findIndex((c) => c.id === r.id);
        const indexR = remoteTimeReportsCopy.findIndex((c) => c.id === r.id);

        if (index > -1) {
            reportsCopy[index] = {...r}
        }
        if (indexR > -1) {
            remoteTimeReportsCopy[indexR] = {...r}
        }
        updateReportsStates(reportsCopy, remoteTimeReportsCopy)
    }


    let visibleDaysCnt = Object.entries(workingDays).length

    let absenceTypes = {};

    if (reports.length > 0) {
        reports[0].absences.forEach(a => {
                let absences = absenceTypes[a.absenceType];
                if (!absences) {
                    absences = []
                }
                absences.push(a)
                absenceTypes[a.absenceType] = absences
            }
        )
    }

    const handleClearWorkingHours = () => {
        let workingTimeCp = {...workingTime}
        for (let r of reports) {
            let WorkingTimeForReport = {}

            for (let pa of r.assignments) {
                let taskId = pa.projectTask.id

                let day2Minutes = {};
                for (let dayNo = 1; dayNo <= 31; dayNo++) {
                    let dailyWorktime = r.dailyWorktimeMap[dayNo]

                    if (!dailyWorktime) {
                        continue;
                    }
                    day2Minutes[dayNo] = {
                        hours: 0,
                        minutes: 0,
                        totalMinutes: 0
                    }
                }
                WorkingTimeForReport[taskId] = day2Minutes
            }

            workingTimeCp[r.id] = WorkingTimeForReport
        }
        setWorkingTime(workingTimeCp)
        previewUpdateStartTimes(workingTimeCp)
        setTouched(true)
    }

    const updateCommentsForReport = (cts, reportId) => {
        let commentTopicsCp = {...commentTopics}
        commentTopicsCp[reportId] = cts
        setCommentTopics(commentTopicsCp)
    }

    const handleBack = () => {navigate(-1)}

    return (isReady &&
        <>
            <div id="edit-report-screen">
                <div
                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <h3>Karta pracy: <b>{month}</b>-<b>{year}</b> &nbsp;
                        {someReportFrozen && <span className="badge bg-info"> Zamrożona <i className="bi bi-snow"></i></span>}
                    </h3>
                    <h4><b>{reports[0].userSnapshot.firstName} {reports[0].userSnapshot.lastName}</b> [{reports[0].userSnapshot.email}]</h4>
                    {isSuperHR && <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               onClick={() => allowWorkOnWeekendsAndDaysOff(month,year, reports[0].userSnapshot.id, !reports[0].allowReportingOnDaysOffAndWeekend)}
                               disabled={someReportFrozen}
                               checked={reports[0].allowReportingOnDaysOffAndWeekend}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">praca w dni wolne</label>
                    </div>}
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="btn-group me-2">
                            <button onClick={() => handleBack()} type="button"
                                    className="btn btn-sm btn-outline-secondary">&laquo; Powrót
                            </button>
                        </div>
                    </div>

                </div>


                <table className={"timeReport table table-bordered"}>
                    <thead>
                    <tr>
                        <th scope="col">
                            {!editDisabled && <button onClick={() => handleClearWorkingHours()} type="button" style={{width: "95%"}}
                                    className="btn btn-sm btn-outline-warning m-1">wyzeruj godziny <i className="bi bi-x-circle"></i>
                            </button>}
                        </th>
                        {
                            Object.entries(workingDays).map((e) => {
                                    let dayNo = e[0]
                                    let type = e[1]
                                let isWorkingOrAbsence = !(type["weekend"] || type["dayOff"])

                                    return (
                                        <th scope="col" className={dayType2CssName(isWorkingOrAbsence)}>{dayNo}</th>
                                    )
                                }
                            )
                        }
                        <th>bilans</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        reports.map((report) => {

                            let validDescription = validateDescription(descriptions[report.id]);
                            let totalMinutesOfWorkInProject = 0;
                            let totalMinutesOfAbsencesInProject = 0;

                            for (const [key, value] of Object.entries(report.workingTimeDimension)) {
                                totalMinutesOfWorkInProject = totalMinutesOfWorkInProject + value;
                            }

                            for (const [key, value] of Object.entries(report.absenceDimension)) {
                                totalMinutesOfAbsencesInProject = totalMinutesOfAbsencesInProject + value;
                            }
                                return (
                                    <>
                                        <tr className={report.frozen ? "frozen" : ""}>
                                            <th scope="row" className={"mainCat"}>
                                                <span>{report.project.acronym}



                                                </span>
                                                {/*<span style={{float:"right"}}>opis:</span>*/}

                                                {isCurator && <>
                                                <br />

                                                    {report.timeDimmensionsAdjusted &&
                                                        <i className="bi bi-exclamation-triangle"
                                                           title={"wymiar czasu pracy i/lub nieobecności został zmodyfikowany "
                                                               +formatDate(report.timeDimmensionsAdjusted.at) + " przez " + report.timeDimmensionsAdjusted.by.email}
                                                        ></i>
                                                    }

                                                <span className="badge bg-success workingHoursToLog" title={"czas pracy"}>
                                                                    {minutesToPrint(totalMinutesOfWorkInProject)}
                                                                </span>
                                                <span className="badge bg-warning workingHoursToLog" title={"czas nieobecności"}>
                                                                    {minutesToPrint(totalMinutesOfAbsencesInProject)}
                                                                </span>
                                                </>}


                                            </th>
                                            <td colSpan={visibleDaysCnt} className={"workDescriptionCell" + (!validDescription ? " error" : "")}>

                                                {!(notAllowedToEdit || report.frozen) && <textarea
                                                    maxLength={860}
                                                    onKeyPress={e => {
                                                        if(e.key === 'Enter')
                                                            e.preventDefault()
                                                    }}
                                                       disabled={notAllowedToEdit || report.frozen}
                                                      className={"workDescription"}
                                                       placeholder={editDisabled ? "opis wykonywanych prac" : "wprowadź opis wykonywanych prac"}
                                                      onChange={(event) => setDescription(event.target.value, report.id)}
                                                      value ={descriptions[report.id]} />
                                            }
                                                {
                                                    (notAllowedToEdit || report.frozen) && <>{descriptions[report.id]}</>
                                                }


                                                {!editDisabled && <ul className="dropdown-menu" role="menu"
                                                                      aria-labelledby="dropdownMenu">
                                                    {!editDisabled && <li onClick={() => setPreviewOfProject({project: report.project, reportId: report.id})}>
                                                        <button type="button" className="btn btn-light">sprawdź wcześniejsze karty pracy
                                                        </button>
                                                    </li>}
                                                </ul>}
                                            </td>
                                            <td  className={"pointer"} onClick={() => setPreviewOfProject({project: report.project, reportId: report.id})}>
                                            </td>
                                        </tr>
                                        {
                                            report.assignments.map((assigment) => {
                                                let unnallocatedMinutes = report.unallocatedMinutes[assigment.projectTask.id];
                                                let hasUnnallocatedMinutes = unnallocatedMinutes !== 0

                                                let toPrint = workingTimeDimensions[report.id][assigment.projectTask.id]

                                                let invalidHours = toPrint.hours < 0;
                                                let invalidMinutes = toPrint.minutes < 0 || toPrint.minutes > 59;

                                                if (!toPrint.hours && !toPrint.minutes) {
                                                    invalidHours = true;
                                                    invalidHours = true;
                                                }

                                                return (
                                                    <>
                                                        <tr className={report.frozen ? "frozen" : ""}>
                                                            <th scope="row" className={"taskName"}
                                                                width={200}>{assigment.projectTask.name}
                                                            <span className={"badge bg-info workingHoursToLog"} title={"Ilość godzin pracy do zaraportowania"}>
                                                                <input className={"workingTime hours " + (invalidHours ? "error" : "") } type="number"
                                                                       disabled={!isSuperHR}
                                                                       min={0}
                                                                       max={250}
                                                                       onChange={(event) => setWorkingTimeDimensionOnChange(event, report.id,  assigment.projectTask.id, "hours")}
                                                                       value={!toPrint ? '' : toPrint.hours}

                                                                />
                                                                <span className={"time-separator"}>:</span>
                                                                <input className={"workingTime minutes " + (invalidMinutes ? "error" : "") } type="number"
                                                                       disabled={!isSuperHR}
                                                                       min={0}
                                                                       max={59}
                                                                       onChange={(event) => setWorkingTimeDimensionOnChange(event, report.id, assigment.projectTask.id, "minutes")}
                                                                       value={!toPrint ? '' : toPrint.minutes.toString().padStart(2, "0")}
                                                                />
                                                            </span>



                                                            </th>
                                                            {Object.entries(workingDays).map((e) => {
                                                                    let dayNo = e[0]
                                                                    let type = e[1]
                                                                    let isWorking = type["working"] || (report.allowReportingOnDaysOffAndWeekend && !type["absence"])


                                                                let projectTaskId = assigment.projectTask.id
                                                                    let toPrint = workingTime[report.id][projectTaskId][dayNo]


                                                                let invalidHours = false;
                                                                let invalidMinutes = false;
                                                                let isZero = !toPrint || (toPrint.hours * 60 + toPrint.minutes) == 0

                                                                if (toPrint) {
                                                                     invalidHours = toPrint.hours < 0 || toPrint.hours > 23
                                                                     invalidMinutes = toPrint.minutes < 0 || toPrint.minutes > 59
                                                                }

                                                                    return (
                                                                        <td className={dayType2CssName(isWorking) + (isZero ? "zero-value": "")}>
                                                                            {(!isZero || isWorking) && <span className={"time-inputs-wrapper"}>
                                                                            <input className={"workingTime hours " + (invalidHours ? "error" : "") } type="number"
                                                                                   disabled={editDisabled}
                                                                                   min={0}
                                                                                   max={8}
                                                                                   onChange={(event) => setWorkingTimeHoursOnChange(event, report.id, projectTaskId, dayNo)}
                                                                                   value={!toPrint ? '' : toPrint.hours}

                                                                            />
                                                                            <span className={"time-separator"}>:</span>
                                                                            <input className={"workingTime minutes " + (invalidMinutes ? "error" : "") } type="number"
                                                                                   disabled={editDisabled}
                                                                                   min={0}
                                                                                   max={59}
                                                                                   onChange={(event) => setWorkingTimeMinutesOnChange(event, report.id, projectTaskId, dayNo)}
                                                                                   value={!toPrint ? '' : toPrint.minutes.toString().padStart(2, "0")}
                                                                            />
                                                                            </span>}
                                                                        </td>
                                                                    )
                                                                }
                                                            )}
                                                            <td className={hasUnnallocatedMinutes ? "error" : ""}>
                                                                { hasUnnallocatedMinutes? minutesToPrint(unnallocatedMinutes) : <i className="bi bi-check-circle-fill slight-ok"></i>}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                        }
                                            )
                                        }
                                    </>
                                )
                            }
                        )
                    }
                    </tbody>
                    <tbody>
                    <tr>
                        <th colSpan={visibleDaysCnt + 2} className={"sectionHeader"}>
                            &nbsp;
                        </th>
                    </tr>
                    <tr>
                        <th scope="row" style={{ textAlign: "left" }} width={200}><b>Suma</b>

                            <span className="badge bg-info workingHoursToLog" title={"Ilość godzin (pracy i nieobecności) do zaraportowania"}>
                                                                {minutesToPrint(totalAssigmentDimension)}
                        </span>
                        </th>
                        {
                            Object.entries(workingDays).map((e) => {
                                    let dayNo = e[0]
                                    let type = e[1]
                                let isWorkingOrAbsence = !(type["weekend"] || type["dayOff"])


                                    let dailyWorkSummary = dailyWorkSummaryMap[dayNo];
                                    let hasAllocatedTime = dailyWorkSummaryMap[dayNo].totalAllocatedTime.totalMinutes > 0;

                                    return (
                                        <td className={dayType2CssName(isWorkingOrAbsence) + " " + (dailyWorkSummary.overHours ? "error" : "")}>
                                            <b>
                                                {(isWorkingOrAbsence || hasAllocatedTime) && printsHoursAndMInutes(dailyWorkSummary.totalAllocatedTime)}
                                            </b>
                                        </td>
                                    )
                                }
                            )
                        }
                        <td className={totalAssigmentDimension != totalAllocation ? "error" : ""}>
                            { totalAssigmentDimension != totalAllocation? minutesToPrint(totalAssigmentDimension - totalAllocation) : <i className="bi bi-check-circle-fill slight-ok"></i>}
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={visibleDaysCnt + 2} className={"sectionHeader"}>
                            &nbsp;
                        </th>
                    </tr>
                    <tr className={"tableSeparator " + someReportFrozen ? "frozen" : ""}>
                        <th scope="col" style={{ textAlign: "left" }}>Godzina rozpoczęcia</th>
                        {
                            Object.entries(workingDays).map((e) => {
                                    let dayNo = e[0]
                                    let type = e[1]
                                let isWorkingOrAbsence = !(type["weekend"] || type["dayOff"])


                                        let dailyWorkSummary = dailyWorkSummaryMap[dayNo];
                                        let hasTotalAllocatedTime = dailyWorkSummaryMap[dayNo].totalAllocatedTime.totalMinutes > 0;

                                    let toPrint = dailyWorkSummary.start

                                    return (
                                        <td className={dayType2CssName(isWorkingOrAbsence) + " startTimeCell " + (dailyWorkSummary.startsTooEarly ? "error" : "") + (toPrint.totalMinutes == 0 ? " zero-value" : "")}>
                                            {hasTotalAllocatedTime && <><span className={"time-inputs-wrapper"}>
                                                                                <input className={"workingTime hours"} type="number"
                                                                                       disabled={editDisabled}
                                                                                       min={0}
                                                                                       max={21}
                                                                                       onChange={(event) => setStartingTimeHoursAtDay(event, dayNo)}
                                                                                       value={toPrint.totalMinutes > 0 ? toPrint.hours: ""}

                                                                                />
                                                                                <span className={"time-separator"}>:</span>
                                                                                <input className={"workingTime minutes"} type="number"
                                                                                       disabled={editDisabled}
                                                                                       min={0}
                                                                                       max={59}
                                                                                       onChange={(event) => setStartingTimeMinutesAtDay(event, dayNo)}
                                                                                       value={toPrint.totalMinutes > 0 ? toPrint.minutes.toString().padStart(2,"0"): ""}
                                                                                />
                                                                                </span>

                                                {!editDisabled && <ul className="dropdown-menu" role="menu"
                                                    aria-labelledby="dropdownMenu">
                                                    <li onClick={() => copyStartTime(toPrint)}>
                                                        <button type="button" className="btn btn-light">
                                                        skopiuj dla całego miesiąca</button>
                                                    </li>

                                                    {reports[0].userSnapshot.id == user.id && <li onClick={() => setAsDefaultTime(user.id, toPrint)}>
                                                        <button type="button" className="btn btn-light">ustaw jako domyślną
                                                        </button>
                                                    </li>}
                                                </ul>}
                                                </>

                                            }

                                        </td>
                                    )
                                }
                            )
                        }
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="col" style={{ textAlign: "left" }}>Godzina zakończenia</th>
                        {
                            Object.entries(workingDays).map((e) => {
                                    let dayNo = e[0]
                                    let type = e[1]
                                let isWorkingOrAbsence = !(type["weekend"] || type["dayOff"])

                                    let dailyWorkSummary = dailyWorkSummaryMap[dayNo];
                                let hasTotalAllocatedTime = dailyWorkSummaryMap[dayNo].totalAllocatedTime.totalMinutes > 0;

                                return (
                                        <td className={dayType2CssName(isWorkingOrAbsence) + " " + (dailyWorkSummary.endsTooLate ? "error" : "")}>
                                            {   hasTotalAllocatedTime && printsHoursAndMInutes(dailyWorkSummary.end)}
                                        </td>
                                    )
                                }
                            )
                        }
                        <td></td>
                    </tr>
                    </tbody>


                    {remoteTimeReports && remoteTimeReports.length > 0 &&

                        <>


                            <thead>
                            <tr>
                                <th colSpan={visibleDaysCnt + 2} className={"sectionHeader"}>
                                    &nbsp;
                                </th>
                            </tr>
                            <tr>
                                <th className={"emptyCell"}></th>
                                <th colSpan={visibleDaysCnt + 1} className={"sectionHeader sectionTitle"}>
                                    <h5><b>Praca zdalna</b></h5>
                                </th>
                            </tr>
                            <tr>
                                <th className={"taskName"}><b>Przełożony</b>:</th>
                                {
                                    Object.entries(workingDays).map((e) => {
                                          let dayNo = e[0];
                                          let type = e[1];
                                          let isWorkingOrAbsence = !(type["weekend"] || type["dayOff"]);
                                          return (
                                            <th scope="col" className={dayType2CssName(isWorkingOrAbsence)}>{dayNo}</th>
                                          );
                                      }
                                    )
                                }
                                <th></th>
                            </tr>
                            </thead>

                            <tbody>
                            {
                                remoteTimeReports.map((report) => {

                                    let supervisorId = report.supervisors[0].id;


                                    if (ownsReport || isHR || report.supervisors.some(c => c.id == user.id)) {

                                    return (
                                        <>
                                            <tr className={report.frozen ? "frozen" : ""}>
                                                <th scope="row" className={"taskName"}>
                                                <span>
                                                    {report.supervisors[0].firstName.substring(0, 1)}. {report.supervisors[0].lastName}
                                                </span>

                                                    <span className="badge bg-success workingHoursToLog"
                                                          title={"max czas pracy zdalnej"}>
                                                                    {minutesToPrint(report.maxRemoteTime)}
                                                </span>


                                                </th>
                                                {Object.entries(workingDays).map((e) => {
                                                        let dayNo = e[0]
                                                        let type = e[1]
                                                        let isWorking = type["working"] || (report.allowReportingOnDaysOffAndWeekend && !type["absence"])
                                                        let isDelegation = !!type["delegation"]

                                                        let toPrint = remoteWorkingTime[report.id][dayNo]

                                                        let invalidHours = false;
                                                        let invalidMinutes = false;
                                                        let isZero = !toPrint || (toPrint.hours * 60 + toPrint.minutes) == 0

                                                        let total = 0;
                                                        if (toPrint) {
                                                            invalidHours = toPrint.hours < 0 || toPrint.hours > 23
                                                            invalidMinutes = toPrint.minutes < 0 || toPrint.minutes > 59
                                                            total = toPrint.hours * 60 + toPrint.minutes;
                                                        }

                                                        let exceedsDailyWorktime = validationResults.remoteTimeValidBySupervisorId[supervisorId][dayNo]

                                                      return (
                                                        <td
                                                          className={dayType2CssName(isWorking && !isDelegation) + " startTimeCell " + (isZero ? " zero-value " : "") + (exceedsDailyWorktime ? "error" : "")}>
                                                            {(!isZero || isWorking) && !isDelegation && <>
                                                                        <span className={"time-inputs-wrapper"}>
                                                                            <input
                                                                              className={"workingTime hours " + (invalidHours ? "error" : "")}
                                                                              type="number"
                                                                              disabled={remoteTimeEditDisabled}
                                                                              min={0}
                                                                              max={8}
                                                                              onChange={(event) => setRemoteWorkingTimeHoursOnChange(event, report.id, dayNo)}
                                                                              value={!toPrint ? '' : toPrint.hours}

                                                                            />
                                                                            <span className={"time-separator"}>:</span>
                                                                            <input
                                                                              className={"workingTime minutes " + (invalidMinutes ? "error" : "")}
                                                                              type="number"
                                                                              disabled={remoteTimeEditDisabled}
                                                                              min={0}
                                                                              max={59}
                                                                              onChange={(event) => setRemoteWorkingTimeMinutesOnChange(event, report.id, dayNo)}
                                                                              value={!toPrint ? '' : toPrint.minutes.toString().padStart(2, "0")}
                                                                            />
                                                                            </span>

                                                                {!remoteTimeEditDisabled &&
                                                                  <ul className="dropdown-menu" role="menu"
                                                                      aria-labelledby="dropdownMenu">
                                                                      <li onClick={() => copyDailyHours(report.id, report.supervisors[0], dayNo)}>
                                                                          <button type="button"
                                                                                  className="btn btn-light">
                                                                              skopiuj sumę godzin w tym dniu
                                                                          </button>
                                                                      </li>
                                                                  </ul>}
                                                            </>
                                                            }
                                                        </td>
                                                      );
                                                    }
                                                )}
                                                <td className={report.totalRemoteTime > report.maxRemoteTime ? "error" : ""}>
                                                    {minutesToPrint(report.totalRemoteTime)}
                                                </td>
                                            </tr>
                                            {isHR && <tr>
                                                <td colSpan={visibleDaysCnt + 2} >
                                                    Wysokość ryczałtu: <span className="badge bg-success workingHoursToLog"
                                                                             >
                                                                    {printPLN(report.dailyRemoteCompensation)}
                                                </span>
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    Naliczony ryczałt: <span className="badge bg-success workingHoursToLog">
                                                                    {printPLN(report.totalRemoteCompensation)}
                                                </span>


                                                </td>
                                            </tr>}
                                        </>
                                    )
                                }
                                else {
                                    return (<></>)
                                }
                            }
                        )
                    }
                    </tbody>
                    </>
                    }

                    {reports[0].absences.length > 0 &&
                    <>
                        <thead>
                        <tr>
                            <th colSpan={visibleDaysCnt + 2} className={"sectionHeader"}>
                                &nbsp;
                            </th>
                        </tr>
                    <tr>
                        <th className="emptyCell"></th>
                        <th colSpan={visibleDaysCnt + 1} className={"sectionHeader sectionTitle"}>
                            <h5><b>Nieobecności</b></h5>
                        </th>
                    </tr>
                    {isCurator && <tr>
                        <th scope="col"></th>
                        {
                            Object.entries(workingDays).map((e) => {
                                    let dayNo = e[0]
                                    let type = e[1]
                                    let isWorkingOrAbsence = type["absence"] && !(type["weekend"] || type["dayOff"])
                                return (
                                        <th scope="col" className={dayType2CssName(isWorkingOrAbsence)}>{dayNo}</th>
                                    )
                                }
                            )
                        }
                        <th>bilans</th>
                    </tr> }
                    </thead>
                    <tbody>
                    {
                        Object.entries(absenceTypes).map((e) => {
                                let at = e[0]
                                let listOfAbsences = e[1]

                                let hasVisibleDetails = at != "EQUIVALENT" && at != "CHILD_CARE_HEALTHY"

                                return (
                                    <>
                                        <tr>
                                            <th scope="row" className={"mainCat"}>{t(at)}</th>
                                            <td colSpan={visibleDaysCnt + 1} className={"alignLeftInCell"}>
                                                {
                                                    listOfAbsences.sort(function(a,b){
                                                        return new Date(a.startDate) - new Date(b.startDate);
                                                    }).map(a => printAbsenceDates(a)).join(", ")
                                                }


                                            </td>
                                        </tr>
                                        {
                                            hasVisibleDetails && isCurator && reports.map((report) => {
                                                let unnallocatedMinutes = report.unallocatedAbsenceMinutes[at];
                                                let hasUnnallocatedMinutes = unnallocatedMinutes !== 0



                                                let toPrint = absenceTimeDimensions[report.id][at]

                                                let invalidHours = toPrint.hours < 0;
                                                let invalidMinutes = toPrint.minutes < 0 || toPrint.minutes > 59;

                                                if (!toPrint.hours && !toPrint.minutes) {
                                                    invalidHours = true;
                                                    invalidHours = true;
                                                }



                                                return (

                                                        <>
                                                        <tr className={report.frozen ? "frozen" : ""}>
                                                            <th scope="row" className={"taskName"}>
                                                                    {report.project.acronym}
                                                                <span className={"badge bg-info workingHoursToLog"}  itle={"Ilość godziń nieobecności do zaraportowania"}>
                                                                    <input className={"workingTime hours " + (invalidHours ? "error" : "") } type="number"
                                                                           disabled={!isSuperHR}
                                                                           min={0}
                                                                           max={250}
                                                                           onChange={(event) => setAbsenceDimensionOnChange(event, report.id,  at, "hours")}
                                                                           value={!toPrint ? '' : toPrint.hours}

                                                                    />
                                                                    <span className={"time-separator"}>:</span>
                                                                    <input className={"workingTime minutes " + (invalidMinutes ? "error" : "") } type="number"
                                                                           disabled={!isSuperHR}
                                                                           min={0}
                                                                           max={59}
                                                                           onChange={(event) => setAbsenceDimensionOnChange(event, report.id, at, "minutes")}
                                                                           value={!toPrint ? '' : toPrint.minutes.toString().padStart(2, "0")}
                                                                    />
                                                                </span>

                                                                </th>
                                                                {Object.entries(workingDays).map((e) => {
                                                                        let dayNo = e[0]
                                                                        let type = e[1]
                                                                        let isAbsence = type["absence"] && type["absence_"+ABSENCE_TYPES[at]] && !(type["weekend"] || type["dayOff"])

                                                                        // let projectTaskId = assigment.projectTask.id
                                                                        let toPrint = undefined;

                                                                        if (absencesTime[report.id] && absencesTime[report.id][dayNo]) {

                                                                            toPrint = absencesTime[report.id][dayNo][at];
                                                                        }

                                                                        let isZero = !toPrint || ((toPrint.hours * 60) + toPrint.minutes) == 0


                                                                        return (
                                                                            <td className={dayType2CssName(isAbsence) + (isZero ? " zero-value" : "")}>

                                                                            {isAbsence && <span className={"time-inputs-wrapper"}>
                                                                            <input className={"workingTime hours"}
                                                                                   disabled={editAbsencesDisabled}
                                                                                   type="number"
                                                                                   min={0}
                                                                                   max={8}
                                                                                   onChange={(event) => setAbsenceHours(event, report.id, at, dayNo)}
                                                                                   value={!toPrint ? '' : toPrint.hours}

                                                                            />
                                                                            <span className={"time-separator"}>:</span>
                                                                            <input className={"workingTime minutes"}
                                                                                   disabled={editAbsencesDisabled}
                                                                                   type="number"
                                                                                   min={0}
                                                                                   max={59}
                                                                                   onChange={(event) => setAbsenceMinutes(event, report.id, at, dayNo)}
                                                                                   value={!toPrint ? '' : toPrint.minutes.toString().padStart(2, "0")}
                                                                            />
                                                                            </span>}
                                                                            </td>
                                                                        )
                                                                    }
                                                                )}
                                                                <td className={hasUnnallocatedMinutes ? "error" : ""}>
                                                                    { hasUnnallocatedMinutes? minutesToPrint(unnallocatedMinutes) : <i className="bi bi-check-circle-fill slight-ok"></i>}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                            )
                                        }
                                    </>
                                )
                            }
                        )
                    }
                    </tbody>
                </>}


                    {reports[0].delegations.length > 0 &&
                        <>
                            <thead>
                            <tr>
                                <th colSpan={visibleDaysCnt + 2} className={"sectionHeader"}>
                                    &nbsp;
                                </th>
                            </tr>
                            <tr>
                                <th className="emptyCell"></th>
                                <th colSpan={visibleDaysCnt + 1} className={"sectionHeader sectionTitle"}>
                                    <h5><b>Delegacje</b></h5>
                                </th>
                            </tr>
                            </thead>
                            <tbody>

                            {reports[0].delegations.sort(function(a,b){
                                return new Date(a.startDateTime) - new Date(b.startDateTime);
                            }).map(d => {
                                    return (
                                        <tr>
                                            <th scope="row" className={"taskName"}>{d.project.acronym}</th>
                                            <td colSpan={visibleDaysCnt + 1} className={"alignLeftInCell"}>
                                                {printDate(d.startDateTime)} - {printDate(d.endDateTime)}, {d.city}
                                            </td>
                                        </tr>
                                    )
                                }
                            )}
                            </tbody>
                        </>}


                </table>
            </div>

            {!saveDisabled && <div className={"row"}>
                <div className={"col-4"}>
                    {<button type="button" onClick={() => saveChanges()} disabled={!touched} className="btn btn-outline-primary">Zapisz zmiany</button> }
                    {touched && <button type="button" onClick={() => undo()} disabled={!touched} className="btn btn-outline-secondary">Cofnij ostatnie zmiany</button> }
                </div>
                {touched && <div className="col-8 alert alert-info inline" role="alert">
                    Zapisanie zmian spowoduje wycofanie wcześniejszych akceptacji/podpisów
                </div>}
            </div>}

            <div className="row">
                        <div className="col-6">
                            <h4>Statusy</h4>

                            <table className={"table table-sm statusesTable"}>
                                <thead>
                                    <tr>
                                        <th>Projekt</th>
                                        <th>
                                            Pracownik
                                        </th>
                                        <th>
                                            Kierownik
                                        </th>
                                        <th>
                                            Przełożony
                                        </th>
                                        {isCurator && <th>
                                            DOBP
                                        </th>}
                                        {isHR && <th>
                                            Kadry
                                        </th>}
                                        <th>
                                            Zamrożony
                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>

                                {reports.map((r) => {
                                    let s = statusesMap[r.id]

                                    let isOwner = r.userSnapshot.id == user.id;
                                    let hasEmployeeAcceptance = s.acceptanceEmployee

                                    let isCuratorOfProject = r.curator.id == user.id

                                    return s && (<tr>
                                            <td>
                                                {r.project.acronym}
                                            </td>
                                    <td>
                                        <AcceptanceStatusSingle acceptance={s.acceptanceEmployee} isCurator={isCurator}  clickHandler={() => removeAcceptance(r.id, "employee")}
                                        />

                                    </td>
                                    <td>
                                        <AcceptanceStatusSingle acceptance={s.acceptanceProjectLeader} isCurator={isCurator} clickHandler={() => removeAcceptance(r.id, "leader")}/>
                                    </td>
                                    <td>
                                        <AcceptanceSupervisorsStatus acceptances={s.acceptanceSupervisors} acceptedByAllSupervisorAt={r.acceptedByAllSupervisorAt} isCurator={isCurator} clickHandler={() => removeAcceptance(r.id, "supervisor")}/>
                                    </td>
                                    {isCurator && <td>
                                        <AcceptanceStatusSingle acceptance={s.acceptanceCurator} isCurator={isCurator} clickHandler={() => removeAcceptance(r.id, "curator")}/>
                                    </td>}
                                            {isHR && <td>
                                                <AcceptanceStatusSingle acceptance={s.acceptanceHR} isCurator={isCurator} clickHandler={() => removeAcceptance(r.id, "HR")}/>
                                            </td>}
                                    <td>
                                        <AcceptanceStatusSingle acceptance={s.frozen} />
                                    </td>
                                   <td>
                                       {canAccept && <button type="button" disabled={touched || (!isOwner && !hasEmployeeAcceptance)} onClick={() => acceptReport(r.id)} className="btn btn-sm btn-outline-success">Zaakceptuj</button>}
                                       {isCurator && !r.acceptanceCurator && <button type="button" disabled={touched} onClick={() => acceptReportAsCurator(r.id)} className="btn btn-sm btn-outline-success">Zatwierdź</button>}
                                       {isCurator && r.reportType == REPORT_TYPE_WORK_TIME && !s.frozen && <button disabled={touched || !allAccepted} type="button" onClick={() => freezeReportHandler(r.id)} className="btn btn-sm btn-outline-primary">Zamróź</button>}
                                       {isSuperHR && s.frozen && <button disabled={touched} type="button" onClick={() => unfreezeReportHandler(r.id)} className="btn btn-sm btn-outline-primary">Odmróź</button>}
                                    </td>
                                </tr>
                                    )})}
                                </tbody>
                            </table>

                            {remoteTimeReports && remoteTimeReports.length > 0 && <table className={"table table-sm statusesTable"}>
                                <thead>
                                <tr>
                                    <th>Praca zdalna</th>
                                    <th>
                                        Pracownik
                                    </th>
                                    <th>
                                        Przełożony
                                    </th>
                                    {isHR && <th>
                                        Kadry
                                    </th>}
                                    <th>
                                    </th>
                                </tr>
                                </thead>

                                <tbody>

                                {remoteTimeReports.map((r) => {
                                    let s = statusesMap[r.id]

                                    let isOwner = r.userSnapshot.id == user.id;
                                    let hasEmployeeAcceptance = s.acceptanceEmployee
                                    let hasSupervisorAcceptance = s.acceptedByAllSupervisorAt != null
                                    let isSuperVisor = r.supervisors[0].id == user.id



                                    return s && (<tr>
                                            <td>
                                                 <span>
                                                    Przełożony {r.supervisors[0].firstName.substring(0, 1)}. {r.supervisors[0].lastName}
                                                </span>
                                            </td>
                                            <td>
                                                <AcceptanceStatusSingle acceptance={s.acceptanceEmployee}  isCurator={isCurator} clickHandler={() => removeAcceptance(r.id, "employee")}/>

                                            </td>
                                            <td>
                                                <AcceptanceSupervisorsStatus acceptances={s.acceptanceSupervisors} acceptedByAllSupervisorAt={r.acceptedByAllSupervisorAt} isCurator={isCurator} clickHandler={() => removeAcceptance(r.id, "supervisor")}/>
                                            </td>
                                            {isHR && <td>
                                                <AcceptanceStatusSingle acceptance={s.acceptanceHR} isCurator={isHR} clickHandler={() => removeAcceptance(r.id, "HR")}/>
                                            </td>}
                                            <td>
                                                {canAccept && isOwner && <button type="button" disabled={touched || (!isOwner && !hasEmployeeAcceptance) || (isOwner && hasEmployeeAcceptance)} onClick={() => acceptReportRemoteWork(r.id)} className="btn btn-sm btn-outline-success">Zaakceptuj</button>}
                                                {canAccept && isSuperVisor && <button type="button" disabled={touched || !hasEmployeeAcceptance || hasSupervisorAcceptance} onClick={() => acceptReportRemoteWork(r.id)} className="btn btn-sm btn-outline-success">Zaakceptuj</button>}
                                                {isHR && <button type="button" disabled={touched || !hasEmployeeAcceptance || !hasSupervisorAcceptance || !!r.acceptanceHR} onClick={() => acceptReportAsHR(r.id)} className="btn btn-sm btn-outline-success">Zatwierdź</button>}
                                            </td>
                                        </tr>
                                    )})}

                                </tbody>
                            </table>}


                        </div>
                <div className="col-6">
                    <CommentsSection commentTopics={commentTopics} reports={reports} updateCommentsForReport={updateCommentsForReport}></CommentsSection>
                </div>

            </div>




            {previewOfProject && <PreviewOfOldReports userId={params.userId} project={previewOfProject.project}
                                                      setDescriptionForReport={setDescriptionForReport}
                                                      closeModal={() => setPreviewOfProject(null)}/>}
        </>

    );
}


export const printsHoursAndMInutes = (obj) => {
    return obj.hours+":"+obj.minutes.toString().padStart(2, "0");
}



export const minutesToPrintArr = (value) => {
    let num = value == undefined ? 0 : value;
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);//.toString().padStart(2, "0");

    return {
        hours: rhours,
        minutes: rminutes,
        totalMinutes: num
    }
}