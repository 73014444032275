import * as React from 'react';
import UserDelegationForm from "./UserDelegationForm";

export default function UserDelegationAdd() {

    let delegation  = {
        projectId: "",
        startDateTime: "",
        endDateTime: "",
        city: "",
    }
    return (
        <>
            <UserDelegationForm delegation={delegation} />
        </>
    );
}
