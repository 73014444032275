import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch from "../../api/async-fetch";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function DaysOffForm(props) {

    const {t} = useTranslation()
    const navigate = useNavigate();

    const dayOffId = useParams().dayOffId
    const isEdit = !!dayOffId
    let {disabled} = props

    const [name, setName] = React.useState(props.dayOff.name);
    const [date, setDate] = React.useState(props.dayOff.date);

    const handleSetName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleSetDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value);
    };

    const handleBack = () => {
        navigate("/days-off")
    };

    const handleDelete = async () => {
        if (window.confirm("Czy na pewno chcesz usunać wybrany dzień wolny?") != true) {
            return;
        }


        let url = BACKEND_URL + 'days-off/'+dayOffId+'/delete'

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t("errorOccurred"))
        } else {
            toast.success(t("dataDeleted"))
            navigate("/days-off/")
        }
    }


    const dateValid = date != ""
    const nameValid = name != ""

    const isValid = () => {
        return dateValid && nameValid
    }


    const handleCreate = async () => {
        let objToSave = {name}

        if (!isEdit) {
            objToSave.date = date
        }

        let url = BACKEND_URL + 'days-off/'

        if (dayOffId) {
            url += dayOffId
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            navigate("/days-off")
        }
    }


    return (
        <form>
            <div className="mb-3 col-2">
                <label htmlFor="dateInput" className="form-label">Data</label>
                <input type="date" className={"form-control" + (!dateValid ? ' is-invalid' : '')} id="dateInput" aria-describedby="Data"
                       value={date}
                       onChange={handleSetDate}
                       disabled={disabled || isEdit}
                />
            </div>
            <div className="mb-3 col-4">
                <label htmlFor="nameInput" className="form-label">Opis</label>
                <input type="text" className={"form-control" + (!nameValid ? ' is-invalid' : '')} id="nameInput" aria-describedby="Opis"
                       value={name}
                       disabled={disabled}
                       onChange={handleSetName}
                />
            </div>
            <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                    <button type="button" className="btn btn-primary"
                            disabled={!isValid() || disabled}
                            onClick={handleCreate}
                    >Zapisz
                    </button>
                </div>

                {isEdit && <div className="btn-group me-2">
                    <button type="button" className="btn btn-sm btn-danger"
                            disabled={disabled}
                            onClick={handleDelete}
                    >
                        <span className="bi bi-trash"></span> Usuń</button>
                </div>}
            </div>
        </form>
    );
}