import * as React from 'react';
import {useTranslation} from "react-i18next";
import {formatDate} from "../../DateUtil";


export default function AcceptanceSupervisorsStatus(props) {

    const {t} = useTranslation()

    const {acceptances, acceptedByAllSupervisorAt, clickHandler, isCurator} = props

    let title = ""

    if (acceptances) {
        for (let a in acceptances) {
            title += formatDate(acceptances[a].at) + " " + acceptances[a].by.email + ","
        }
    }

    if (title != "") {
        return <i className={"bi bi-check-circle-fill acceptanceIcon" + (acceptedByAllSupervisorAt ? " slight-ok" : "")} title={title}>
            {isCurator && <ul className="dropdown-menu" role="menu"
                              aria-labelledby="dropdownMenu">
                <li>
                    <button type="button" className="btn btn-light" onClick={clickHandler}>wycofaj akceptację
                    </button>
                </li>
            </ul>}
        </i>
    }
    else {
        return<span title={title}> - </span>
    }

}