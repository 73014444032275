import AuthService, {EMPLOYEE, HR, PROJECT_CURATOR, PROJECT_LEADER, SUPER_HR, SUPERVISOR} from "../../AuthService";

export const RenderStatus = (report) => {
    let status = ""

    return <>
        {report.accepted && <span className="badge rounded-pill bg-success">zaakceptowana</span>}
        {!report.accepted && <span className="badge rounded-pill bg-warning">oczekuje na akceptację</span>}
        {report.frozen && <span className="badge rounded-pill bg-info">zamrożona <i className="bi bi-snow" /></span>}
    </>
}

export const RenderStatusForEmployee = (report) => {
    let status = ""

    if (!report.acceptanceEmployee) {
        status = "oczekuje na Twoją akceptację"
    }
    else {
        status = "zaakceptoweś/aś"
        if (!report.accepted) {
            status += ", oczekuje na pozostałe akceptacje"
        }
    }
    if (report.frozen) {
        status += ", zamrożony"
    }

    return status
}

export const StatusOptions = [
    {        value: "accepted_by_all", label: "Zaakceptowana przez wszystkich"},
    {        value: "accepted_by_nobody", label: "Nieposiada żadnych akceptacji"},
    {        value: "accepted_by_user", label: "Pracownik - zaakceptowana"},
    {        value: "not_accepted_by_user", label: "Pracownik - NIEZAKCEPTOWANA"},
    {        value: "accepted_by_leader", label: "Kierownik - zaakceptowana"},
    {        value: "not_accepted_by_leader", label: "Kierownik - NIEZAKCEPTOWANA"},
    {        value: "accepted_by_supervisor", label: "Przełożony - zaakceptowana"},
    {        value: "not_accepted_by_supervisor", label: "Przełożony - NIEZAKCEPTOWANA"},
    {        value: "frozen", label: "Zamrożona"},
    {        value: "not_frozen", label: "Niezamrożona"},
]

export const MonthOptions= [
    {        value: 1, label: 1    },
    {        value: 2, label: 2    },
    {        value: 3, label: 3    },
    {        value: 4, label: 4    },
    {        value: 5, label: 5    },
    {        value: 6, label: 6    },
    {        value: 7, label: 7    },
    {        value: 8, label: 8    },
    {        value: 9, label: 9    },
    {        value: 10, label: 10    },
    {        value: 11, label: 11    },
    {        value: 12, label: 12    },
]

export const LanguageOptions= [
    {        value: "pl", label: "pl"    },
    {        value: "en", label: "en"    },
]

export const GetYearOptions = () =>
    [
        {value: 2022, label: 2022},
        {value: 2023, label: 2023},
        {value: 2024, label: 2024},
        {value: 2025, label: 2025},
        {value: 2026, label: 2026},
    ]


export const RolesOptions = [
    {        value: EMPLOYEE, label: "Pracownik"},
    {        value: PROJECT_LEADER, label: "Kierownik"},
    {        value: SUPERVISOR, label: "Przełożony"},
    {        value: PROJECT_CURATOR, label: "DOBP", canBeAssignedOnlyBy:[PROJECT_CURATOR]},
    {        value: HR, label: "Kadry"},
    {        value: SUPER_HR, label: "SUPER_HR", canBeAssignedOnlyBy:[SUPER_HR]}
]

export const GetRolesOptions = () => {
    return RolesOptions.filter(o => !o.canBeAssignedOnlyBy || AuthService.hasAnyRole(o.canBeAssignedOnlyBy))
}