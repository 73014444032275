import * as React from 'react';
import {useTranslation} from "react-i18next";
import Comments from "./Comments";
import {useEffect, useRef} from "react";
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {RenderStatus} from "./StatusUtils";


export default function PreviewOfOldReports(props) {

    const {t} = useTranslation()
    const [reports, setReports] = React.useState([]);
    const fetchReports = () => {
        let url = BACKEND_URL + `reports/log/${props.userId}/${props.project.id}`
        const baseRequestConfig = getBaseRequestConfig("GET")
        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setReports(response)
                            })
            .catch((error) => {
            })
    }

    useEffect(() => {
        fetchReports()
    }, [])

    return (
        <div className="modal fade show" id="exampleModalLive" tabIndex="-1" aria-labelledby="exampleModalLiveLabel"
             aria-modal="true" role="dialog" style={{display: "block"}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLiveLabel">Opisy zadań dla projektu {props.project.acronym}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.closeModal}></button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-striped table-sm">
                            <thead>
                            <tr>
                                <th scope="col">Miesiąc i rok</th>
                                <th scope="col">Opis prac</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                        {reports.map((row) => (
                            <tr key={row.id}>
                                <td>{row.month}-{row.year}</td>
                                <td><b>{row.description}</b></td>
                                <td onClick={() => props.setDescriptionForReport(row.description)}>
                                    kopiuj
                                </td>
                            </tr>
                        ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={props.closeModal}>Zamknij</button>
                    </div>
                </div>
            </div>
        </div>
    );
}