import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import asyncFetch, {syncFetchAndJSON} from "../../../api/async-fetch";
import {useTranslation} from "react-i18next";
import {minutesToPrintArr, printsHoursAndMInutes} from "../../reports/ReportEdit";
import {toast} from "react-toastify";

export default function UserAbsenceForm(props) {

    const {t} = useTranslation()
    const navigate = useNavigate();

    let params = useParams();
    let {disabled} = props

    const absenceId = useParams().absenceId
    const isEdit = !!absenceId

    const [absenceType, setAbsenceType] = React.useState(props.record.absenceType);
    const [startDate, setStartDate] = React.useState(props.record.startDate);
    const [endDate, setEndDate] = React.useState(props.record.endDate);
    const [workedOnDate, setWorkedOnDate] = React.useState(props.record.workedOnDate);
    const [startTime, setStartTime] = React.useState(props.record.startTime);
    const [endTime, setEndTime] = React.useState(props.record.endTime);


    const [absenceTypes, setAbsenceTypes] = React.useState([]);


    const handleBack = () => {
        navigate("/users/" + params.userId + "/absences")
    }

    const handleAbsenceType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAbsenceType(event.target.value);
    };

    const calculateHour = () => {
        if (startDate && startTime && endTime) {
            let s = Date.parse(startDate + " " + startTime)
            let e = Date.parse(startDate + " " + endTime)
        }
    }

    const handleSetStartTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartTime(event.target.value);
    };

    const handleSetEndTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndTime(event.target.value);
    };


    const handleSetStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleSetEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    const handleSetWorkedOnDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWorkedOnDate(event.target.value);
    };


    const isChildCare = absenceType == "CHILD_CARE_HEALTHY"
    const isEquivalent = absenceType == "EQUIVALENT"
    const absenceTypeInvalid = absenceType == ""
    let startDateInvalid = !startDate;
    let endDateInvalid = (!isChildCare && !isEquivalent) && !endDate;

    if (startDate && endDate) {
        const d1 = new Date(startDate);
        const d2 = new Date(endDate);

        if (d1 > d2) {
            startDateInvalid = endDateInvalid = true;
        }
    }


    let s = Date.parse(startDate + " " + startTime)
    let e = Date.parse(startDate + " " + endTime)
    let hoursStr = "";
    let minutes = 0;

    if (startDate && startTime && endTime) {
        minutes = (e - s) / (1000 * 60)
        hoursStr = printsHoursAndMInutes(minutesToPrintArr(minutes))
    }

    const startTimeInvalid = isChildCare && !startTime
    const endTimeInvalid = isChildCare && (!endTime || minutes <= 0)
    const hoursInvalid = isChildCare && (startTimeInvalid || endTimeInvalid || minutes <= 0 || minutes > 480)
    const workedOnDateInvalid = isEquivalent && !workedOnDate


    const isInValid = startDateInvalid || endDateInvalid || absenceTypeInvalid || hoursInvalid || startTimeInvalid || endTimeInvalid
                        || workedOnDateInvalid

    const handleCreate = async () => {

        let objToSave = {} ;

        if (isChildCare) {
            objToSave = {absenceType, startDate,  startTime, endTime}
        }
        else {
            objToSave = {absenceType, startDate,  endDate}
        }

        if (isEquivalent) {
            objToSave.workedOnDate = workedOnDate
        }

        if (!isEdit) {
            objToSave.userId = params.userId
        }

        let url = BACKEND_URL + 'absences/create'

        if (absenceId) {
            url = BACKEND_URL + 'absences/' + absenceId
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            toast.success(t("dataSaved"))
            navigate("/users/" + params.userId + "/absences")
        }
    }

    const handleDelete = async () => {
        if (window.confirm("Czy na pewno chcesz usunać wybraną nieobecność?") != true) {
            return;
        }


        let url = BACKEND_URL + 'absences/'+absenceId+'/delete'

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            toast.success(t("dataDeleted"))
            navigate("/users/" + params.userId + "/absences")
        }
    }

    const fetchAbsenceTypes = () => {
        let url = BACKEND_URL + "absences/types"
        const baseRequestConfig = getBaseRequestConfig("GET")

        syncFetchAndJSON(url, baseRequestConfig)
            .then((response) => {
                setAbsenceTypes(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        fetchAbsenceTypes()
    }, [])

    return (
        <>
            <form>
                <div className="mb-3 col-3">
                    <label htmlFor="taskSelect" className="form-label">Rodzaj nieobecności</label>
                    <select required={true} className={"form-control" + (absenceTypeInvalid ? ' is-invalid' : '')}
                            id="taskSelect" aria-describedby="Rodzaj nieobecności"
                            value={absenceType}
                            onChange={handleAbsenceType}
                            disabled={disabled}
                    >
                        <option value=''>{t("selectOption")}</option>
                        {absenceTypes.map((type) => <option value={type}>{t(type)}</option>)}
                    </select>
                </div>

                <div className="mb-3 col-2">
                    <label htmlFor="startDate" className="form-label">Data rozpoczęcia</label>
                    <input type="date" required={true}
                           className={"form-control" + (startDateInvalid ? ' is-invalid' : '')} id="startDate"
                           aria-describedby="Data rozpoczęcia"
                           value={startDate}
                           onChange={handleSetStartDate}
                           disabled={disabled}
                    />
                </div>
                {(!isChildCare && !isEquivalent) && <div className="mb-3 col-2">
                    <label htmlFor="endDate" className="form-label">Data zakończenia</label>
                    <input type="date" required={true}
                           className={"form-control" + (endDateInvalid ? ' is-invalid' : '')} id="endDate"
                           aria-describedby="Data zakończenia"
                           value={endDate}
                           onChange={handleSetEndDate}
                           disabled={disabled}
                    />
                </div>}

                {isEquivalent && <div className="mb-3 col-2">
                    <label htmlFor="workedOnDate" className="form-label">Ekwiwalent za dzień</label>
                    <input type="date" required={true}
                           className={"form-control" + (workedOnDateInvalid ? ' is-invalid' : '')} id="workedOnDate"
                           aria-describedby="Ekwiwalent za dzień"
                           value={workedOnDate}
                           onChange={handleSetWorkedOnDate}
                           disabled={disabled}
                    />
                </div>}

                {isChildCare && <div className="mb-3 col-2">
                    <label htmlFor="startDate" className="form-label">Godzina rozpoczęcia</label>
                    <input type="time" required={true}
                           className={"form-control" + (startTimeInvalid ? ' is-invalid' : '')} id="startDate"
                           aria-describedby="Data rozpoczęcia"
                           value={startTime}
                           onChange={handleSetStartTime}
                           disabled={disabled}
                    />
                </div>

                }
                {isChildCare && <div className="mb-3 col-2">
                    <label htmlFor="endDate" className="form-label">Godzina zakończenia</label>
                    <input type="time" required={true}
                           className={"form-control" + (endTimeInvalid ? ' is-invalid' : '')} id="endDate"
                           aria-describedby="Data zakończenia"
                           value={endTime}
                           onChange={handleSetEndTime}
                           disabled={disabled}
                    />
                </div>}

                {isChildCare && <div className="mb-3 col-2">
                    <label htmlFor="hours" className="form-label">Godziny</label>
                    <input type="text" required={false} disabled={true} className={"form-control" + (hoursInvalid ? ' is-invalid' : '')} id="hours"
                           aria-describedby="Godziny"
                           value={hoursStr}
                    />
                </div>}

                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-primary"
                                disabled={isInValid || disabled}
                                onClick={handleCreate}
                        >Zapisz
                        </button>
                    </div>


                    {absenceId && <div className="btn-group me-2">
                        <button type="button" className="btn btn-sm btn-danger"
                                disabled={disabled}
                                onClick={handleDelete}
                        >
                            <span className="bi bi-trash"></span> Usuń</button>
                    </div>}
                </div>
            </form>
        </>
    );
}