import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import {syncFetchAndJSON} from "../../api/async-fetch";
import {printAbsenceEnd, printAbsenceStart} from "../stringUtils";
import {projectDataUrl} from "../projects/projectsRouting";
import {useTranslation} from "react-i18next";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {GetYearOptions, MonthOptions} from "../reports/StatusUtils";
import {getSearchParams} from "../reports/reportUtils";
import ConfirmMonthHRReadiness, {ABSENCES} from "../month-hr-readiness/ConfirmMonthHRReadiness";
import AuthService, {HR, SUPER_HR} from "../../AuthService";
import {goToUserAbsences} from "../users/usersRouting";


export default function AbsencesListForHR() {

    const navigate = useNavigate();
    const {t} = useTranslation()
    let [searchParams, setSearchParams] = useSearchParams();

    const [month, setMonth] = React.useState(getSearchParams(searchParams, "month",null));
    const [year, setYear] = React.useState(getSearchParams(searchParams, "year",null));
    const [user, setUser] = React.useState(getSearchParams(searchParams, "user",null));
    const [project, setProject] = React.useState(getSearchParams(searchParams, "project",null));

    const [absences, setAbsences] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [itemsPerPage] = React.useState(20);
    const [totalPages, setTotalPages] = React.useState(1)
    const [users, setUsers] = React.useState([]);
    const [projects, setProjects] = React.useState([]);

    let isHR = AuthService.hasAnyRole([HR, SUPER_HR])

    const persistSearchState = (k,v, cleanPageNumber) => {
        let params = {
            month, year, user, pageNumber
        }
        params[k] = v

        if (cleanPageNumber) {
            params.pageNumber = 0
        }


        setSearchParams(createSearchParams(params))
    }

    const setMonthHandler = (v) => {
        let val = v ? v.value : null
        setMonth(val);
        setPageNumber(0)
        persistSearchState("month", val, true);
    }
    const setYearHandler = (v) => {
        let val = v ? v.value : null
        setYear(val);
        setPageNumber(0)
        persistSearchState("year", val, true);
    }
    const setUserHandler = (v) => {
        let val = v ? v.value : null
        setUser(val);
        setPageNumber(0)
        persistSearchState("user", val, true);
    }

    const setPageNumberHandler = (v) => {
        setPageNumber(v);
        persistSearchState("pageNumber", v);
    }


    const mapUserToSelectOption = (u) => {return {value:u.id, label: u.firstName + " " + u.lastName + " [" + u.email+"]"}}

    const handlePageClick = (event) => {
        setPageNumberHandler(event.selected)
    };

    const setProjectHandler = (v) => {
        let val = v ? v.value : null
        setProject(val);
        setPageNumber(0);

        persistSearchState("project", val, true);
    }

    const fetchUsers = React.useCallback(() => {
        let url = BACKEND_URL + 'users/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify({})}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setUsers(response.map(mapUserToSelectOption))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const fetchProjects = () => {
        let searchRequest = {
            pagination: {
                page: 0,
                pageSize: 1000
            }
        }

        let url = BACKEND_URL + 'projects/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setProjects(response.content.map((p) => {return {value: p.id, label: p.acronym}}))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchUsers()
    }, [fetchUsers])


    useEffect(() => {
        fetchProjects()
    }, [])


    const goToAbsenceDetails = (userId, absenceId) => navigate("/users/"+userId+"/absences/"+absenceId)

    const gotoAddProject = async () => {
        navigate("/projects/add")
    }

    const toProjectDetails = (projectId) => {
        navigate(projectDataUrl(projectId))
    }

    const fetchAbsences = () => {
        let searchRequest = {
            pagination: {
                page: pageNumber,
                pageSize: itemsPerPage
            }
        }

        if (user) {
            searchRequest.userId = user
        }
        if (month) {
            searchRequest.month = month
        }

        if (year) {
            searchRequest.year = year
        }

        if (project != null) {
            searchRequest.projectId = project
        }

        let url = BACKEND_URL + 'absences/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setAbsences(response.content)
                setTotalPages(response.totalPages)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        fetchAbsences()
    }, [pageNumber, itemsPerPage, month, year, user, project])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h3 className="h3">Nieobecności</h3>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                    </div>
                </div>
            </div>

            <div className="form-row row">
                <div className="col-1">
                    <Select
                        placeholder={t("month")}
                        value={MonthOptions.filter(obj => month && obj.value.toString() === month.toString())}
                        className="basic-single"
                        onChange = {setMonthHandler}
                        classNamePrefix="select"
                        isClearable={true}
                        name="color"
                        options={MonthOptions}
                    />
                </div>
                <div className="col-1">
                    <Select
                        placeholder={t("year")}
                        className="basic-single"
                        onChange = {setYearHandler}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        name="color"
                        value={GetYearOptions().filter(obj => year && obj.value.toString() === year.toString())}
                        options={GetYearOptions()}
                    />
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("employee")}
                        className="basic-single"
                        onChange = {setUserHandler}
                        value={users.filter(obj => obj.value === user)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={users}
                        isDisabled={project}
                    />
                </div>
                <div className="col-2">
                    <Select
                        placeholder={t("project")}
                        className="basic-single"
                        onChange = {setProjectHandler}
                        value={projects.filter(obj => obj.value.toString() === project)}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={projects}
                        isDisabled={user}
                    />
                </div>
                <div className="col-2">
                    {isHR && <button type="button" className="btn btn-sm btn-primary"
                            disabled={!(user)}
                            onClick={() => navigate("/users/"+user+"/absences/create")}
                    >
                        Dodaj nieobecność
                    </button>}
                </div>
                <div className="col-3">
                    {isHR && <ConfirmMonthHRReadiness month={month} year={year} user={user} scope={ABSENCES} />}
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Pracownik</th>
                        <th scope="col">Data od</th>
                        <th scope="col">Data do</th>
                        <th scope="col">Rodzaj nieobecności</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {absences.map((row) => (
                        <tr key={row.id}>
                            <td></td>
                            <td>{ row.user && <a onClick={() => goToUserAbsences(navigate, row.user.id)}>{row.user.firstName} {row.user.lastName}</a>}</td>
                            <td>{printAbsenceStart(row)}</td>
                            <td>{printAbsenceEnd(row)}</td>
                            <td>{t(row.absenceType)}</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => goToAbsenceDetails(row.user.id, row.id)}>edytuj &raquo;</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="row">
                <ReactPaginate
                    nextLabel="&raquo;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={totalPages}
                    previousLabel="&laquo;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    );
}