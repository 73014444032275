import {useNavigate, useParams} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';

import {useTranslation} from "react-i18next";
import Select from "react-select";
import {toast} from "react-toastify";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch, {syncFetchAndJSON} from "../../api/async-fetch";
import {BACKEND_URL} from "../../config";
import {mapUsersToSelectOption, mapUserToSelectOption} from "../users/userUtils";

export default function RemoteWorkPermitForm(props) {
    const {t} = useTranslation()
    const navigate = useNavigate();

    let {disabled} = props
    let id = useParams().id;

    const [user, setUser] = React.useState(mapUserToSelectOption(props.pa.user));
    const [startDate, setStartDate] = React.useState(props.pa.startDate);
    const [endDate, setEndDate] = React.useState(props.pa.endDate);
    const [pcPermit, setPcPermit] = React.useState(props.pa.pcPermit);
    const [internetPermit, setIntenetPermit] = React.useState(props.pa.internetPermit);
    const [maxFactor, setMaxFactor] = React.useState(props.pa.maxFactor);

    const [users, setUsers] = React.useState([]);

    const goBack = () => {
        navigate(-1)
    };


    const fetchUsers = () => {
        let searchRequest = {}

        let url = BACKEND_URL + 'users/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setUsers(mapUsersToSelectOption(response))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchUsers()
    }, [])


    let userIdValid = !!user;


    let assigmentDimensionValid = maxFactor > 0 && maxFactor <= 1


    let startDateValid = startDate != ""
    let endDateValid = endDate != ""
    if (startDate && endDate) {
        const d1 = new Date(startDate);
        const d2 = new Date(endDate);

        if (d1 >= d2) {
            startDateValid = endDateValid = false;
        }
    }


    const isValid = () => {
        return userIdValid && startDateValid && endDateValid &&  assigmentDimensionValid
    }

    const handleSetStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleSetEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    const handleSetAssigmentDimension = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaxFactor(event.target.value);
    };

    const handleCreate = async () => {
        let objToSave = {
            employeeId: user.value,
            startDate, endDate,
            maxFactor: maxFactor,
            pcPermit: pcPermit,
            internetPermit: internetPermit
        }

        let url = BACKEND_URL + `remote-work-permit/`

        if (id) {
            url += `${id}`
        }

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}
        const response = await asyncFetch(url, requestConfig);
        let responseJson = await response.json();
        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        }
        else {
            toast.success(t("dataSaved"))
            goBack();
        }
    }

    const handleDelete = async () => {
        if (window.confirm("Czy na pewno chcesz usunąć wybrany rekord?") != true) {
            return;
        }


        let url = BACKEND_URL + 'remote-work-permit/'+id+'/delete'

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig}

        const response = await asyncFetch(url, requestConfig);

        let responseJson = await response.json();

        if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
            toast.warning(t(responseJson[0]))
        } else {
            toast.success(t("dataDeleted"))
            navigate(-1)
        }
    }


    return (
        <>
            <form>
                <div className={"mb-3 col-4" + (!userIdValid ? ' is-invalid' : '')}>
                    <label htmlFor="userSelect" className="form-label">Pracownik</label>
                    <Select
                        isDisabled={disabled || id}
                        disabled={disabled}
                        required={true}
                        placeholder={t("employee")}
                        className="basic-single"
                        onChange = {setUser}
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        value={user}
                        name="color"
                        options={users}
                    />
                </div>


                <div className="mb-3 col-2">
                    <label htmlFor="startDate" className="form-label">Data rozpoczęcia</label>
                    <input type="date" required={true} className={"form-control" + (!startDateValid ? ' is-invalid' : '')} id="startDate" aria-describedby="Data rozpoczęcia"
                           value={startDate}
                           disabled={disabled}
                           onChange={handleSetStartDate}
                    />
                </div>
                <div className="mb-3 col-2">
                    <label htmlFor="endDate" className="form-label">Data zakończenia</label>
                    <input type="date" required={true} className={"form-control" + (!endDateValid ? ' is-invalid' : '')} id="endDate" aria-describedby="Data zakończenia"
                           value={endDate}
                           disabled={disabled}
                           onChange={handleSetEndDate}
                    />
                </div>

                <div className="mb-3 col-4 form-check">
                    <input type="checkbox" className="form-check-input" id="pcPermitInput" checked={pcPermit}
                           disabled={disabled}
                           onClick={(e) => setPcPermit(!pcPermit)}/>
                    <label className="form-check-label" htmlFor="pcPermitInput">Komputer/laptop prywatny</label>
                </div>

                <div className="mb-3 col-4 form-check">
                    <input type="checkbox" className="form-check-input" id="internetPermitInput" checked={internetPermit}
                           disabled={disabled}
                           onClick={(e) => setIntenetPermit(!internetPermit)}/>
                    <label className="form-check-label" htmlFor="internetPermitInput">Usługi telekomunikacyjne prywatne</label>
                </div>


                <div className="mb-3 col-2">
                    <label htmlFor="endDate" className="form-label">{"Max wymiar pracy zdalnej"}</label>
                    <input type="number" required={true} className={"form-control" + (!assigmentDimensionValid ? ' is-invalid' : '')} id="endDate" aria-describedby="Wymiar"
                           value={maxFactor}
                           lang="pl"
                           disabled={disabled}
                           onChange={handleSetAssigmentDimension}
                    />
                </div>

                {props.showActions && <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-primary"
                                disabled={!isValid() || disabled}
                                onClick={handleCreate}
                        >Zapisz
                        </button>
                    </div>

                    {id && <div className="btn-group me-2">
                        <button type="button" className="btn btn-sm btn-danger"
                                disabled={disabled}
                                onClick={handleDelete}
                        >
                            <span className="bi bi-trash"></span> Usuń</button>
                    </div>}
                </div>}
            </form>
        </>
    );
}