import {useParams} from "react-router-dom";


import * as React from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch, {syncFetchAndJSON} from "../../api/async-fetch";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";



export default function ReplyToComment(props) {

    const {t} = useTranslation()
    const {reportId, commentTopic} = props

    const myRef = useRef(null)
    const isResolved = !!commentTopic.resolvedAt

    const [text, setText] = React.useState("");
    const [isOpen, setIsOpen] = React.useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault()
        let objToSave = {text: text}

        let url = BACKEND_URL + `reports/report/${reportId}/comments/topic/${commentTopic.uuid}/comment/create`

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await syncFetchAndJSON(url, requestConfig);
        props.updateComments(response, reportId)
        setText("")
        setIsOpen(false)
    }

    const markAsResolved = async (event) => {
        let objToSave = {text: text}

        let url = BACKEND_URL + `reports/report/${reportId}/comments/topic/${commentTopic.uuid}/resolve`

        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(objToSave)}

        const response = await syncFetchAndJSON(url, requestConfig);
        props.updateComments(response, reportId)

    }

    const toggle = async (event) => {
        let newVal = !isOpen
        setIsOpen(newVal)
        if (newVal) {
            // setTimeout(() => {
            //     myRef.current.scrollIntoView()
            // }, 100);
        }
    }


    const handleSetText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };


    if (isResolved) {
        return (
            <></>
        )
    }

    return (
        <div className={"ms-4"}>

            {!isOpen && !isResolved && <button type="button" className="btn btn-sm btn-outline-secondary"
                                onClick={toggle}
            >odpowiedz <i className="bi bi-reply"></i>
            </button>}
            {!isOpen && <button type="button" className=" ms-2 btn btn-sm btn-outline-success"
                                onClick={markAsResolved}
            >zakończ <i className="bi bi-check-all"></i>
            </button>}

            {isOpen && <form onSubmit={handleSubmit}>
                <div className="mb-6">
                    <textarea className="fullWith" onChange={handleSetText}>{text}</textarea>
                </div>

                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-sm btn-outline-secondary"
                                onClick={toggle}
                        >Anuluj
                        </button>
                        <button type="submit" className="btn btn-sm btn-outline-success"
                                disabled={text == ""}
                        >Zapisz
                        </button>
                    </div>
                </div>

            </form>}
            <span ref={myRef} />

        </div>

    );
}