import {Link, useNavigate} from "react-router-dom";


import * as React from 'react';
import {useEffect} from 'react';
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch, {syncFetchAndJSON} from "../../api/async-fetch";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {GetYearOptions, MonthOptions} from "../reports/StatusUtils";
import AuthService, {HR} from "../../AuthService";


export default function MonthHRReadiness() {
    const {t} = useTranslation()

    const navigate = useNavigate();

    const [users, setUsers] = React.useState([]);

    const fetchRecords = React.useCallback(() => {
        let searchRequest = {

        }

        let url = BACKEND_URL + 'monthly-hr-readiness/search'
        const baseRequestConfig = getBaseRequestConfig("POST")
        const requestConfig = {...baseRequestConfig, body: JSON.stringify(searchRequest)}
        syncFetchAndJSON(url, requestConfig)
            .then((response) => {
                setUsers(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])



    useEffect(() => {
        fetchRecords()
    }, [fetchRecords])


    return (
        <>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Dane kadrowe potwierdzone dla:</h1>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Miesiąc</th>
                        <th scope="col">Zakres</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((row) => (
                        <tr key={row.id}>
                            <td></td>
                            <td>{row.year} - {row.month}</td>
                            <td>{t("hrReadinesScope" +row.scope)}</td>
                            <td>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}