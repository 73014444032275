class AuthService {
    login(response) {
        localStorage.setItem("user2", JSON.stringify(response))
    }

    logout() {
        window.sessionStorage.accessToken = null
        localStorage.removeItem("user2")
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user2'))
    }

    hasAnyRole(roles,user) {
        if (roles.length == 0) {
            return true;
        }
        let u = user ? user : this.getCurrentUser();

        if (!u) {
            return false;
        }

        let contains = false;
        roles.forEach(r => {

            if (u.roles.includes(r)) {
                contains = true;
                return true;
            }
        })
        return contains;
    }
}


export const EMPLOYEE = "EMPLOYEE";
export const SUPERVISOR = "SUPERVISOR";
export const PROJECT_LEADER = "PROJECT_LEADER";
export const SUPER_HR = "SUPER_HR";
export const PROJECT_CURATOR = "PROJECT_CURATOR";
export const HR = "HR";

export default new AuthService();